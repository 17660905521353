import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-form-control-check-two',
  templateUrl: './form-control-check-two.component.html',
  styleUrls: ['./form-control-check-two.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckTwoComponent),
      multi: true
    }
  ]
})
export class FormControlCheckTwoComponent implements OnInit, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;
  @Input() name: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() theme: 'dark' | 'light' = 'light';

  @Output() childValue = new EventEmitter<boolean>();

  public valueSrc: boolean;

  set value(val: boolean) {
    if (val !== undefined && val !== null && this.valueSrc !== val) {
      this.valueSrc = val;
      this.childValue.emit(val);
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): boolean {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(obj: boolean) {
    this.value = obj;
    this.onChange(obj);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onChange(value?: boolean) {
  }

  onTouch() {
  }

  select(currentValue: boolean) {
    currentValue === true ?
      this.writeValue(false) :
      this.writeValue(true);
  }
}
