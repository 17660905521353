import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingService} from '../../../../../services/loading/loading.service';
import {Subscription} from 'rxjs';
import {User} from '../../../../../modules/authentication/user/user.model';
import {SessionService} from '../../../../../modules/authentication/session/session.service';
import {SessionQuery} from '../../../../../modules/authentication/session/session.query';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})
export class UserNavigationComponent implements OnInit, OnDestroy {

  user: User;
  initials: string;
  isImpersonating: boolean;

  userSubscription: Subscription;

  constructor(private sessionService: SessionService,
              private sessionQuery: SessionQuery,
              private loadingService: LoadingService,
              private router: Router) {
  }

  ngOnInit() {
    this.userSubscription = this.sessionQuery.getUserObservable().subscribe(user => {
      this.user = user;
      this.initials = this.user.firstName[0].toUpperCase() + this.user.lastName[0].toUpperCase();
      this.isImpersonating = this.user.isImpersonating;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }

  unImpersonate() {
    this.loadingService.setIsLoading(false);
    this.sessionService.unImpersonate().pipe(take(1)).subscribe(() => {
      this.loadingService.setIsLoading(false);
      this.sessionService.routeHome();
    }, () => {
      this.loadingService.setIsLoading(false);
    });
  }

  viewProfile() {
    const user = this.sessionQuery.getUser();
    const roles = user.roles;
    if (roles.includes('ROLE_HUB')) {
      this.router.navigateByUrl(`/admin/user/user-management/view/${user.hubId}`);
    } else if (roles.includes('ROLE_ORGANISATION')) {
      // organisation/profile/user-profile/e@mm.com
      this.router.navigateByUrl(`/organisation/profile/user-management/view/${user.username}`);
    } else if (roles.includes('ROLE_FACILITATOR')) {
      this.router.navigateByUrl(`/facilitator/profile`);
    }
  }
}
