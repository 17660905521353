import {Component, Input, OnInit} from '@angular/core';
import {ChoiceInputItem} from '../../../models/form-elements.model';

@Component({
  selector: 'app-input-radio-disabled',
  templateUrl: './input-radio-disabled.component.html',
  styleUrls: ['./input-radio-disabled.component.scss']
})
export class InputRadioDisabledComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() choices: ChoiceInputItem[];
  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() isGrey = true;
  @Input() hasBorder = false;

  constructor() {
  }

  ngOnInit() {
  }

}
