<ng-container *ngIf="!isReadOnly; else disabledWidget">
  <app-form-control-check-two
    [label]="label"
    [id]="id"
    [theme]="theme"
    [showErrors]="showErrors"
    [showLabel]="showLabel"
    [isRequired]="isRequired"
    [formSubmitted]="formSubmitted"
    (childValue)="writeValue($event)"
    [(ngModel)]="value"
  ></app-form-control-check-two>
</ng-container>

<ng-template #disabledWidget>
  <app-widget-check-two-disabled
    [value]="value"
    [label]="label"
    [theme]="theme"
  ></app-widget-check-two-disabled>
</ng-template>
