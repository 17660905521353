import {NgModule} from '@angular/core';
import {OrganisationProfileFormComponent} from './organisation-profile-form.component';
import {ImageUploadModule} from '../../../shared/modules/image-upload/image-upload.module';
import {FormElementsModule} from '../../../shared/modules/form-elements/form-elements.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertModule} from '../../../shared/modules/alert/alert.module';
import {SharedCommonModule} from '../../../shared/modules/shared-common/shared-common.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TreeviewModule} from 'ngx-treeview';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    OrganisationProfileFormComponent
  ],
  imports: [
    CommonModule,
    ImageUploadModule,
    FormElementsModule,
    ReactiveFormsModule,
    AlertModule,
    SharedCommonModule,
    RouterModule,
    TreeviewModule,
    GooglePlaceModule,
    FormsModule
  ],
  exports: [
    OrganisationProfileFormComponent
  ]
})
export class OrganisationProfileFormModule {
}
