<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <p
      class="mb-2"
      [class]="style + '-input-label label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <div
    [class.disabled]="isGrey"
    [class]="style + '-input readonly-input'"
    [class.borderless]="!hasBorder"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
  >
    <p
      class="whitespace-normal"
      [ngClass]="value === 'Select an option...' ? ['text-grey', 'border-none'] : ''"
    >
      <ng-container *ngIf="useCountryPipe">
        {{value | country | async}}
      </ng-container>
      <ng-container *ngIf="useProvincePipe">
        {{value | province | async}}
      </ng-container>
      <ng-container *ngIf="useDistrictPipe">
        {{value | district | async}}
      </ng-container>
      <ng-container *ngIf="useSubDistrictPipe">
        {{value | subDistrict | async}}
      </ng-container>
      <ng-container *ngIf="!useCountryPipe && !useDistrictPipe && !useSubDistrictPipe && !useProvincePipe && items">
        {{value | singleChoiceInputType : items}}
      </ng-container>
      <ng-container *ngIf="!useCountryPipe && !useDistrictPipe && !useSubDistrictPipe && !useProvincePipe && !items">
        {{value}}
      </ng-container>
    </p>
  </div>
</div>
