import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {InputMask} from '../../models/form-elements.model';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements OnInit, OnDestroy, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() usePipe?: boolean;
  @Input() stringPrepend?: string;
  @Input() stringAppend?: string;

  // Default
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'default';
  @Input() numberMask: InputMask;

  @Input() isUnique = false;
  @Input() uniqueId?: number;
  @Input() urlFragment?: string;
  @Input() uniqueAdditionalParam?: string;
  @Input() uniqueError = 'That value is already in use. Please try another';

  public valueSrc: string;
  valueSubscription: Subscription;

  set value(val: any) {
    if (this.valueSrc !== val) {
      this.valueSrc = InputNumberComponent.removeThousandsSeparators(val);
      this.onChange(this.valueSrc);
      this.onTouch();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  static removeThousandsSeparators(value: any) {
    if (value && typeof value === 'string') {
      return parseInt(value.replace(/,/g, ''), 0);
    }
    return value;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

  select(event: any) {
    event.target.select();
  }
}
