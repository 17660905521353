<app-spinner [loadingObs]="loading">
  <form class="form" [formGroup]="profileForm" #form="ngForm" (ngSubmit)="validateAndSave(pageTop)">
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12 p-0">
          <h5 class="my-3 ml-3 d-inline-block">{{heading}}</h5>
          <button *ngIf="!hasBottomSubmit" class="btn btn-success float-right" type="submit">{{submitText}}</button>
          <a
            *ngIf="!hasBottomSubmit"
            class="btn btn-light float-right border-2"
            type="button"
            routerLink="/admin/organisation-management/list"
          >
            Cancel
          </a>
          <p class="ml-3" *ngIf="subHeading">{{subHeading}}</p>
        </div>
      </div>
    </div>

    <div class="card-body">
      <app-alert
        class="w-100"
        [id]="'registration-profile'"
      ></app-alert>

      <div #pageTop class="row">

        <div class="col-5">

          <div class="default-input-group position-relative">
            <div class="input-label-text">
              <label class="mb-2 default-input-label text-dark bg-white" for="name">
                Name of organisation*
              </label>
            </div>
            <div class="default-input flex-between">
              <input id="name"
                     #ngModel="ngModel"
                     class="input-inner"
                     type="text"
                     required
                     [class.border-danger]="(ngModel.touched && ngModel.invalid) || unique === false"
                     [ngClass]="{ 'is-invalid': (ngModel.touched && ngModel.invalid) || unique === false}"
                     name="name"
                     [ngModel]="profileForm.controls.name.value"
                     [ngModelOptions]="{standalone: true}"
                     (blur)="checkUniqueName(pageTop)"
                     (ngModelChange)="changeName($event)"
              >
            </div>

            <div class="validation-notices">
              <div
                class="null-or-length-notice"
                *ngIf="profileForm.get('name').invalid && (ngModel.touched || form.submitted)"
              >
                <p class="font-size-sm font-weight-bold text-danger mt-1">
                  This field is required!
                </p>
              </div>
              <div class="null-or-length-notice" *ngIf="unique === false">
                <p class="font-size-sm font-weight-bold text-danger mt-1">
                  This name is already in use. Please choose another.
                </p>
              </div>
            </div>

          </div>


          <!--          <app-input-text-->
          <!--            [label]="'Name of organisation*'"-->
          <!--            [id]="'name'"-->
          <!--            [isRequired]="true"-->
          <!--            [style]="'default'"-->
          <!--            [theme]="'light'"-->
          <!--            [formSubmitted]="form.submitted"-->
          <!--            formControlName="name"-->
          <!--          ></app-input-text>-->

          <app-input-select-single
            [label]="'Type of organisation*'"
            [id]="'type'"
            [isRequired]="true"
            [style]="'default'"
            [theme]="'light'"
            [formSubmitted]="form.submitted"
            [items]="organisationTypes"
            formControlName="type"
          ></app-input-select-single>

        </div>

        <div class="offset-1 col-5">

          <div class="mb-3">
            <img class="profile-image logo-height"
                 src="{{profileForm?.value?.photo?.location || 'assets/images/common/placeholder.jpg'}}"
                 alt="organisation-logo">

            <span class="ml-5">
              <app-image-upload
                [label]="profileForm?.value?.photo?.location ? 'Replace' : 'Upload'"
                (imageUploaded)="addLogo($event)"
              ></app-image-upload>
              <a
                *ngIf="profileForm?.value?.photo?.location"
                href="javascript:void(0)"
                class="text-danger fa fa-trash fa-1x mt-1 ml-2"
                (click)="removePhoto()"
              ></a>
              <div class="validation-notices">
              </div>
            </span>
          </div>

          <div>
            <p class="font-size-sm font-weight-bold text-success ml-0">
              NB: Maximum file size is 2MB
            </p>
          </div>

          <div class="validation-notices">
            <div
              class="null-or-length-notice"
              *ngIf="profileForm.get('photo').invalid && form.submitted"
            >
              <!--                    // (profileForm.get('photo').dirty || profileForm.get('photo').touched)"-->
              <p class="font-size-sm font-weight-bold text-danger">
                This field is required!
              </p>
            </div>
          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-5">
          <ng-container formGroupName="contact">
            <app-input-text
              [label]="'Contact person*'"
              [id]="'primaryName'"
              [isRequired]="true"
              [style]="'default'"
              [theme]="'light'"
              [formSubmitted]="form.submitted"
              formControlName="primaryName"
            ></app-input-text>

            <app-input-text
              [label]="'Position/Role*'"
              [id]="'primaryPosition'"
              [isRequired]="true"
              [style]="'default'"
              [theme]="'light'"
              [formSubmitted]="form.submitted"
              formControlName="primaryPosition"
            ></app-input-text>
          </ng-container>
        </div>

        <div class="offset-1 col-5">

          <app-input-textarea
            [label]="'Description'"
            [id]="'description'"
            [rows]="'6'"
            [maxLength]="2000"
            [style]="'default'"
            [theme]="'light'"
            [showOptional]="false"
            formControlName="description"
          ></app-input-textarea>

          <ng-container *ngIf="isHub">
            <input
              type="checkbox"
              class="ml-0"
              id="hasBiReporting"
              name="hasBiReporting"
              [value]="true"
              [attr.aria-label]="'Has Bi Reporting'"
              formControlName="hasBiReporting"
            />
            <label for="hasBiReporting" class="p-2 mb-3">Has Advanced Analytics</label>
          </ng-container>

        </div>
      </div>


      <div class="row">

        <div class="col-5">
          <ng-container formGroupName="contact">
            <app-input-text
              type="email"
              [label]="'Email address*'"
              [id]="'primaryEmail'"
              [isRequired]="true"
              [style]="'default'"
              [theme]="'light'"
              [formSubmitted]="form.submitted"
              [isEmail]="true"
              formControlName="primaryEmail"
            ></app-input-text>

            <!--            <app-input-text-->
            <!--              [label]="'Contact number*'"-->
            <!--              [id]="'primaryNumber'"-->
            <!--              [isRequired]="true"-->
            <!--              [style]="'default'"-->
            <!--              [theme]="'light'"-->
            <!--              [isContactNo]="true"-->
            <!--              [formSubmitted]="form.submitted"-->
            <!--              formControlName="primaryNumber"-->
            <!--            ></app-input-text>-->

            <div class="default-input-group position-relative">
              <div class="input-label-text">
                <label
                  class="mb-2 default-input-label text-dark bg-white"
                  for="primaryNumber">Contact number*
                </label>
              </div>
              <div class="default-input border-dark"
                   [class.border-danger]="(form.form.controls.contact.controls['primaryNumber'].touched || form.submitted) && form.form.controls.contact.controls['primaryNumber'].errors">
                <ngx-intl-tel-input
                  id="primaryNumber"
                  name="'primaryNumber'"
                  [cssClass]="'input-inner text-dark border-dark'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.SouthAfrica"
                  [maxLength]="'15'"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.International"
                  formControlName="primaryNumber"
                ></ngx-intl-tel-input>
              </div>
              <div class="validation-notices">
                <div
                  class="null-or-length-notice"
                  *ngIf="form.form.controls.contact.controls['primaryNumber'].invalid && (form.form.controls.contact.controls['primaryNumber'].touched || form.submitted)"
                >
                  <p *ngIf="form.form.controls.contact.controls['primaryNumber'].errors['required']"
                     class="font-size-sm font-weight-bold text-danger mt-1">
                    This field is required!
                  </p>
                  <p *ngIf="!form.form.controls.contact.controls['primaryNumber'].errors['required']"
                     class="font-size-sm font-weight-bold text-danger mt-1">
                    Invalid contact number!
                  </p>
                </div>
              </div>
            </div>

          </ng-container>
        </div>

        <div class="offset-1 col-5">

          <ng-container formGroupName="contact">
            <app-input-text
              [label]="'Second contact person'"
              [id]="'secondaryName'"
              [style]="'default'"
              [theme]="'light'"
              [showOptional]="false"
              formControlName="secondaryName"
            ></app-input-text>

            <!--            <app-input-text-->
            <!--              [label]="'Second contact number'"-->
            <!--              [id]="'secondaryNumber'"-->
            <!--              [style]="'default'"-->
            <!--              [theme]="'light'"-->
            <!--              [showOptional]="false"-->
            <!--              [isContactNo]="true"-->
            <!--              formControlName="secondaryNumber"-->
            <!--            ></app-input-text>-->

            <div class="default-input-group position-relative">
              <div class="input-label-text">
                <label
                  class="mb-2 default-input-label text-dark bg-white"
                  for="secondaryNumber">Second contact number
                </label>
              </div>
<!--                   [class.border-danger]="(form.form.controls.contact.controls['secondaryNumber'].touched || form.submitted) && form.form.controls.contact.controls['secondaryNumber'].errors"-->
              <div class="default-input border-dark"
              >
                <ngx-intl-tel-input
                  id="secondaryNumber"
                  name="'secondaryNumber'"
                  [cssClass]="'input-inner text-dark border-dark'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.SouthAfrica"
                  [maxLength]="'15'"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.International"
                  formControlName="secondaryNumber"
                ></ngx-intl-tel-input>
              </div>
              <div class="validation-notices">
                <div
                  class="null-or-length-notice"
                  *ngIf="form.form.controls.contact.controls['secondaryNumber'].invalid && (form.form.controls.contact.controls['secondaryNumber'].touched || form.submitted)"
                >
<!--                  <p *ngIf="form.form.controls.contact.controls['secondaryNumber'].errors['required']"-->
<!--                     class="font-size-sm font-weight-bold text-danger mt-1">-->
<!--                    This field is required!-->
<!--                  </p>-->
                  <p *ngIf="!form.form.controls.contact.controls['secondaryNumber'].errors['required']"
                     class="font-size-sm font-weight-bold text-danger mt-1">
                    Invalid contact number!
                  </p>
                </div>
              </div>
            </div>

          </ng-container>
        </div>

      </div>

      <h6 class="my-3">Address details</h6>

      <div class="row">

        <div class="col-5">
          <ng-container formGroupName="contact">

            <div class="default-input-group position-relative">
              <div class="input-label-text">
                <label
                  class="mb-2 default-input-label text-dark bg-white"
                  for="addressSearch">Street address*
                </label>
              </div>
              <div class="default-input border-dark"
                   [class.border-danger]="(getContact().controls.streetAddress.touched || form.submitted) && getContact().controls.streetAddress.errors">
                <input
                  id="addressSearch"
                  ngx-google-places-autocomplete
                  [options]="options"
                  class="input-inner text-dark border-dark"
                  [required]="true"
                  (onAddressChange)="handleAddressChange($event)"
                  (keydown)="handleKeyDown($event)"
                  formControlName="streetAddress"
                />
              </div>
              <div class="validation-notices">
                <ng-container
                  *ngIf="(getContact().controls.streetAddress.touched || form.submitted) && getContact().controls.streetAddress.errors">
                  <div *ngIf="getContact().controls.streetAddress.errors.required" class="null-or-length-notice">
                    <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
                  </div>
                </ng-container>
              </div>
            </div>


            <!--            <app-input-text-->
            <!--              [label]="'Street address*'"-->
            <!--              [id]="'streetAddress'"-->
            <!--              [isRequired]="true"-->
            <!--              [style]="'default'"-->
            <!--              [theme]="'light'"-->
            <!--              [formSubmitted]="form.submitted"-->
            <!--              formControlName="streetAddress"-->
            <!--            ></app-input-text>-->

            <app-input-select-single
              *ngIf="provinceTypes"
              [label]="'Province*'"
              [id]="'province'"
              [isRequired]="true"
              [style]="'default'"
              [theme]="'light'"
              [items]="filteredProvinceTypes"
              [clearValueOnItemsChange]="false"
              [formSubmitted]="form.submitted"
              formControlName="provinceEntity"
              (selectedItem)="setCountry($event)"
            ></app-input-select-single>


            <label class="default-input-label text-dark bg-white">
              GPS coordinates*
            </label>
            <div class="ml-5">

              <div class="default-input-group position-relative">
                <div class="input-label-text">
                  <label class="mb-2 default-input-label text-dark bg-white" for="latitude">
                    N
                  </label>
                </div>
                <div
                  class="default-input flex-between"
                  [class.border-danger]="(getContact().get('latitude').touched || form.submitted) && getContact().get('latitude').invalid"
                  [ngClass]="{ 'is-invalid': (getContact().get('latitude').touched || form.submitted) && getContact().get('latitude').invalid}"
                >
                  <input id="latitude"
                         class="input-inner"
                         type="number"
                         required
                         name="latitude"
                         formControlName="latitude"
                  >
                </div>

                <div class="validation-notices">
                  <div class="null-or-length-notice" *ngIf="(getContact().get('latitude').touched || form.submitted) && getContact().get('latitude').hasError('required')">
                    <p class="font-size-sm font-weight-bold text-danger mt-1">
                      This field is required!
                    </p>
                  </div>
                </div>

              </div>

              <div class="default-input-group position-relative">
                <div class="input-label-text">
                  <label class="mb-2 default-input-label text-dark bg-white" for="longitude">
                    W
                  </label>
                </div>
                <div
                  class="default-input flex-between"
                  [class.border-danger]="(getContact().get('longitude').touched || form.submitted) && getContact().get('longitude').invalid"
                  [ngClass]="{ 'is-invalid': (getContact().get('longitude').touched || form.submitted) && getContact().get('longitude').invalid}"
                >
                  <input id="longitude"
                         class="input-inner"
                         type="number"
                         required
                         name="longitude"
                         formControlName="longitude"
                  >
                </div>

                <div class="validation-notices">
                  <div class="null-or-length-notice" *ngIf="(getContact().get('longitude').touched || form.submitted) && getContact().get('longitude').hasError('required')">
                    <p class="font-size-sm font-weight-bold text-danger mt-1">
                      This field is required!
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </ng-container>
        </div>

        <div class="offset-1 col-5">

          <ng-container formGroupName="contact">
            <app-input-text
              [label]="'City*'"
              [id]="'city'"
              [isRequired]="true"
              [style]="'default'"
              [theme]="'light'"
              [formSubmitted]="form.submitted"
              formControlName="city"
            ></app-input-text>

            <div class="default-input-group position-relative">
              <div class="input-label-text">
                <label
                  class="mb-2 default-input-label text-dark bg-white"
                  for="country">Country
                </label>
              </div>
              <div class="default-input border-dark">
                <input
                  id="country"
                  class="input-inner text-dark border-dark"
                  [disabled]="true"
                  [ngModel]="selectedCountry"
                  [ngModelOptions]="{standalone: true}"
                />
              </div>
              <div class="validation-notices"></div>
            </div>

            <app-input-text
              [label]="'Postal/Zip code'"
              [id]="'postalCode'"
              [showOptional]="false"
              [style]="'default'"
              [theme]="'light'"
              [formSubmitted]="form.submitted"
              formControlName="postalCode"
            ></app-input-text>

          </ng-container>
        </div>

      </div>


      <h5 class="my-3">Additional information</h5>

      <div class="row">

        <div class="col-11">
          <app-input-checkbox
            [label]="'Please select which SDGs your organisation contributes towards*'"
            [id]="'sdgs'"
            [isRequired]="true"
            [style]="'default'"
            [theme]="'light'"
            [choices]="sdgs"
            [formSubmitted]="form.submitted"
            formControlName="sdgs"
          ></app-input-checkbox>

          <app-input-checkbox
            [label]="' Add targeted populations (beneficiaries) field*'"
            [id]="'targetedPopulations'"
            [isRequired]="true"
            [style]="'default'"
            [theme]="'light'"
            [choices]="targetedPopulations"
            [formSubmitted]="form.submitted"
            formControlName="targetedPopulations"
          ></app-input-checkbox>

          <div class="w-100 mx-auto mb-3">
            <label class="mb-2 default-input-multi-legend">Select the services provided*</label>
            <div
              class="default-input"
              [class.border-danger]="profileForm.get('services').invalid && form.submitted"
            >
              <ngx-treeview
                [config]="config"
                [items]="organisationServiceTypes"
                (selectedChange)="organisationServiceChange($event);"
              ></ngx-treeview>
            </div>

            <div class="validation-notices">
              <div
                class="null-or-length-notice"
                *ngIf="profileForm.get('services').invalid && form.submitted"
              >
                <p class="font-size-sm font-weight-bold text-danger mt-1">
                  This field is required!
                </p>
              </div>
            </div>
          </div>

          <div class="w-100 mx-auto mb-3">
            <label class="mb-2 default-input-multi-legend">
              Select the provinces where your organisation is located* (This would need to be set per country instance)
            </label>
            <div
              class="default-input"
              [class.border-danger]="profileForm.get('operationalProvinceEntities').invalid && form.submitted"
            >
              <ngx-treeview
                [config]="config"
                [items]="provinceTreeViewTypes"
                (selectedChange)="operationalProvinceChange($event);"
              ></ngx-treeview>
            </div>

            <div class="validation-notices">
              <div
                class="null-or-length-notice"
                *ngIf="profileForm.get('operationalProvinceEntities').invalid && form.submitted"
              >
                <p class="font-size-sm font-weight-bold text-danger mt-1">
                  This field is required!
                </p>
              </div>
            </div>
          </div>

          <!--          <app-input-checkbox-->
          <!--            [label]="'Select the provinces where your organisation is located* (This would need to be set per country instance)'"-->
          <!--            [id]="'operationalProvince'"-->
          <!--            [isRequired]="true"-->
          <!--            [style]="'default'"-->
          <!--            [theme]="'light'"-->
          <!--            [choices]="provinceTreeviewTypes"-->
          <!--            [formSubmitted]="form.submitted"-->
          <!--            formControlName="operationalProvinceEntities"-->
          <!--          ></app-input-checkbox>-->
        </div>

      </div>

      <div *ngIf="hasBottomSubmit" class="row">
        <div class="offset-9 col-3">
          <button class="btn btn-success mt-2" type="submit">{{submitText}}</button>
        </div>
      </div>

    </div>
  </form>
</app-spinner>
