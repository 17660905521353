import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChoiceInputItem} from '../../models/form-elements.model';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCheckboxComponent),
      multi: true
    }
  ]
})
export class InputCheckboxComponent implements OnInit, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() name: string;
  @Input() id: string;
  @Input() choices: ChoiceInputItem[];

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isGrey = true;
  @Input() showActiveBorder = true;
  @Input() hasBorder = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() cols?: number;

  @Output() selected = new EventEmitter<string>();

  checkBoxAnswers = [];

  set value(val: string[]) {
    if (val !== undefined && this.checkBoxAnswers !== val) {
      this.checkBoxAnswers = val;
      this.onChange(val);
      this.onTouch();
      this.writeValue(val);
    }
  }

  get value() {
    return this.checkBoxAnswers;
  }

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(value: any[]) {
    this.onChange(value);
    if (value && typeof value === 'object') {
      this.checkBoxAnswers = value.map(v => {
        const found = this.choices.find(item => item.value === v);
        if (found) {
          return found.value;
        }
      });
    } else {
      this.checkBoxAnswers = [];
    }
  }

  registerOnChange(fn: (value: any) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: any) {
  }

  onTouch() {
  }

  select(val: string) {
    this.selected.emit(val);
  }
}
