import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DateUtil} from '../../../../../utils/date-util';
import {ValidationService} from '../../../services/validation.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../custom-date-adapter/custom-date-parser.formatter';

@Component({
  selector: 'app-form-control-date',
  templateUrl: './form-control-date.component.html',
  styleUrls: ['./form-control-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDateComponent),
      multi: true
    },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class FormControlDateComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {

  @ViewChild('model', {static: true}) datepicker: NgModel;

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() type = 'text';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() hasTime = false;
  @Input() theme = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() minDate = undefined;
  // TODO for year range bug, limited to 10 years,
  //  see https://stackoverflow.com/questions/46875040/angularjs-changing-the-year-range-of-ngbdatepicker
  //  and https://ng-bootstrap.github.io/#/components/datepicker/api
  @Input() maxDate = undefined;
  @Input() minTime;

  @Output() childValue = new EventEmitter<any>();

  public dateValueSrc: any;
  public timeValueSrc: any;
  hasError: 'null' | 'length' | '';
  valueSubscription: Subscription;

  set dateValue(value: any) {
    if (this.dateValueSrc !== value) {
      if (typeof value === 'number') {
        this.dateValueSrc = DateUtil.convertTimestampToDateObject(value);
      } else {
        this.dateValueSrc = value;
      }

      this.onChange(DateUtil.convertDateObjectToTimestamp(this.dateValue, this.timeValue));
      this.childValue.emit(DateUtil.convertDateObjectToTimestamp(this.dateValue, this.timeValue));
      this.onTouch();
      this.validate();
    }
  }

  get dateValue(): any {
    return this.dateValueSrc;
  }

  set timeValue(value: any) {
    if (this.timeValueSrc !== value) {
      if (typeof value === 'number') {
        this.timeValueSrc = DateUtil.convertTimestampToTimeObject(value);
      } else {
        this.timeValueSrc = value;
      }

      this.onChange(DateUtil.convertDateObjectToTimestamp(this.dateValue, this.timeValue));
      this.childValue.emit(DateUtil.convertDateObjectToTimestamp(this.dateValue, this.timeValue));
      this.onTouch();
      this.validate();
    }
  }

  get timeValue(): any {
    return this.timeValueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.datepicker) {
      this.valueSubscription = this.datepicker.valueChanges.subscribe(() => {
        this.validate();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSubmitted) {
      if (changes.formSubmitted.currentValue === true) {
        this.validate();
      }
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: any) {
    this.dateValue = value;
    this.timeValue = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: number) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  onChange(value?: number) {
  }

  onTouch() {
  }

  validate() {
    if (this.isRequired) {
      this.hasError = ValidationService.dateControlHasError(this.datepicker.control, this.formSubmitted) ? 'null' : '';
    } else {
      this.hasError = '';
    }
  }

}
