import {BaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {compareAsc} from '../../../../../shared/utils/sort-util';

export interface CountryCombined extends BaseEntity {
  name?: string;
  code?: string;
  description?: string;
  status?: 'ACTIVE' | 'DEACTIVATED' | 'DELETED';

  provinces?: ProvinceDto[];
}

export interface Country extends BaseEntity {
  name?: string;
  code?: string;
  description?: string;
  status?: 'ACTIVE' | 'DEACTIVATED' | 'DELETED';
}

export interface CountryUsersDto {
  countryId: number;
  countryName: string;
  provinces: number;
  clients: number;
  facilitators: number;
  organisationUsers: number;
  hubs: number;
}

export interface ProvinceUsersDto {
  provinceId: number;
  provinceCode: string;
  provinceName: string;
  clients: number;
  facilitators: number;
  organisationUsers: number;
  hubs: number;
}

export interface Province extends BaseEntity {
  name?: string;
  code?: string;
  description?: string;
  status?: 'ACTIVE' | 'DEACTIVATED' | 'DELETED';

  country?: Country;
}

export interface ProvinceDto extends BaseEntity {
  name?: string;
  code?: string;
  description?: string;
  status?: 'ACTIVE' | 'DEACTIVATED' | 'DELETED';
}

export interface ProvinceCreateDto extends BaseEntity {
  name: string;
  code: string;
  description: string;

  countryId: number;
}

export interface ProvinceUpdateDto extends BaseEntity {
  name: string;
  code: string;
  description: string;
}

export function createCountryForm(formBuilder: FormBuilder, params?: Partial<Country>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params?.name, Validators.required],
    code: [params?.code, Validators.required],
    description: [params?.description],
  }));
}

export function createProvinceCreateForm(formBuilder: FormBuilder, params?: Partial<ProvinceCreateDto>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params?.name, Validators.required],
    code: [params?.code, Validators.required],
    description: [params?.description],
    countryId: [params?.countryId, Validators.required],
  }));
}

export function createProvinceUpdateForm(formBuilder: FormBuilder, params?: Partial<ProvinceUpdateDto>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params?.name, Validators.required],
    code: [params?.code, Validators.required],
    description: [params?.description],
  }));
}

export const sortProvinceComparator = (a: Province, b: Province): number => {
  return compareAsc(`${a.country.name} ${a.name}`, `${b.country.name} ${b.name}`);
};
