import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionQuery} from '../../../modules/authentication/session/session.query';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private sessionQuery: SessionQuery) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.sessionQuery.isLoggedIn().subscribe(authenticated => {
      if (!authenticated) {
        return next.handle(request);
      }

      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this.sessionQuery.getValue().token}`
        }
      });
    }, () => {
      console.log('catching the error in Token Interceptor.');
    });

    return next.handle(request);
  }
}
