import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './components/alert/alert.component';
import {AlertBannerComponent} from './components/alert-banner/alert-banner.component';


@NgModule({
  declarations: [AlertComponent, AlertBannerComponent],
  exports: [
    AlertComponent
  ],
  imports: [
    CommonModule
  ]
})
export class AlertModule {
}
