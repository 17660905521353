import {Component, OnInit, OnDestroy} from '@angular/core';
import {SidebarService} from '../../../../../services/sidebar/sidebar.service';
import {Subscription} from 'rxjs';
import {SessionQuery} from '../../../../../modules/authentication/session/session.query';
import {NotificationModel} from '../../../../../common/components/notification-receive/store/notification.model';
import {NotificationService} from '../../../../../common/components/notification-receive/store/notification.service';
import {NotificationReceiveViewModalComponent} from '../../../../../common/components/notification-receive/components/notification-receive-view-modal/notification-receive-view-modal.component';
import {ModalService} from '../../../../../services/modal/modal.service';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SessionService } from 'app/modules/authentication/session/session.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  toggled: boolean;
  sidebarSubscription: Subscription;

  notifications: NotificationModel[];
  notificationSubscription: Subscription;

  options: NgbModalOptions;

  constructor(public sessionQuery: SessionQuery,
              private sidebarService: SidebarService,
              private notificationService: NotificationService,
              private modalService: ModalService,
              private router: Router,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.options = {
      ariaLabelledBy: 'Notification',
      centered: true,
      // size: 'lg',
      scrollable: true,
    };

    this.notificationService.refreshUnReadNotifications();
    this.notificationService.refreshDashboardNotifications();

    this.sidebarSubscription = this.sidebarService.getToggled().subscribe(toggled => {
      this.toggled = toggled;
    });

    this.notificationSubscription = this.notificationService.getUnReadNotifications().subscribe(list => {
      this.notifications = list;
    });
  }

  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }

    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.sidebarService.setToggled(!this.toggled);
  }

  viewNotification(element: NotificationModel) {
    this.notificationService.markNotificationsAsRead([element.id]).subscribe(() => {
      // nothing to happen
    });

    const modalRef = this.modalService.openStackedModal(NotificationReceiveViewModalComponent, this.options, [
      {key: 'notification', value: element},
    ]);

    // refresh is the @Output
    // this.componentInstanceSubscription = modalRef.componentInstance.refresh.subscribe(() => {
    //   this.fetchClient();
    // });

    modalRef.result.then(reason => {
      // This runs when the modal is closed with the close() function
      if (reason === 'sent') {
        // this.fetchNotifications();
      } else {
        // this.fetchNotifications();
      }
    }).catch(() => {
      // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
      // this.fetchNotifications();
    });
  }

  viewMore() {
    if (this.sessionQuery.hasRole(['ROLE_ORGANISATION'])) {
      this.router.navigateByUrl('organisation/notification/list');
    } else if (this.sessionQuery.hasRole(['ROLE_FACILITATOR'])) {
      this.router.navigateByUrl('facilitator/notification/list');
    } else {
      this.router.navigateByUrl('admin/notification/list');
    }
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }
}
