import {FormBuilder, Validators} from '@angular/forms';
import {PAT_EMAIL} from '../../organisation-registration/store/organisation-profile.model';
import {BaseEntity, createBaseEntityForm} from '../../../shared/models/base-entity.model';
import {Province} from '../../admin/components/location-management/store/location.model';
import {FileUploadSpaces} from '../../../shared/modules/file-upload/models/file-upload.model';

export type Roles =
// 'ROLE_ADMIN' |
  | 'ROLE_SUPER_ADMIN'
  | 'ROLE_HUB'
  | 'ROLE_ORGANISATION'
  | 'ROLE_FACILITATOR'
  | 'ROLE_INTERNAL_FACILITATOR'
  | 'ROLE_INDEPENDENT_FACILITATOR'
  | 'ROLE_LEGACY'
  | 'ROLE_SUPER_HUB'
  // 'ROLE_FUNDER' |
  // 'ROLE_GRANT_MANAGER' |
  // 'ROLE_GRANT_COORDINATOR' |
  // 'ROLE_APPLICANT' |
  | 'Unauthenticated'
  | 'Any';

export interface Role {
  name: Roles;
}

export interface User extends BaseEntity{
  // id: number;
  username?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  roles?: Roles[];
  authorities?: Role[];
  userStatus?: string;
  hostUrl?: string;

  // hub
  hubId?: number;
  hubStatus?: string;

  // organisation
  organisationId?: number;
  organisationStatus?: string;
  contactNo?: string;
  hasBiReporting?: string;
  // streetAddress?: string;
  // city?: string;
  // provinceEntityId?: number;
  // provinceEntity?: Province;
  // postalCode?: string;
  // latitude?: number;
  // longitude?: number;
  photo?: FileUploadSpaces;

  // facilitator
  facilitatorId?: number;
  facilitatorStatus?: string;
  hasAnyBiReporting?: boolean;

  enabled?: boolean;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  isSubscribing?: boolean;
  recaptcha?: any;

  isImpersonating?: boolean;
}

export interface UserDto extends BaseEntity{
  username?: string;
  email?: string;
  password?: string;
  userStatus?: string;
  firstName?: string;
  lastName?: string;

  // Organisation user fields
  contactNo?: string;
  // streetAddress?: string;
  // city?: string;
  // provinceEntityId?: number;
  // postalCode?: string;
  // latitude?: number;
  // longitude?: number;
  photo?: FileUploadSpaces;

  authorities?: Role[];
}

export function createUser(params?: Partial<User>) {
  return {
    id: params && params.id,
    username: params && params.username,
    email: params && params.email,
    password: params && params.password,
    confirmPassword: params && params.confirmPassword,
    firstName: params && params.firstName,
    lastName: params && params.lastName,

    // Organisation user fields
    contactNo: params && params.contactNo,
    // streetAddress: params && params.streetAddress,
    // city: params && params.city,
    // provinceEntityId: params && params.provinceEntityId,
    // postalCode: params && params.postalCode,
    // latitude: params && params.latitude,
    // longitude: params && params.longitude,
    photo: params && params.photo,

    isSubscribing: params && params.isSubscribing,
    roles: (params && params.roles) || [],
    userStatus: params && params.userStatus,
    hostUrl: params && params.hostUrl,
    organisationId: params && params.organisationId,
    enabled: params && params.enabled,
    accountNonExpired: params && params.accountNonExpired,
    accountNonLocked: params && params.accountNonLocked,
    credentialsNonExpired: params && params.credentialsNonExpired,
    recaptcha: params && params.recaptcha,
  } as User;
}

export function createLoginForm(
  formBuilder: FormBuilder,
  params?: Partial<{ username; password; recaptcha }>
) {
  return formBuilder.group({
    username: [
      (params && params.username) || '',
      [Validators.required],
    ],
    password: [(params && params.password) || '', Validators.required],
    recaptcha: [(params && params.recaptcha) || '', Validators.required],
  });
}

export function createRegisterForm(
  formBuilder: FormBuilder,
  params?: Partial<User>
) {
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required, Validators.pattern(PAT_EMAIL)],
    ],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    firstName: [params && params.firstName, Validators.required],
    lastName: [params && params.lastName, Validators.required],

    contactNo: [params && params.contactNo, Validators.required],
    // streetAddress: [params && params.streetAddress],
    // city: [params && params.city],
    // provinceEntityId: [params && params.provinceEntityId],
    // provinceEntity: [params && params.provinceEntity],
    // postalCode: [params && params.postalCode],
    // latitude: [params && params.latitude],
    // longitude: [params && params.longitude],
    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params?.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),

    recaptcha: [(params && params.recaptcha) || '', Validators.required],
  });
}


export function createUserForm(
  formBuilder: FormBuilder,
  params?: Partial<UserManagement>
) {
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required, Validators.pattern(PAT_EMAIL)],
    ],
    email: [
      params && params.email,
      [Validators.required, Validators.pattern(PAT_EMAIL)],
    ],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    firstName: [params && params.firstName, Validators.required],
    lastName: [params && params.lastName, Validators.required],
    contactNo: [params && params.contactNo, Validators.required],
    // streetAddress: [params && params.streetAddress],
    // city: [params && params.city],
    // provinceEntityId: [params && params.provinceEntityId],
    // postalCode: [params && params.postalCode],
    // latitude: [params && params.latitude],
    // longitude: [params && params.longitude],
    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params?.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),
  });
}

export function createAdminProfileForm(
  formBuilder: FormBuilder,
  params?: Partial<User>
) {
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required],
    ],
    email: [
      params && params.email,
      [Validators.required, Validators.pattern(PAT_EMAIL)],
    ],
    firstName: [params && params.firstName, Validators.required],
    lastName: [params && params.lastName, Validators.required],
  });
}

export function createOrgProfileForm(
  formBuilder: FormBuilder,
  params?: Partial<User>
) {
  let roles;
  if (params?.authorities) {
    roles = params.authorities.map(x => x.name);
  } else {
    roles = [];
  }
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required],
    ],
    email: [
      params && params.email,
      [Validators.required, Validators.pattern(PAT_EMAIL)],
    ],
    firstName: [params && params.firstName, Validators.required],
    lastName: [params && params.lastName, Validators.required],
    contactNo: [params && params.contactNo, Validators.required],
    // streetAddress: [params && params.streetAddress],
    // city: [params && params.city],
    // provinceEntityId: [params && params.provinceEntity?.id],
    // postalCode: [params && params.postalCode],
    // latitude: [params && params.latitude],
    // longitude: [params && params.longitude],
    roles: [roles],

    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params?.photo), {
      bucket: [params && params.photo && params.photo.bucket || ''],
      firstKey: [params && params.photo && params.photo.firstKey || ''],
      location: [params && params.photo && params.photo.location || ''],
      secondKey: [params && params.photo && params.photo.secondKey || ''],
    })),
  });
}

export interface ResetUser {
  username: string;
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
  hostUrl: string;
  recaptcha: any;
}

export function createResetUser(params?: Partial<ResetUser>) {
  return {
    username: params && params.username,
    password: params && params.password,
    newPassword: params && params.newPassword,
    newPasswordConfirm: params && params.newPasswordConfirm,
    hostUrl: params && params.hostUrl,
  } as ResetUser;
}

export function createResetUserForm(
  formBuilder: FormBuilder,
  params?: Partial<ResetUser>
) {
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required],
    ],
    password: ['', Validators.required],
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required],
    hostUrl: [''],
  });
}

export function createForgotPasswordForm(
  formBuilder: FormBuilder,
  params?: Partial<ResetUser>
) {
  return formBuilder.group({
    username: [
      params && params.username,
      [Validators.required], // Validators.pattern(PAT_EMAIL)
    ],
    recaptcha: [(params && params.recaptcha) || '', Validators.required],
    hostUrl: [''],
  });
}

export function createForgotPasswordConfirmationForm(
  formBuilder: FormBuilder,
  params?: Partial<ResetUser>
) {
  return formBuilder.group({
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required],
  });
}

export interface UserDetail {
  username: string;
  firstName: string;
  lastName: string;
  userCreatedOn: number;

  // implementer
  confirmedRegistration: boolean;
  organisationId: number;
  organisationCreatedOn: number;
  organisationName: string;
}

export interface UserManagement {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  contactNo?: string;
  // streetAddress: string;
  // city: string;
  // provinceEntityId: number;
  // postalCode: string;
  // latitude: number;
  // longitude: number;
  photo?: FileUploadSpaces;
}

export interface UserManagementDto {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  hostUrl: string;
  userCreatedOn: any;

  // Organisation user fields
  contactNo?: string;
  // streetAddress?: string;
  // city?: string;
  // provinceEntityId?: number;
  // provinceName?: string;
  // countryName?: string;
  // postalCode?: string;
  // latitude?: number;
  // longitude?: number;
  photo?: FileUploadSpaces;
}

export interface UpdateAdminUser {
  id?: number;
  existingUsername: string;
  newUsername: string;
  firstName: string;
  lastName: string;
  email: string;
  // contactCode: number;
  // contactNumber: number;
  // address: string;
  // province: string;
  // country: string;
  // // TODO setup profile pic
  // profilePic: any;

  // Organisation user fields
  contactNo?: string;
  // streetAddress?: string;
  // city?: string;
  // provinceEntityId?: number;
  // postalCode?: string;
  // latitude?: number;
  // longitude?: number;
  photo?: FileUploadSpaces;
}

export function createUpdateAdminUser(params?: Partial<UpdateAdminUser>) {
  return {
    id: params && params.id,
    existingUsername: params && params.existingUsername,
    newUsername: params && params.newUsername,
    firstName: params && params.firstName,
    lastName: params && params.lastName,
    email: params && params.email,
    // contactCode: params && params.contactCode,
    // contactNumber: params && params.contactNumber,
    // address: params && params.address,
    // province: params && params.province,
    // country: params && params.country,
    // profilePic: params && params.profilePic,
  } as UpdateAdminUser;
}
