<form [formGroup]="questionForm" #form="ngForm">
  <div class="row">
    <div class="col-6 mt-1">
<!--      {{questionNumber + '. ' + questionForm.value.label + questionForm.value.mandatory ? '*' : ''}}-->
      {{questionNumber + '. ' + questionForm.value.label}}
    </div>
    <div class="col-6">

      <ng-container [ngSwitch]="questionForm.value.type">

        <!-- Can only edit INDICATOR type questions -->
        <ng-container *ngSwitchCase="'INDICATOR'">
          <!-- green/yellow/red-->
          <div class="d-flex justify-content-end">

            <!-- GREEN -->
            <div
              class="flex-start custom-control"
            >
<!--                class="custom-control-input"-->
              <input
                type="radio"
                [id]="'greenAnswerIndicator' + dimensionIndex + questionIndex"
                [name]="'answerIndicator'"
                [value]="'GREEN'"
                [required]="true"
                formControlName="answerIndicator"
              />
<!--              class="custom-control-label"-->
              <label [for]="'answerIndicator' + dimensionIndex + questionIndex">
                Green
              </label>
            </div>

            <!-- YELLOW -->
            <div
              class="flex-start custom-control"
            >
<!--                class="custom-control-input"-->
              <input
                type="radio"
                [id]="'yellowAnswerIndicator' + dimensionIndex + questionIndex"
                [name]="'answerIndicator'"
                [value]="'YELLOW'"
                [required]="true"
                formControlName="answerIndicator"
              />
<!--              class="custom-control-label"-->
              <label [for]="'answerIndicator' + dimensionIndex + questionIndex">
                Yellow
              </label>
            </div>

            <!-- RED -->
            <div
              class="flex-start custom-control"
            >
<!--                class="custom-control-input"-->
              <input
                type="radio"
                [id]="'redAnswerIndicator' + dimensionIndex + questionIndex"
                [name]="'answerIndicator'"
                [value]="'RED'"
                [required]="true"
                formControlName="answerIndicator"
              />
<!--              class="custom-control-label"-->
              <label [for]="'answerIndicator' + dimensionIndex + questionIndex">
                Red
              </label>
            </div>

          </div>
        </ng-container>

<!--        <ng-container *ngSwitchCase="'PHOTO'">-->
<!--          <div>-->
<!--            <img-->
<!--              class="profile-image logo-height mb-3"-->
<!--              src="{{questionForm.value.answerPhoto?.location || 'assets/images/common/placeholder.jpg'}}"-->
<!--              alt="client-photo"-->
<!--            >-->

<!--            <app-image-upload-->
<!--              [label]="'Upload photo'"-->
<!--              (imageUploaded)="addPhoto($event)"-->
<!--            ></app-image-upload>-->

<!--            <div class="validation-notices">-->
<!--              <div-->
<!--                class="null-or-length-notice"-->
<!--                *ngIf="questionForm.get('answerPhoto').invalid && form.submitted"-->
<!--              >-->
<!--                <p class="font-size-sm font-weight-bold text-danger">-->
<!--                  This field is required!-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container>-->

<!--        <ng-container *ngSwitchCase="'TEXT'">-->
<!--          <app-input-text-->
<!--            [id]="'answerText' + dimensionIndex + questionIndex"-->
<!--            [style]="'default'"-->
<!--            [theme]="'light'"-->
<!--            [showOptional]="false"-->
<!--            [showLabel]="false"-->
<!--            [isRequired]="questionForm.value.mandatory"-->
<!--            [formSubmitted]="formSubmitted"-->
<!--            formControlName="answerText"-->
<!--          ></app-input-text>-->
<!--        </ng-container>-->

<!--        <ng-container *ngSwitchCase="'SINGLE_SELECT'">-->
<!--          <app-input-select-single-->
<!--            [id]="'answerSingleSelectValue' + dimensionIndex + questionIndex"-->
<!--            [style]="'default'"-->
<!--            [theme]="'light'"-->
<!--            [showOptional]="false"-->
<!--            [showLabel]="false"-->
<!--            [isRequired]="questionForm.value.mandatory"-->
<!--            [formSubmitted]="formSubmitted"-->
<!--            [items]="questionForm.value.choices"-->
<!--            formControlName="answerSingleSelectValue"-->
<!--          ></app-input-select-single>-->
<!--        </ng-container>-->

<!--        <ng-container *ngSwitchCase="'MULTI_SELECT'">-->
<!--          <app-input-select-multi-->
<!--            [id]="'answerMultiSelectValues' + dimensionIndex + questionIndex"-->
<!--            [style]="'default'"-->
<!--            [theme]="'light'"-->
<!--            [showOptional]="false"-->
<!--            [showLabel]="false"-->
<!--            [isRequired]="questionForm.value.mandatory"-->
<!--            [formSubmitted]="formSubmitted"-->
<!--            [items]="questionForm.value.choices"-->
<!--            formControlName="answerMultiSelectValues"-->
<!--          ></app-input-select-multi>-->
<!--        </ng-container>-->



<!--        <ng-container *ngSwitchDefault>-->
<!--          <p>No question type selected</p>-->
<!--        </ng-container>-->

<!--        -->

      </ng-container>
    </div>
  </div>
</form>
