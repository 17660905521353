import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../../../../services/loading/loading.service';
import {NotificationModel} from '../../store/notification.model';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';
import {NotificationService} from '../../store/notification.service';
import {AlertService} from 'app/shared/modules/alert/services/alert.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../../../../../modules/authentication/user/user.service';

export const notificationReceiveTypes: ChoiceInputItem[] = [
  {
    label: 'Std',
    value: 'STANDARD'
  },
  {
    label: 'Support',
    value: 'SUPPORT'
  },
  {
    label: 'Facilitator Requesting Access',
    value: 'FACILITATOR_REQUESTING_ACCESS'
  },
  {
    label: 'Organisation Requesting Client Access',
    value: 'ORGANISATION_REQUESTING_CLIENT_ACCESS'
  },
  {
    label: 'Facilitator Reset Password',
    value: 'FACILITATOR_RESET_PASSWORD'
  },
];

@Component({
  selector: 'app-notification-receive-view-modal',
  templateUrl: './notification-receive-view-modal.component.html',
  styleUrls: ['./notification-receive-view-modal.component.scss']
})
export class NotificationReceiveViewModalComponent implements OnInit {

  @Input() notification: NotificationModel;

  loading: Observable<boolean>;

  notificationReceiveTypes: ChoiceInputItem[] = notificationReceiveTypes;

  constructor(public activeModal: NgbActiveModal,
              public loadingService: LoadingService,
              private notificationService: NotificationService,
              private alertService: AlertService,
              private userService: UserService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loading = this.loadingService.getIsLoading();
  }

  closeModal(reason?: string) {
    this.activeModal.close(reason);
  }

  grantAccess() {
    this.loadingService.setIsLoading(true);
    this.notificationService.grantAccess(this.notification.requestAccessInfoHouseholdId,
      this.notification.requestAccessInfoOrganisationId).subscribe(results => {
      this.loadingService.setIsLoading(false);
      this.closeModal();
    }, () => {
      this.alertService.setAlert('Unable to grant access', 'error', 'view-notification');
      this.loadingService.setIsLoading(false);
    });
  }

  createFacilitator() {
    const route = '/organisation/facilitator-management/add';
    this.router.navigate([route], {state: {notification: this.notification}}).then(() => {
      this.closeModal();
    });
  }

  resetFacilitatorPassword() {
    this.loadingService.setIsLoading(true);
    this.userService.confirmForgotFacilitatorPassword(this.notification.forgotPasswordToken, this.notification.id).subscribe(() => {
      this.loadingService.setIsLoading(false);
      this.closeModal();
    }, error => {
      this.alertService.setAlert(
        (error && error.error && error.error.message) || 'Failed to reset facilitator password',
        'error',
        'view-notification'
      );
      this.loadingService.setIsLoading(false);
    });
  }
}
