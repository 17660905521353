import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SurveyScorecardPdfService} from '../../common/components/client-management-survey/services/survey-scorecard-pdf.service';

@Component({
  selector: 'app-scorecard-pdf-for-mobile',
  templateUrl: './scorecard-pdf-for-mobile.component.html',
  styleUrls: ['./scorecard-pdf-for-mobile.component.scss']
})
export class ScorecardPdfForMobileComponent implements OnInit {

  constructor(public route: ActivatedRoute,
              private surveyScorecardService: SurveyScorecardPdfService) {
  }

  ngOnInit(): void {
    const conductedSurveyId = this.route.snapshot.params.conductedSurveyId;
    // This is required for mobile app
    this.surveyScorecardService.init(conductedSurveyId, true);
  }

}
