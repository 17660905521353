import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    // see https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#configuration
    Chart.register(ChartDataLabels);
  }

}
