import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  toggled = new BehaviorSubject<boolean>(false);

  constructor() { }

  getToggled(): Observable<boolean> {
    return this.toggled.asObservable();
  }

  setToggled(loading: boolean): void {
    this.toggled.next(loading);
  }
}
