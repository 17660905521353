<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <p
      class="mb-2"
      [class]="style + '-input-label label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>

  <div
    [class]="style + '-input readonly-input font-weight-normal font-size-small text-left'"
    [class.borderless]="!hasBorder"
    [class.disabled]="isGrey"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    [textMask]="{mask: mask}"
  >
    <span
      *ngIf="stringPrepend"
      [class]="'input-prepend font-weight-normal ' + style + '-prepend'"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >
      {{stringPrepend}}
    </span>
    <span *ngIf="usePipe">
      {{value | number }}
    </span>
    <span *ngIf="!usePipe">
      {{value}}
    </span>
    <span
      *ngIf="stringAppend"
      [class]="'input-string-append font-weight-normal ' + style + '-append'"
      [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
      [class.default-no-label]="!showLabel && style === 'default'"
    >
      {{stringAppend}}
    </span>
  </div>
</div>
