import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChoiceInputItem} from '../../models/form-elements.model';

@Component({
  selector: 'app-input-select-single',
  templateUrl: './input-select-single.component.html',
  styleUrls: ['./input-select-single.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectSingleComponent),
      multi: true
    }
  ]
})
export class InputSelectSingleComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;
  @Input() items: ChoiceInputItem[];
  @Input() clearValueOnItemsChange = true;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() allowDelete = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() useCountryPipe?: boolean;
  @Input() useProvincePipe?: boolean;
  @Input() useDistrictPipe?: boolean;
  @Input() useSubDistrictPipe?: boolean;
  @Input() hasSearch: boolean;

  @Output() selectedItem = new EventEmitter<any>();

  placeHolder: string;
  valueSrc: any;

  set value(val: any) {
    this.valueSrc = val;
    this.onChange(val);
    this.onTouch();
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.items) {
      this.ref.detectChanges();
    }
    if (changes && changes.isReadOnly) {
      this.ref.detectChanges();
    }
  }

  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;
    }
    this.selectedItem.emit(value);
    this.onChange(value);
  }

  registerOnChange(fn: (value: any) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: any | any[]) {
  }

  onTouch() {
  }

  selectOption(item: string) {
    this.writeValue(item);
  }
}
