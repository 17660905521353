<!--<div class="dropdown btn-group">-->
<!--  <a ngbDropdown placement="bottom-right" class="nav-item dropdown">-->
<!--    <div-->
<!--      ngbDropdownToggle-->
<!--      class="nav-link dropdown-toggle user-toggle btn text-light btn-success btn-outline-light"-->
<!--    >-->
<!--      <i class="far fa-user mr-2"></i>-->
<!--      <p>{{user.username}}</p>-->
<!--    </div>-->

<!--    <div ngbDropdownMenu class="dropdown-menu bg-dark text-light text-center">-->

<!--      <a class="dropdown-item cursor-pointer text-light" routerLink="/authentication/resetPassword">-->
<!--        <p>Reset Password</p>-->
<!--      </a>-->

<!--      <hr class="dropdown-divider w-75 mx-auto">-->

<!--      <a class="dropdown-item cursor-pointer text-light" (click)="logout()">-->
<!--        <p>Logout</p>-->
<!--      </a>-->
<!--    </div>-->
<!--  </a>-->
<!--</div>-->


<!--<div *ngIf="isImpersonating" class="border border-danger text-danger d-inline-block">-->
<!--<span>Impersonating &nbsp;</span>-->
<!--{{user.username}}-->
<!--</div>-->

<div *ngIf="isImpersonating">
  <a class="btn btn-outline-success" (click)="unImpersonate()">
    Un-Impersonate
  </a>
</div>

<div class="bg-success text-light font-weight-normal initials-size"
     (click)="viewProfile()"
     *ngIf="!isImpersonating && !user?.photo?.location">
  <span>{{initials}}</span>
</div>

<img
    (click)="viewProfile()"
    *ngIf="!isImpersonating && user?.photo?.location"
    class="avatar"
    src="{{user.photo.location}}"
    alt="avatar"
  >
