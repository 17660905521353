import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChoiceInputItem} from '../../../models/form-elements.model';
import {ValidationService} from '../../../services/validation.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {debounce} from 'rxjs/operators';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-form-control-select-single',
  templateUrl: './form-control-select-single.component.html',
  styleUrls: ['./form-control-select-single.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlSelectSingleComponent),
      multi: true
    }
  ]
})
export class FormControlSelectSingleComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;
  @Input() items: ChoiceInputItem[];
  @Input() clearValueOnItemsChange = true;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() allowDelete = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() placeHolder: string;
  @Input() hasSearch: boolean;

  @Output() selectedItem = new EventEmitter<any>();

  valueSrc: any;
  searchValue: string;
  searchValueSubscription: Subscription;
  itemsClone: ChoiceInputItem[];

  hasError: 'null' | '';
  isTouched: boolean;

  set value(val: any) {
    this.onChange(val);
    this.onTouch();
    this.valueSrc = val;

    if (this.items) {
      const found = this.items.find(item => {
        if (typeof item.value === 'object' && item.value !== null && item.value.id && val) {
          return item.value.id === val.id;
        }
        return item.value === val;
      });
      if (found) {
        this.placeHolder = found.label;
      } else {
        this.placeHolder = 'Select an option...';
      }
    }

    this.validate();
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.items && !changes.items.firstChange) {
      if (this.items) {
        const found = this.items.find(item => {
          if (typeof item.value === 'object' && item.value !== null && item.value.id && this.valueSrc) {
            return item.value.id === this.valueSrc.id;
          }
          return item.value === this.valueSrc;
        });
        if (found) {
          this.placeHolder = found.label;
        } else {
          this.placeHolder = 'Select an option...';
        }
      }
      if (this.clearValueOnItemsChange) {
        this.remove();
      } else {
        this.validate();
      }
    }
    if (changes && changes.formSubmitted) {
      if (changes.formSubmitted.currentValue === true) {
        this.validate();
      }
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
  }

  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;
    }
    this.selectedItem.emit(value);
    this.onChange(value);
  }

  registerOnChange(fn: (value: any) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: any | any[]) {
  }

  onTouch() {
  }

  selectOption(item: ChoiceInputItem) {
    this.placeHolder = item.label;
    this.writeValue(item.value);
  }

  remove(event?: Event) {
    this.value = undefined;
    this.selectedItem.emit(undefined);
    this.validate();
    if (event) {
      // Prevent the dropdown from opening when a user removes an item
      event.stopPropagation();
    }
  }

  validate() {
    if (this.isRequired) {
      this.hasError = ValidationService.singleSelectControlHasError(this.isTouched, this.value, this.formSubmitted) ? 'null' : '';
    } else {
      this.hasError = '';
    }
  }

  subscribeToValue(input: NgModel) {
    this.itemsClone = this.items;
    this.searchValueSubscription = input.valueChanges.pipe(
      debounce(() => interval(500))
    ).subscribe(value => {
      if (value) {
        this.items = this.items.filter(item => item.label.toUpperCase().includes(value.toUpperCase()));
      } else {
        this.items = this.itemsClone;
      }
    });
  }

  unsubscribeFromValue() {
    this.searchValue = '';
    this.items = this.itemsClone;
  }
}
