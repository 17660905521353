import {Component, OnInit} from '@angular/core';
import {SessionQuery} from '../../../authentication/session/session.query';
import {User} from '../../../authentication/user/user.model';
import {Router} from '@angular/router';
import {SessionService} from '../../../authentication/session/session.service';

@Component({
  selector: 'app-registration-welcome',
  templateUrl: './registration-welcome.component.html',
  styleUrls: ['./registration-welcome.component.scss']
})
export class RegistrationWelcomeComponent implements OnInit {

  user: User;

  constructor(private sessionQuery: SessionQuery,
              private router: Router,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.user = this.sessionQuery.getUser();
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }
}
