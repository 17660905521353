import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input-textarea-disabled',
  templateUrl: './input-textarea-disabled.component.html',
  styleUrls: ['./input-textarea-disabled.component.scss']
})
export class InputTextareaDisabledComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() isGrey = true;
  @Input() hasBorder = true;

  constructor() { }

  ngOnInit() {
  }

}
