<app-spinner [loadingObs]="loading">
  <div>
    <div class="w-100 p-4">

      <!-- Adding this invisible button to allow the modal to scroll to the top -->
      <button class="close-modal d-none" type="button" (click)="closeModal()">
        <i class="fa fa-times-circle"></i>
      </button>

      <div class="card-body">
        <h4 class="category text-success text-center">
          Received Notification
        </h4>

        <hr class="dropdown-divider border-success w-50 mx-auto mb-2">

        <app-alert
          class="w-100"
          [id]="'view-notification'"
        ></app-alert>

        <div class="row mt-4">
          <div class="col-12">

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Subject: </strong></p>
              </div>
              <div class="col-12 border p-2">
                <p>{{notification.subject}}</p>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Message: </strong></p>
              </div>
            <div class="col-12 border p-2">
              <p [innerHTML]="notification.message"></p>
            </div>
          </div>

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Sender: </strong></p>
              </div>
              <div class="col-12 col-9 border p-2">
                <p>{{notification.sender}}</p>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Type: </strong></p>
              </div>
              <div class="col-12 border p-2">
                <p>{{notification.type | singleChoiceInputType: notificationReceiveTypes}}</p>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Date: </strong></p>
              </div>
              <div class="col-12 border p-2">
                <p>{{notification.dateTime | date : 'dd/MM/yyyy'}}</p>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 p-0">
                <p class="mt-2"><strong>Time: </strong></p>
              </div>
              <div class="col-12 border p-2">
                <p>{{notification.dateTime | date : 'shortTime'}}</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-light mr-2 border-2" type="button" (click)="closeModal()">Close</button>
        <button
          *ngIf="notification.type === 'ORGANISATION_REQUESTING_CLIENT_ACCESS'"
          (click)="grantAccess()"
          class="btn btn-success mr-2"
          type="button"
        >Grant Access</button>
        <button
          *ngIf="notification.type === 'FACILITATOR_REQUESTING_ACCESS'"
          (click)="createFacilitator()"
          class="btn btn-success mr-2"
          type="button"
        >Create Facilitator</button>
        <button
          *ngIf="notification.type === 'FACILITATOR_RESET_PASSWORD' && notification.forgotPasswordToken"
          (click)="resetFacilitatorPassword()"
          class="btn btn-success mr-2"
          type="button"
        >Reset Facilitator Password</button>
      </div>

    </div>
  </div>
</app-spinner>

