import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SidebarService} from '../../../services/sidebar/sidebar.service';
import {User} from '../../../modules/authentication/user/user.model';
import {SessionQuery} from '../../../modules/authentication/session/session.query';
import {environment} from '../../../../environments/environment';

declare let pendo: any;

@Component({
  selector: 'app-authenticated-container',
  templateUrl: './authenticated-container.component.html',
  styleUrls: ['./authenticated-container.component.scss']
})
export class AuthenticatedContainerComponent implements OnInit, OnDestroy {
  toggled: boolean;
  sidebarSubscription: Subscription;

  user: User;

  constructor(private sidebarService: SidebarService,
              private sessionQuery: SessionQuery) {
  }

  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.user = this.sessionQuery.getUser();
    this.sidebarSubscription = this.sidebarService.getToggled().subscribe(toggled => {
      this.toggled = toggled;
    });
    if (environment.production) {
      this.pendoInitialise();
    }
  }

  onActivate(top: HTMLElement) {
    top.scrollTo({
      top: 0
    });
  }

  private pendoInitialise() {
    pendo.initialize({
      visitor: {
        id: this.user.id,   // Required if user is logged in, default creates anonymous ID
        email: this.user.email,        // Recommended if using Pendo Feedback, or NPS Email
        full_name: this.user.firstName + ' ' + this.user.lastName,    // Recommended if using Pendo Feedback
        role: this.user.roles         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        id: this.user.organisationId ?
          'Org-' + this.user.organisationId : this.user.facilitatorId ?
            'Facilitator-' + this.user.facilitatorId : 'Hub',
        // orgId: this.user.organisationId,        // Optional

        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }
}
