import {Household} from 'app/modules/admin/components/client-management/store/client.model';
import {QuestionType} from 'app/modules/admin/components/survey-management/store/survey.model';
import {BaseUuidEntity} from 'app/shared/models/base-uuid-entity.model';
import {FileUploadUuidSpaces} from 'app/shared/modules/file-upload/models/file-upload.model';
import {BaseEntity} from '../../../../../shared/models/base-entity.model';
import {ChoiceInputItem} from '../../../../../shared/modules/form-elements/models/form-elements.model';

export enum SurveyType {
  GREENLIGHT = 'GREENLIGHT',
  GENERIC = 'GENERIC'
}

export enum TimelineType {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
  FIFTH = 'FIFTH'
}

export enum SurveySelectionType {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export interface ConductedSurvey extends BaseUuidEntity {
  surveyId: number;
  household: Household;
  facilitatorId: number;
  facilitatorUsername: string;
  facilitatorProvince: string;
  organisationId: number;
  organisationName: string;
  name: string;
  description: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';
  surveyType: SurveyType;
  // confidentialityConsent: string;
  instruction: string;
  dimensions: ConductedSurveyDimension[];

  // -- Used only for conducting the survey ------------------------
  answerConductedBy: string;
  answerTimeline: TimelineType;
  answerAcceptConfidentialityConsent: boolean;
  answerConsentPhoto: FileUploadUuidSpaces;
  answerConsentVoiceNote: FileUploadUuidSpaces;
  answerSubmitted: boolean;
  answerSubmittedDate: any;
  answerCaptureNotes: string;
}

export interface ConductedSurveyListDto {
  // base entity fields
  conductedSurveyId?: number;
  version?: number;
  createdBy?: string;
  modifiedBy?: string;
  createdOn?: number;
  modifiedOn?: number;

  surveyId: number;
  clientId: number;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;
  // household: HouseholdListDto;

  facilitatorId: number;
  facilitatorUsername: string;
  organisationId: number;
  organisationName: string;
  name: string;
  description: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';
  surveyType: SurveyType;
  // confidentialityConsent: string;
  instruction: string;
  dimensions: ConductedSurveyDimension[];

  // -- Used only for conducting the survey ------------------------
  answerConductedBy: string;
  answerTimeline: TimelineType;
  answerAcceptConfidentialityConsent: boolean;
  answerConsentPhoto: FileUploadUuidSpaces;
  answerConsentVoiceNote: FileUploadUuidSpaces;
  answerSubmitted: boolean;
  answerSubmittedDate: any;
  answerCaptureNotes: string;
}

export interface ConductedSurveyScorecardChartV3Dto {
  dimension: string;
  question: string;

  red: number;
  yellow: number;
  green: number;

  surveyCount: number;
}

export interface ConductedSurveyScorecardExportV3Dto {

  // householdId: string;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;

  category: string;
  subCategory: string;

  facilitatorName: string;

  dimensions: ConductedSurveyDimensionExportV3Dto[];

  // -- Used only for conducting the survey ------------------------
  answerTimeline: TimelineType;
  answerSubmittedDate: any;
}

export interface ConductedSurveyScorecardV2Dto {

  householdId: string;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;

  category: string;
  subCategory: string;

  facilitatorName: string;

  dimensions: ConductedSurveyDimensionV2Dto[];

  // -- Used only for conducting the survey ------------------------
  answerTimeline: TimelineType;
  answerSubmittedDate: any;
  answerCaptureNotes: string;
}

export interface ConductedSurveyScorecardExportV4Dto {

  id: any;
  surveyType: SurveyType;
  answerTimeline: TimelineType;
  answerSubmittedDate: any;
  facilitatorId: number;

  // householdId: string;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;

  category: string;
  subCategory: string;

  answerCaptureNotes: string;

  dimensions: ConductedSurveyDimensionExportV4Dto[];
}

export interface ConductedSurveyScorecardDto extends BaseUuidEntity {

  householdId: string;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;

  category: string;
  subCategory: string;

  facilitatorName: string;
  surveyType: SurveyType;

  dimensions: ConductedSurveyDimensionDto[];

  // -- Used only for conducting the survey ------------------------
  answerTimeline: TimelineType;
  answerCaptureNotes: string;
  answerSubmittedDate: any;
}

export interface ConductedSurveyGenericDto extends BaseUuidEntity {

  householdId: string;
  householdFirstName: string;
  householdLastName: string;
  householdIdNumber: string;
  householdLatitude: number;
  householdLongitude: number;

  category: string;
  subCategory: string;

  facilitatorName: string;
  surveyType: SurveyType;

  dimensions: ConductedSurveyDimensionGenericDto[];

  // -- Used only for conducting the survey ------------------------
  answerTimeline: TimelineType;
  answerSubmittedDate: any;
}

export interface ConductedSurveyMergeGroupV2Dto {
  primary: ConductedSurveyMergeV2Dto;
  secondary: ConductedSurveyMergeV2Dto;
}


export interface ConductedSurveyMergeV2Dto {
  householdId: string;
  householdName: string;
  // groupedSurveys: Map<string, Map<string, ConductedSurveyInfoDto[]>>;
  groupedSurveys: GroupedSurveys;
}

export interface GroupedSurveys {
  [orgKey: string]: {
    [surveyKey: string]: ConductedSurveyInfoDto[];
  };
}


export interface ConductedSurveyMergeGroupDto {
  primary: ConductedSurveyMergeDto;
  secondary: ConductedSurveyMergeDto;
}

export interface ConductedSurveyMergeTuple {
  surveyId: number;
  surveyName: string;
}

export interface ConductedSurveyMergeDto {
  householdId: string;
  householdName: string;
  surveyData: Map<ConductedSurveyMergeTuple, ConductedSurveyInfoDto[]>;
}

export interface ConductedSurveyInfoDto extends BaseEntity {
  surveyId: number;
  surveyName: string;
  organisationId: number;
  organisationName: string;
  timeline: TimelineType;

  originalTimeline: TimelineType;
  originalClientName: string;
}

export interface ConductedSurveyInclusiveDto {
  conductedSurvey: ConductedSurvey;

  facilitatorUsername: string;
  facilitatorProvince: string;
  facilitatorName: string;

  categoryList: string[];
  subCategoryList: string[];
}

export interface ConductedSurveyInclusiveListDto {
  conductedSurvey: ConductedSurveyListDto;

  facilitatorUsername: string;
  facilitatorProvince: string;
  facilitatorName: string;

  categoryList: string[];
  subCategoryList: string[];
}

export interface ConductedSurveyDimension extends BaseUuidEntity {
  name: string;
  sortOrder: number;

  questions: ConductedSurveyQuestion[];
}

export interface ConductedSurveyDimensionDto extends BaseUuidEntity {
  name: string;
  sortOrder: number;
  questions: ConductedSurveyQuestionDto[];
}

export interface ConductedSurveyDimensionExportV3Dto {
  name: string;
  sortOrder: number;
  questions: ConductedSurveyQuestionExportV3Dto[];
}

export interface ConductedSurveyDimensionV2Dto {
  name: string;
  sortOrder: number;
  questions: ConductedSurveyQuestionV2Dto[];
}

export interface ConductedSurveyDimensionExportV4Dto {
  id: any;
  name: string;
  sortOrder: number;
  questions: ConductedSurveyQuestionExportV4Dto[];
}

export interface ConductedSurveyDimensionGenericDto extends BaseUuidEntity {
  name: string;
  sortOrder: number;
  questions: ConductedSurveyQuestionGenericDto[];
}

export interface ConductedSurveyQuestion extends BaseUuidEntity {
  label: string;
  sortOrder: number;
  type: QuestionType;

  // if QuestionType == INDICATOR
  optionSet: ConductedSurveyQuestionOptionSet;

  // if QuestionType == PHOTO || TEXT || SINGLE_SELECT || MULTI_SELECT
  mandatory: boolean;

  // if QuestionType == SINGLE_SELECT || MULTI_SELECT
  choices: ConductedSurveyQuestionChoice[];


  // -- Used only for conducting the survey ------------------------------------
  // if QuestionType == INDICATOR
  answerIndicator: SurveySelectionType;

  // if QuestionType == PHOTO
  answerAcceptPhotoConsent: boolean;
  answerPhoto: FileUploadUuidSpaces;

  // if QuestionType == TEXT
  answerText: string;

  // if QuestionType == SINGLE_SELECT
  answerSingleSelectValue: string;

  // if QuestionType == MULTI_SELECT
  answerMultiSelectValues: string[];


  // -- Priority ----------------------------------------------------------------
  answerPriority: boolean;


  // -- Actions ----------------------------------------------------------------
  answerHowWillYouGetIt: string;
  answerWhyDontYouHaveIt: string;
  answerHowManyMonths: number;
}

export interface ConductedSurveyQuestionDto extends BaseUuidEntity {
  label: string;
  type: QuestionType;

  // -- Used only for conducting the survey ------------------------------------
  // if QuestionType == INDICATOR
  answerIndicator: SurveySelectionType;

  // if QuestionType == PHOTO
  answerAcceptPhotoConsent: boolean;
  answerPhoto: FileUploadUuidSpaces;

  // if QuestionType == TEXT
  answerText: string;

  // if QuestionType == SINGLE_SELECT
  answerSingleSelectValue: string;

  // if QuestionType == MULTI_SELECT
  answerMultiSelectValues: string[];


  // -- Priority ----------------------------------------------------------------
  answerPriority: boolean;


  // -- Actions ----------------------------------------------------------------
  answerHowWillYouGetIt: string;
  answerWhyDontYouHaveIt: string;
  answerHowManyMonths: number;
}

export interface ConductedSurveyQuestionV2Dto extends BaseUuidEntity {
  label: string;
  type: QuestionType;
  sortOrder: number;

  // -- Used only for conducting the survey ------------------------------------
  // if QuestionType == INDICATOR
  answerIndicator: SurveySelectionType;

  // if QuestionType == PHOTO
  answerAcceptPhotoConsent: boolean;
  answerPhoto: FileUploadUuidSpaces;

  // if QuestionType == TEXT
  answerText: string;

  // if QuestionType == SINGLE_SELECT
  answerSingleSelectValue: string;

  // if QuestionType == MULTI_SELECT
  answerMultiSelectValues: string[];


  // -- Priority ----------------------------------------------------------------
  answerPriority: boolean;


  // -- Actions ----------------------------------------------------------------
  answerHowWillYouGetIt: string;
  answerWhyDontYouHaveIt: string;
  answerHowManyMonths: number;
}

export interface ConductedSurveyQuestionExportV3Dto {
  label: string;
  type: QuestionType;
  sortOrder: number;

  // -- Used only for conducting the survey ------------------------------------
  // if QuestionType == INDICATOR
  answerIndicator: SurveySelectionType;

  // if QuestionType == PHOTO
  // answerAcceptPhotoConsent: boolean;
  // answerPhoto: FileUploadUuidSpaces;
  answerPhotoLocation: string;
  answerPhotoFileName: string;

  // if QuestionType == TEXT
  answerText: string;

  // if QuestionType == SINGLE_SELECT
  answerSingleSelectValue: string;

  // if QuestionType == MULTI_SELECT
  answerMultiSelectArray: string[];


  // // -- Priority ----------------------------------------------------------------
  // answerPriority: boolean;
  //
  //
  // // -- Actions ----------------------------------------------------------------
  // answerHowWillYouGetIt: string;
  // answerWhyDontYouHaveIt: string;
  // answerHowManyMonths: number;
}

export interface ConductedSurveyQuestionExportV4Dto {
  id: any;
  label: string;
  type: QuestionType;
  sortOrder: number;

  answerIndicator: SurveySelectionType;
}

export interface ConductedSurveyQuestionGenericDto extends BaseUuidEntity {
  label: string;
  type: QuestionType;
  sortOrder: number;
  mandatory: boolean;

  choicesAsString: string;
  choicesArray: ChoiceInputItem[];

  // -- Used only for conducting the survey ------------------------------------
  // if QuestionType == PHOTO
  answerPhotoLocation: string; // photo location
  answerPhotoFileName: string; // photo file name

  // if QuestionType == TEXT
  answerText: string;

  // if QuestionType == SINGLE_SELECT
  answerSingleSelectValue: string; // answer string value
  // answerSingleSelectArray: string[];

  // if QuestionType == MULTI_SELECT
  answerMultiSelectValues: string; // list.join of answer string value
  answerMultiSelectArray: string[];

}

export interface ConductedSurveyQuestionOptionSet extends BaseUuidEntity {
  greenSurveyQuestionOption: ConductedSurveyQuestionOption;
  yellowSurveyQuestionOption: ConductedSurveyQuestionOption;
  redSurveyQuestionOption: ConductedSurveyQuestionOption;
}

export interface ConductedSurveyQuestionOption extends BaseUuidEntity {
  label: string;
  file: FileUploadUuidSpaces;
}

export interface ConductedSurveyQuestionChoice extends BaseUuidEntity {
  value: string;
  label: string;
}

export interface ConductedSurveyDeleteDto {
  idList: number[];
}
