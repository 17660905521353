import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionQuery} from '../../../modules/authentication/session/session.query';

@Injectable({
  providedIn: 'root'
})
export class FacilitatorGuard implements CanActivate {

  constructor(private sessionQuery: SessionQuery,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.sessionQuery.getUser();
    if (!user) {
      this.router.navigateByUrl('/authentication/login');
    } else if (!user.facilitatorId) {
      // TODO route to create profile
      // this.router.navigateByUrl('/organisation-registration/welcome');
    } else if (user.facilitatorStatus === 'INACTIVE') {
      this.router.navigateByUrl('/authentication/deactivated');
    }
    return this.sessionQuery.hasRole(['ROLE_FACILITATOR']);
  }
}
