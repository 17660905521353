import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {SessionQuery} from '../../../../../modules/authentication/session/session.query';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../../../modules/authentication/user/user.model';
import {Observable} from 'rxjs';
import {FileUploadSpaces} from '../../../file-upload/models/file-upload.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input() label: string;
  @Input() center = false;
  @Output() imageUploaded: EventEmitter<FileUploadSpaces> = new EventEmitter<FileUploadSpaces>();

  user: User;
  url = environment.apiUrl;
  uploader: FileUploader;
  uploading = false;

  errorMessage: string;
  allowedMimeType = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
  maxFileSize = 2 * 1024 * 1024; // 2mb

  constructor(private sessionQuery: SessionQuery,
              private http: HttpClient,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.user = this.sessionQuery.getUser();
    this.uploaderInit();
  }

  uploaderInit() {
    const uploaderURL = `${environment.apiUrl}/file`;

    this.uploader = new FileUploader({
      url: uploaderURL,
      autoUpload: true,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.maxFileSize
    });

    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
      this.uploader.authToken = 'Basic ' + this.sessionQuery.getToken();
      // if (this.additionalParameters && this.additionalParameters.length > 0) {
      //   this.additionalParameters.forEach(additionalParameter => {
      //     this.uploader.options.additionalParameter = {[additionalParameter.key]: additionalParameter.value};
      //   });
      // }
      this.uploading = true;
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number) => {
      // this.document = JSON.parse(response);
      this.uploading = false;
      this.imageUploaded.emit(JSON.parse(response));
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploading = false;

      if (status === 413 || response.includes('Maximum upload size exceeded')) {
        this.toastrService.error('File size exceeds maximum size (5MB) allowed.');
        // this.alertService.setAlert('File size exceeds maximum size (5MB) allowed.', 'error', 'uploadSingleDoc');
        console.log('File size exceeds maximum size (5MB) allowed.', 'error', 'uploadSingleDoc');
      } else {
        this.toastrService.error('An error occurred while uploading the file. [' + status + ']');
        // this.alertService.setAlert('An error occurred while uploading the file. [' + status + ']', 'error', 'uploadSingleDoc');
        console.log('An error occurred while uploading the file. [' + status + ']', 'error', 'uploadSingleDoc');
      }
    };

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      switch (filter.name) {
        case 'fileSize':
          this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
          break;
        case 'mimeType':
          const allowedTypes = this.allowedMimeType.join();
          this.errorMessage = `Type "${item.type}" is not allowed. Allowed types: "${allowedTypes}"`;
          break;
        default:
          this.errorMessage = `Unknown error (filter is ${filter.name})`;
      }
      this.toastrService.error(this.errorMessage);
    };
  }

  private getFile(bucket: string, location: string): Observable<any> {
    const url = `${environment.apiUrl}/file?bucket=${bucket}&location=${location}`;
    return this.http.get<any>(url);
  }
}
