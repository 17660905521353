import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChoiceInputItem} from '../../models/form-elements.model';

@Component({
  selector: 'app-input-select-multi',
  templateUrl: './input-select-multi.component.html',
  styleUrls: ['./input-select-multi.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectMultiComponent),
      multi: true
    }
  ]
})
export class InputSelectMultiComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() labelExtraInfo?: string;
  @Input() id: string;
  @Input() items: ChoiceInputItem[];

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';

  @Output() selectedItem = new EventEmitter<any>();

  placeHolder: string[] = [];
  valueSrc: any[];

  set value(val: any[]) {
    this.onChange(val);
    this.valueSrc = val;
  }

  get value(): any[] {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.items) {
      this.removeAll();
    }
  }

  writeValue(value: string[]) {
    if (value) {
      this.value = [...value];
    }
    this.selectedItem.emit(value);
    this.placeHolder = this.value.map(v => {
      const found = this.items.find(item => item.value === v);
      if (found) {
        return found.label;
      }
    });
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  onChange(value?: string | string[]) {
  }

  onTouch() {
  }

  selectOption(item: ChoiceInputItem) {
    this.placeHolder = this.placeHolder || [];

    if (!this.placeHolder.includes(item.label)) {
      this.placeHolder.push(item.label);
      this.value.push(item.value);
      this.writeValue(this.value);
    }
  }

  remove(item: string, idx: number, event: Event) {
    this.placeHolder = this.placeHolder.filter(placeholder => placeholder !== item);
    this.value = this.value.filter(answer => {
      const found = this.items.find(i => i.label === item);
      if (found && answer !== found.value) {
        return answer;
      }
    });
    if (event) {
      // Prevent the dropdown from opening when a user removes an item
      event.stopPropagation();
    }
  }

  removeAll() {
    this.placeHolder = [];
    this.value = [];
    this.onChange();
  }

  inSelectedAnswer(option: ChoiceInputItem) {
    return this.value.includes(option.value);
  }
}
