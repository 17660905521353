import {Injectable} from '@angular/core';
import {OrganisationProfile} from './organisation-profile.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface OrganisationProfileState extends EntityState<OrganisationProfile> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'organisation-profile',
})
export class OrganisationProfileStore extends EntityStore<OrganisationProfileState> {

  constructor() {
    super();
  }

}

