import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ChoiceInputItem} from '../../../models/form-elements.model';
import {ValidationService} from '../../../services/validation.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-form-control-checkbox',
  templateUrl: './form-control-checkbox.component.html',
  styleUrls: ['./form-control-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckboxComponent),
      multi: true
    }
  ]
})
export class FormControlCheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() name: string;
  @Input() id: string;
  @Input() choices: ChoiceInputItem[];

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() showBorder = true;
  @Input() theme = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() cols?: number;

  @Output() selected = new EventEmitter<string>();

  isTouched: boolean;
  selectedValue: string;
  hasError: 'null' | 'length' | '';
  checkBoxAnswers = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSubmitted) {
      if (changes.formSubmitted.currentValue === true) {
        this.validate();
      }
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
  }

  writeValue(value: any[]) {
    this.onChange(value);
    if (value) {
      // Every time an item is checked, emit that item.
      if (this.selectedValue) {
        this.selected.emit(this.selectedValue);
      }

      this.checkBoxAnswers = value.map(v => {
        const found = this.choices.find(item => item.value === v);
        if (found) {
          return found.value;
        }
      });
    } else {
      this.checkBoxAnswers = [];
    }
    this.validate();
  }

  registerOnChange(fn: (value: any) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: any) {
  }

  onTouch() {
  }

  checkItem(idx: number) {
    if (this.choices[idx].value === 'none') {
      this.checkBoxAnswers = ['none'];
    } else if (this.choices[idx].value === 'NONE') {
      this.checkBoxAnswers = ['NONE'];
    } else {
      // If the item exists in the array already, that means the user is clicking it again to remove it.
      const existsIdx = this.checkBoxAnswers.findIndex(option => option === this.choices[idx].value);
      if (existsIdx > -1) {
        this.selectedValue = this.checkBoxAnswers[existsIdx];
        this.checkBoxAnswers.splice(existsIdx, 1);
      } else {
        this.selectedValue = this.choices[idx].value;
        this.checkBoxAnswers.push(this.choices[idx].value);

        // remove none if exists
        const noneIdx = this.checkBoxAnswers.findIndex(option => {
          return option === 'none' || option === 'NONE';
        });
        if (noneIdx > -1) {
          this.checkBoxAnswers.splice(noneIdx, 1);
        }
      }
    }

    this.writeValue(this.checkBoxAnswers);
    this.validate();
  }

  validate() {
    if (this.isRequired) {
      this.hasError = ValidationService.multiControlHasError(this.checkBoxAnswers, this.formSubmitted, this.isTouched) ? 'null' : '';
    } else {
      this.hasError = '';
    }
  }

}
