import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChoiceInputItem} from '../../../form-elements/models/form-elements.model';

@Component({
  selector: 'app-chevron',
  templateUrl: './chevron.component.html',
  styleUrls: ['./chevron.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChevronComponent implements OnInit {

  @Input() appValid: boolean;
  @Input() isValid: boolean;
  @Input() isActive: boolean;
  @Input() isDisabled: boolean;
  @Input() title: ChoiceInputItem;
  @Input() popoverText?: string;
  @Input() showPopover = true;

  @Output() selected = new EventEmitter<string>();

  formattedTitle: { titleOne: string, titleTwo: string };

  constructor() {
  }

  ngOnInit() {
    const tempTitleArray = this.title.label.split(' ');
    if (tempTitleArray.length > 1) {
      this.formattedTitle = {
        titleOne: tempTitleArray[0],
        titleTwo: tempTitleArray[1]
      };
    }
  }

  onSelect() {
    this.selected.emit(this.title.value);
  }

}
