import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertMessage} from '../../models/alert.model';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent implements OnInit {

  @Input() alert: AlertMessage;
  @Output() dismiss = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.alert.config && this.alert.config.timeOut && this.alert.config.timeOut > 0) {
      setTimeout(() => {
        this.dismissAlert();
      }, this.alert.config.timeOut);
    }
  }

  dismissAlert() {
    this.dismiss.emit();
  }
}
