import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CancelConfirmHelperService {

  public get hasConfirmedCancel(): boolean {
    return this.hasConfirmedCancelSource;
  }

  public set hasConfirmedCancel(value: boolean) {
    this.hasConfirmedCancelSource = value;
  }

  public get isLoggingOut(): boolean {
    return this.isLoggingOutSource;
  }

  public set isLoggingOut(value: boolean) {
    this.isLoggingOutSource = value;
  }

  public mustConfirmLogout: boolean;

  private hasConfirmedCancelSource: boolean;
  private isLoggingOutSource: boolean;


  constructor() {
  }
}
