import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {DashboardComponent} from 'angular-google-charts';

/**
 * Using this mainly to remember filters, sorting, page number, etc. for the list pages.
 * So that when we route forward and then come back, we can remain where we were.
 */

/**
 * @description
 *
 * Provides a way to customize when activated routes get reused.
 *
 */

/**
 * see https://medium.com/@gerasimov.pk/how-to-reuse-rendered-component-in-angular-2-3-with-routereusestrategy-64628e1ca3eb
 * see also https://blog.bitsrc.io/angular-route-reuse-strategy-c7939ebbf797
 */

/**
 * Also see @CustomReuseStrategyV2, which is NOT currently in use but does work.
 *
 * This strategy uses the route url to store and clear the route.
 * We currently store all routes that have a /list in it.
 * We then clear the store
 */
export class CustomReuseStrategy implements RouteReuseStrategy {

  private handlers: {[key: string]: DetachedRouteHandle} = {};

  private topLevelUrls = [
    'client-management',
    'organisation-management',
    'facilitator-management',
    'survey-management', // survey for hub and conducted-survey for facilitator
    'reporting', // conducted-survey for hub and organisation
    'category-management', // organisation only
    'dashboard',
    'read-excel',
    'location-management',
    'analytics',
    'notification',
    'user',
    'profile',
  ];

  constructor() {
  }

  /**
   * Determines if this route (and its subtree) should be detached to be reused later.
   *
   * This will be called first while leaving from one route to another to determine whether the component can be detached
   * (stored instead of destroying it) for re-use.
   *
   * We can access the route details using the ActivatedRouteSnapshot param and we can return true if we want to detach,
   * or else return false.
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.url.join('/') === 'list';
  }

  /**
   * Stores the detached route.
   * Storing a null value should erase the previously stored value.
   *
   * When shouldDetach returned true means, this method will be called and here we can have our own logic to store
   * the component instance somewhere.
   *
   * We can get the current route details from the ActivatedRouteSnapshot param and its component instance from DetachedRouteHandle.
   * How we are storing is totally up to us, we can store it in the object, Map, service, or something else,
   * (we should be able to fetch the stored value in another method called retrieve which we are going to see below)
   *
   * It is recommended to store the route path and its respective component instance(DetachedRouteHandle) in a key-value pair
   * so that it will be easy to retrieve it when required.
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[route.parent.url.join('/') + '/' + route.url.join('/')] = handle;
  }

  /**
   * Determines if this route (and its subtree) should be reattached.
   *
   * This method is used to determine whether the component that we are trying to load should be re-used
   * (instead of creating it newly) or not
   *
   * Return true if we want to re-use the or else return false.
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // if we want to exclude routes
    if (route.component === DashboardComponent) {
      this.handlers = {};
      return false;
    }

    // // this is to remember all urls
    return !!this.handlers[route.parent.url.join('/') + '/' + route.url.join('/')];
  }

  /**
   * Retrieves the previously stored route.
   *
   * When shouldAttach returns true means, this method will be called, and here we need to retrieve and return
   * the component instance (DetachedRouteHandle) which we have stored in the store() method for this route path
   * (we can access the path using ActivatedRouteSnapshot param).
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.handlers[route.parent.url.join('/') + '/' + route.url.join('/')];
  }

  /**
   * Determines if a route should be reused.
   *
   * This method is used to determine whether the route should be re-used or not.
   * This must return true, in order to make both the Angular default strategy and our own strategy work.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // if the 2 routes(future & curr) come from a different group(/organisation or /client), then clear it from the store.
    // if it's from the same group, then we won't see 'client-management', etc.

    if (curr.routeConfig) {
      const path = curr.routeConfig.path;
      if (this.topLevelUrls.includes(path) && path !== future.routeConfig.path) {
        this.handlers = {};
      }
    }
    return future.routeConfig === curr.routeConfig;
  }
}
