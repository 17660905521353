import {Injectable} from '@angular/core';
import {OrganisationProfileStore} from './organisation-profile.store';
import {HttpClient} from '@angular/common/http';
import {NameDto, OrganisationProfile, OrganisationProfileInclusiveDto} from './organisation-profile.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {SessionQuery} from '../../authentication/session/session.query';
import {User} from '../../authentication/user/user.model';

@Injectable({providedIn: 'root'})
export class OrganisationProfileService {

  constructor(protected store: OrganisationProfileStore,
              private http: HttpClient,
              private sessionQuery: SessionQuery) {
  }

  createByOrganisation(organisation: OrganisationProfile): Observable<OrganisationProfile> {
    const organisationUsername = encodeURIComponent(this.sessionQuery.getUser().username);
    const url = `${environment.apiUrl}/organisation-profile?organisationUsername=${organisationUsername}`;
    return this.http.post<OrganisationProfile>(url, organisation);
  }

  createByHub(organisation: OrganisationProfile, orgUsername: string): Observable<OrganisationProfile> {
    const url = `${environment.apiUrl}/organisation-profile/admin?username=${orgUsername}`;
    return this.http.post<OrganisationProfile>(url, organisation);
  }

  createLegacyOrganisation(organisation: OrganisationProfile): Observable<OrganisationProfile> {
    const url = `${environment.apiUrl}/organisation-profile/legacy`;
    return this.http.post<OrganisationProfile>(url, organisation);
  }

  createLegacyUserOrganisation(body: { user: User, organisationProfile: OrganisationProfile }): Observable<any> {
    const url = `${environment.apiUrl}/organisation-profile/user-organisation/legacy`;
    return this.http.post<any>(url, body);
  }

  getAllOrganisations(): Observable<OrganisationProfileInclusiveDto[]> {
    const url = `${environment.apiUrl}/organisation-profile`;
    return this.http.get<OrganisationProfileInclusiveDto[]>(url);
  }

  getById(organisationId: number): Observable<OrganisationProfile> {
    const url = `${environment.apiUrl}/organisation-profile/${organisationId}`;
    return this.http.get<OrganisationProfile>(url);
  }

  update(organisation: OrganisationProfile) {
    const url = `${environment.apiUrl}/organisation-profile/${organisation.id}`;
    return this.http.put<OrganisationProfile>(url, organisation);
  }

  updateStatus(organisationId: number, status: string): Observable<any> {
    const url = `${environment.apiUrl}/organisation-profile/${organisationId}/status?status=${status}`;
    return this.http.put<any>(url, undefined);
  }

  getAllByFacilitatorId(facilitatorId: number): Observable<OrganisationProfile[]> {
    const url = `${environment.apiUrl}/organisation-facilitator/organisations?facilitatorId=${facilitatorId}`;
    return this.http.get<OrganisationProfile[]>(url);
  }


  getAllByFacilitatorUser(): Observable<OrganisationProfile[]> {
    const facilitatorId = this.sessionQuery.getUser().facilitatorId;
    const url = `${environment.apiUrl}/organisation-facilitator/organisations?facilitatorId=${facilitatorId}`;
    return this.http.get<OrganisationProfile[]>(url);
  }

  getAllBySurveyId(surveyId: number): Observable<OrganisationProfile[]> {
    const url = `${environment.apiUrl}/organisation-profile/by-survey?surveyId=${surveyId}`;
    return this.http.get<OrganisationProfile[]>(url);
  }

  getByOrganisationUser(): Observable<OrganisationProfile> {
    const organisationId = this.sessionQuery.getUser().organisationId;
    const url = `${environment.apiUrl}/organisation-profile/by-organisation-user?organisationId=${organisationId}`;
    return this.http.get<OrganisationProfile>(url);
  }

  checkUniqueName(name: string, id?: number): Promise<boolean> {
    let url = `${environment.apiUrl}/organisation-profile/unique`;
    url += `?value=${name}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<boolean>(url).toPromise();
  }

  getOrgNames(): Observable<NameDto[]> {
    const url = `${environment.apiUrl}/organisation-profile/names`;
    return this.http.get<NameDto[]>(url);
  }

  getOrgNamesByFacilitator(): Observable<NameDto[]> {
    const facilitatorId = this.sessionQuery.getUser().facilitatorId;
    const url = `${environment.apiUrl}/organisation-profile/names?facilitatorId=${facilitatorId}`;
    return this.http.get<NameDto[]>(url);
  }

  getAllNames(): Observable<NameDto[]> {
    const url = `${environment.apiUrl}/organisation-profile/names`;
    return this.http.get<NameDto[]>(url);
  }
}
