import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appItemGrid]'
})
export class ItemGridDirective implements OnInit {

  @Input() cols?: number;
  @Input() arrayLength: number;

  constructor(private el: ElementRef<HTMLElement>,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.renderer.setStyle(
      this.el.nativeElement,
      'display',
      'grid'
    );
    if (!this.cols) {
      this.setColumns(this.arrayLength);
    } else {
      this.renderer.setStyle(
        this.el.nativeElement,
        'grid-template',
        `auto / repeat(${this.cols}, 1fr)`
      );
    }
  }

  private setColumns(arrayLength: number): void {
    if (arrayLength < 8) {
      // 1 column
      this.renderer.setStyle(
        this.el.nativeElement,
        'grid-template',
        'auto / repeat(1, 1fr)'
      );
    } else if (arrayLength > 8 && arrayLength < 16) {
      // 2 columns
      this.renderer.setStyle(
        this.el.nativeElement,
        'grid-template',
        'auto / repeat(2, 1fr)'
      );
    } else if (arrayLength > 16) {
      // 3 columns
      this.renderer.setStyle(
        this.el.nativeElement,
        'grid-template',
        'auto / repeat(3, 1fr)'
      );
    }
  }
}
