import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  ConductedSurvey,
  ConductedSurveyDimension,
  ConductedSurveyQuestion,
  ConductedSurveyQuestionChoice,
  ConductedSurveyQuestionOptionSet
} from '../../../../organisation/components/organisation-survey-management/store/conducted-survey.model';
import {QuestionType} from '../../survey-management/store/survey.model';
import {createHouseholdForm} from './client.model';
import {createBaseUuidEntityForm} from '../../../../../shared/models/base-uuid-entity.model';

export function createConductedSurveyForm(destroy$, formBuilder: FormBuilder, params?: Partial<ConductedSurvey>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    surveyId: [params.surveyId, Validators.required],
    // household: createHouseholdForm(destroy$, formBuilder, params.household),

    name: [params.name, Validators.required],
    status: [params.status, Validators.required],
    surveyType: [params.surveyType, Validators.required],
    description: [params.description],
    instruction: [params.instruction, Validators.required],

    dimensions: createConductedSurveyDimensionListForm(formBuilder, params.dimensions),

    // ------- answer fields ----------------------------------------------
    answerConductedBy: [params.answerConductedBy, Validators.required],
    answerTimeline: [params.answerTimeline, Validators.required],
    answerAcceptConfidentialityConsent: [params.answerAcceptConfidentialityConsent],
    answerConsentPhoto: [params.answerConsentPhoto],
    answerConsentVoiceNote: [params.answerConsentVoiceNote],
    answerSubmitted: [params.answerSubmitted],
    answerSubmittedDate: [params.answerSubmittedDate],
    answerCaptureNotes: [params.answerCaptureNotes],
  }));
}


export function createConductedSurveyDimensionListForm(formBuilder: FormBuilder, params: ConductedSurveyDimension[]): FormArray {
  const list = new FormArray([]);
  if (params && params.length > 0) {
    params.forEach(conductedSurveyDimension => {
      list.push(createConductedSurveyDimensionForm(formBuilder, conductedSurveyDimension));
    });
  }
  return list;
}

export function createConductedSurveyDimensionForm(formBuilder: FormBuilder, params: Partial<ConductedSurveyDimension>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    name: [params.name, Validators.required],
    sortOrder: [params.sortOrder, Validators.required],

    questions: createConductedSurveyQuestionListForm(formBuilder, params.questions),
  }));
}


export function createConductedSurveyQuestionListForm(formBuilder: FormBuilder, params: ConductedSurveyQuestion[]): FormArray {
  const list = new FormArray([]);
  if (params && params.length > 0) {
    params.forEach(conductedSurveyQuestion => {
      list.push(createConductedSurveyQuestionForm(formBuilder, conductedSurveyQuestion));
    });
  }
  return list;
}

export function createConductedSurveyQuestionForm(formBuilder: FormBuilder, params: Partial<ConductedSurveyQuestion>): FormGroup {
  const formGroup = formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    label: [params.label, Validators.required],
    sortOrder: [params.sortOrder || 1, Validators.required],
    type: [params.type, Validators.required],

    // if QuestionType == PHOTO || TEXT || SINGLE_SELECT || MULTI_SELECT
    mandatory: [params.mandatory],

    // ------- answer fields ---------------------------------------------
    // if QuestionType == INDICATOR
    answerIndicator: [params.answerIndicator],

    // if QuestionType == PHOTO
    answerAcceptPhotoConsent: [params.answerAcceptPhotoConsent],
    answerPhoto: [params.answerPhoto, (params.type === QuestionType.PHOTO && params.mandatory) ? Validators.required : undefined],

    // if QuestionType == TEXT
    answerText: [params.answerText, (params.type === QuestionType.TEXT && params.mandatory) ? Validators.required : undefined],

    // if QuestionType == SINGLE_SELECT
    answerSingleSelectValue: [params.answerSingleSelectValue,
      (params.type === QuestionType.SINGLE_SELECT && params.mandatory) ? Validators.required : undefined],

    // if QuestionType == MULTI_SELECT
    answerMultiSelectValues: [params.answerMultiSelectValues,
      (params.type === QuestionType.MULTI_SELECT && params.mandatory) ? Validators.required : undefined],


    // -- Priority ----------------------------------------------------------------
    answerPriority: [params.answerPriority],

    // -- Actions ----------------------------------------------------------------
    answerHowWillYouGetIt: [params.answerHowWillYouGetIt],
    answerWhyDontYouHaveIt: [params.answerWhyDontYouHaveIt],
    answerHowManyMonths: [params.answerHowManyMonths],
  }));

  // populate data for optionSet and choices on edit
  // to run once-off
  const type = formGroup.get('type');
  setControls(type.value, params);


  function setControls(value: QuestionType, params1: Partial<ConductedSurveyQuestion>) {
    switch (value) {
      case 'INDICATOR': {
        formGroup.addControl('optionSet', createConductedOptionSetForm(formBuilder, params1.optionSet || {}));
        break;
      }
      case 'SINGLE_SELECT':
      case 'MULTI_SELECT': {
        const control = formGroup.get('choices');
        if (!control) {
          formGroup.addControl('choices', createConductedSurveyQuestionChoiceListForm(formBuilder, params1.choices));
        }
        break;
      }
    }
  }

  return formGroup;
}


export function createConductedOptionSetForm(formBuilder: FormBuilder, params?: Partial<ConductedSurveyQuestionOptionSet>) {
  return formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    greenSurveyQuestionOption: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.greenSurveyQuestionOption), {
      label: [params && params.greenSurveyQuestionOption && params.greenSurveyQuestionOption?.label || '', Validators.required],
      file: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.greenSurveyQuestionOption?.file), {
        bucket: [params.greenSurveyQuestionOption && params.greenSurveyQuestionOption?.file &&
        params.greenSurveyQuestionOption?.file?.bucket],
        firstKey: [params.greenSurveyQuestionOption && params.greenSurveyQuestionOption?.file &&
        params.greenSurveyQuestionOption?.file?.firstKey],
        location: [params.greenSurveyQuestionOption && params.greenSurveyQuestionOption?.file &&
        params.greenSurveyQuestionOption?.file?.location],
        secondKey: [params.greenSurveyQuestionOption && params.greenSurveyQuestionOption?.file &&
        params.greenSurveyQuestionOption?.file?.secondKey],
      })),
    })),

    redSurveyQuestionOption: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.redSurveyQuestionOption), {
      label: [params && params.redSurveyQuestionOption && params.redSurveyQuestionOption?.label || '', Validators.required],
      file: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.redSurveyQuestionOption?.file), {
        bucket: [params.redSurveyQuestionOption && params.redSurveyQuestionOption?.file &&
        params.redSurveyQuestionOption?.file?.bucket],
        firstKey: [params.redSurveyQuestionOption && params.redSurveyQuestionOption?.file &&
        params.redSurveyQuestionOption?.file?.firstKey],
        location: [params.redSurveyQuestionOption && params.redSurveyQuestionOption?.file &&
        params.redSurveyQuestionOption?.file?.location],
        secondKey: [params.redSurveyQuestionOption && params.redSurveyQuestionOption?.file &&
        params.redSurveyQuestionOption?.file?.secondKey],
      })),
    })),

    yellowSurveyQuestionOption: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.yellowSurveyQuestionOption), {
      label: [params && params.yellowSurveyQuestionOption && params.yellowSurveyQuestionOption?.label || '', Validators.required],
      file: formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params.yellowSurveyQuestionOption?.file), {
        bucket: [params.yellowSurveyQuestionOption && params.yellowSurveyQuestionOption?.file &&
        params.yellowSurveyQuestionOption?.file?.bucket],
        firstKey: [params.yellowSurveyQuestionOption && params.yellowSurveyQuestionOption?.file &&
        params.yellowSurveyQuestionOption?.file?.firstKey],
        location: [params.yellowSurveyQuestionOption && params.yellowSurveyQuestionOption?.file &&
        params.yellowSurveyQuestionOption?.file?.location],
        secondKey: [params.yellowSurveyQuestionOption && params.yellowSurveyQuestionOption?.file &&
        params.yellowSurveyQuestionOption?.file?.secondKey],
      })),
    })),
  }));
}

export function createConductedSurveyQuestionChoiceListForm(formBuilder: FormBuilder,
                                                            params: Partial<ConductedSurveyQuestionChoice[]>): FormArray {
  const list = new FormArray([]);
  if (params && params.length > 0) {
    params.forEach(funder => {
      list.push(createConductedSurveyQuestionChoiceForm(formBuilder, funder));
    });
  } else {
    list.push(createConductedSurveyQuestionChoiceForm(formBuilder, {}));
  }
  return list;
}

export function createConductedSurveyQuestionChoiceForm(formBuilder: FormBuilder,
                                                        params: Partial<ConductedSurveyQuestionChoice>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseUuidEntityForm(params), {
    value: [params && params?.value, Validators.required],
    label: [params && params?.label, Validators.required],
  }));
}
