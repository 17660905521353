import {
  ConductedSurvey,
  SurveySelectionType
} from '../../modules/organisation/components/organisation-survey-management/store/conducted-survey.model';
import {PslLogoDarkUtil} from '../base-64-images/psl-logo-dark-util';
import {PdfUtil} from './pdf-util';
import {SingleChoiceInputTypePipe} from '../modules/shared-common/pipes/choiceInput/single-choice-input-type.pipe';
import {ChoiceInputItem} from '../modules/form-elements/models/form-elements.model';
import {
  timelineTypes
} from 'app/common/components/client-management-survey/components/client-management-survey-edit-modal/client-management-survey-edit-modal.component';
import {QuestionType} from '../../modules/admin/components/survey-management/store/survey.model';

export class PdfScorecardUtil {
  private static singleChoiceInputTypePipe: SingleChoiceInputTypePipe = new SingleChoiceInputTypePipe();
  private static timelineTypes: ChoiceInputItem[] = timelineTypes;

  static scorecardHeader(conductedSurvey: ConductedSurvey, isGeneric = false) {
    return (currentPage, pageCount, pageSize) => {
      // you can apply any logic and return any valid pdfmake element
      return [this.getHeaderContent(conductedSurvey, isGeneric)];
    };
  }

  private static getHeaderContent(conductedSurvey: ConductedSurvey, isGeneric: boolean) {
    return [
      {
        margin: [20, 20, 20, 0],
        columns: [
          {
            margin: [10, 10, 0, 0],
            image: PslLogoDarkUtil.getImage(),
            width: 45,
            height: 50,
          },
          {
            margin: [40, 25, 0, 0],
            width: 200,
            text: isGeneric ? 'Generic Results' : 'Scorecard and Action Plan',
            fontSize: 12,
            bold: true,
            decoration: 'underline'
          },
          [
            {
              margin: [0, 10, 0, 0],
              columns: [{
                columns: [
                  {
                    width: 40,
                    margin: [0, 2, 0, 0],
                    text: 'Client Name',
                  },
                  PdfUtil.getHeaderBorderedText(`${conductedSurvey.household.firstName} ${conductedSurvey.household.lastName}`, 90),
                ],
              }],
            },
            {
              columns: [{
                columns: [
                  {
                    width: 40,
                    margin: [0, 2, 0, 0],
                    text: 'Organisation',
                  },
                  PdfUtil.getHeaderBorderedText(conductedSurvey.organisationName, 90),
                ]
              }],
            },
            {
              columns: [{
                columns: [
                  {
                    width: 40,
                    margin: [0, 2, 0, 0],
                    text: 'Facilitator',
                  },
                  PdfUtil.getHeaderBorderedText(
                    `${conductedSurvey.answerConductedBy}`, 90
                  ),
                ]
              }],
            },
          ], [
            {
              margin: [0, 10, 0, 0],
              columns: [{
                columns: [
                  {
                    width: 50,
                    margin: [5, 2, 0, 0],
                    text: 'Date of Survey',
                  },
                  // const dateOptions = {
                  //       month: 'long',
                  //       day: 'numeric',
                  //       year: 'numeric',
                  //     };
                  //     const dateString = date.toLocaleDateString('en-ZA', dateOptions);
                  //     const year = date.getFullYear();
                  PdfUtil.getHeaderBorderedText(
                    conductedSurvey.answerSubmitted ?
                      new Date(conductedSurvey.answerSubmittedDate).toLocaleDateString('en-ZA') :
                      'Not Submitted',
                    90),
                ]
              }],
            },
            {
              columns: [{
                columns: [
                  {
                    width: 50,
                    margin: [5, 2, 0, 0],
                    text: 'Survey Timeline',
                  },
                  PdfUtil.getHeaderBorderedText(
                    this.singleChoiceInputTypePipe.transform(conductedSurvey.answerTimeline, this.timelineTypes), 90),
                ]
              }],
            },
            {
              columns: [{
                columns: isGeneric ? [] : [
                  {
                    width: 50,
                    margin: [5, 2, 0, 0],
                    text: 'Scorecard Score',
                  },
                  PdfUtil.getHeaderBorderedText(this.getColumnTotals(conductedSurvey), 90),
                ]
              }],
            },
          ]
        ],
      },
      {canvas: [{type: 'line', x1: 25, y1: 10, x2: 570, y2: 10, lineWidth: 1}]} // Top line
    ];
  }

  private static getColumnTotals(conductedSurvey: ConductedSurvey): string {
    const totals = this.reduceColumn(conductedSurvey);

    // return '30G, 15Y, 5R'
    return `${totals.green}G, ${totals.yellow}Y, ${totals.red}R`;
  }

  private static reduceColumn(conductedSurvey: ConductedSurvey) {
    return conductedSurvey.dimensions.reduce((acc, baselineDimension) => {

      const baselineQuestions = baselineDimension.questions.filter(bq => bq.type === QuestionType.INDICATOR);

      return baselineQuestions.reduce((accQ, baselineQuestion) => {
        const type: SurveySelectionType = baselineQuestion.answerIndicator;
        if (type) {
          accQ[type.toLowerCase()] += 1;
        }
        return accQ;
      }, acc);

    }, {
      green: 0,
      yellow: 0,
      red: 0,
    });

    //
    //
    //
    // return list.reduce((acc, item) => {
    //   // SurveySelectionType.RED
    //   const type: SurveySelectionType = item[timelineType];
    //   if (type) {
    //     acc[type.toLowerCase()] += 1;
    //   }
    //   return acc;
    // }, {
    //   green: 0,
    //   yellow: 0,
    //   red: 0,
    // });
  }
}
