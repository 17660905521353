import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {FileUploadModule} from 'ng2-file-upload';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule
  ],
  declarations: [ImageUploadComponent],
  exports: [ImageUploadComponent]
})
export class ImageUploadModule {
}
