import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from '../../../authentication/session/session.service';

@Component({
  selector: 'app-registration-submitted',
  templateUrl: './registration-submitted.component.html',
  styleUrls: ['./registration-submitted.component.scss']
})
export class RegistrationSubmittedComponent implements OnInit {

  constructor(private router: Router,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }
}
