import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../../../shared/models/base-entity.model';
import {ConductedSurveyDimension, SurveyType, TimelineType} from '../../../../organisation/components/organisation-survey-management/store/conducted-survey.model';
import {FileUploadSpaces, FileUploadUuidSpaces} from '../../../../../shared/modules/file-upload/models/file-upload.model';

export enum QuestionType {
  INDICATOR = 'INDICATOR',
  PHOTO = 'PHOTO',
  TEXT = 'TEXT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT'
}

export interface Survey extends BaseEntity {
  name: string;
  status: 'DRAFT' | 'ACTIVE' | 'DEACTIVATED' | 'DELETED';
  surveyType: SurveyType;
  description: string;
  instruction: string;
}

export interface SurveyDto extends BaseEntity {
  name?: string;
  status?: 'DRAFT' | 'ACTIVE' | 'DEACTIVATED' | 'DELETED';
  surveyType?: SurveyType;
  description?: string;
  // confidentialityConsent: string;
  instruction?: string;

  dimensions?: SurveyDimensionDto[];
}

export interface SurveyDimensionDto extends BaseEntity {
  name: string;
  sortOrder: number;

  questions: SurveyQuestionDto[];
}

export interface SurveyQuestionDto extends BaseEntity {
  label: string;
  sortOrder: number;
  type: QuestionType;

  // if QuestionType == INDICATOR
  optionSet: SurveyQuestionOptionSet;

  // if QuestionType == PHOTO || TEXT || SINGLE_SELECT || MULTI_SELECT
  mandatory: boolean;

  // if QuestionType == SINGLE_SELECT || MULTI_SELECT
  choices: SurveyQuestionChoice[];
}

export interface SurveyQuestionChoice extends BaseEntity {
  value: string;
  label: string;
}

export interface SurveyQuestionOptionSet extends BaseEntity {
  greenOption: SurveyQuestionOption;
  yellowOption: SurveyQuestionOption;
  redOption: SurveyQuestionOption;
}

export interface SurveyQuestionOption extends BaseEntity {
  label: string;
  file: FileUploadSpaces;
}

export interface ConductedSurveyReturnDto extends BaseEntity {

  // Only to be used by the update endpoint

  surveyId?: number;
  surveyName?: string;
  householdId?: number;
  facilitatorId?: number;
  organisationId?: number;

  name?: string;
  description?: string;
  status?: 'ACTIVE' | 'DELETED' | 'DEACTIVATED' | 'DRAFT';
  surveyType?: SurveyType;
  nstruction?: string;

  dimensions?: ConductedSurveyDimension[];


  // -- Used only for conducting the survey ------------------------
  // only set on create
  answerConductedBy?: string;
  answerTimeline?: TimelineType;

  answerAcceptConfidentialityConsent?: boolean;

  answerConsentPhoto?: FileUploadUuidSpaces;
  answerConsentVoiceNote?: FileUploadUuidSpaces;


  answerSubmitted?: boolean;
  answerCaptureNotes?: string;

  answerSubmittedDate?: any;
}

export interface SurveyDimensionReturnDto extends BaseEntity {
  name: string;
  sortOrder: number;

  questions: SurveyQuestionReturnDto[];
}

export interface SurveyQuestionReturnDto extends BaseEntity {
  label: string;
  sortOrder: number;
  type: QuestionType;

  // if QuestionType == INDICATOR
  answerIndicator?: 'RED' | 'YELLOW' | 'GREEN';

  // if QuestionType == PHOTO || TEXT || SINGLE_SELECT || MULTI_SELECT
  mandatory: boolean;

  // if QuestionType == SINGLE_SELECT || MULTI_SELECT
  choices: SurveyQuestionChoice[];
}

export function createSurvey(params: Partial<Survey>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params.name,
    status: params.status,
    surveyType: params.surveyType,
    description: params.description,
    instruction: params.instruction,

  }) as Survey;
}


export function createSurveyForm(formBuilder: FormBuilder, params?: Partial<Survey>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params.name, Validators.required],
    surveyType: [params.surveyType, Validators.required],
    description: [params.description],
    instruction: [params.instruction, Validators.required],

    // used for conducted survey
    status: [params.status],
  }));
}

export function createNameForm(formBuilder: FormBuilder, name: string): FormGroup {
  return formBuilder.group({
    name: [name, Validators.required],
  });
}

export function createSurveyInstructionForm(formBuilder: FormBuilder, instruction: string): FormGroup {
  return formBuilder.group({
    instruction: [instruction, Validators.required],
  });
}
