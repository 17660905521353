<div class="d-flex align-items-stretch min-height-100 bg-dark text-white">
  <div class="p-5">
    <div class="error-number fw-900 text-warning text-uppercase">Error 404</div>
    <h6 class="fw-300 mb-0">Page not found :(</h6>
    <small class="ff-headers fw-300">
      Sorry, but the page you were trying to view does not exist.
      <a class="btn-link" [routerLink]="['/']">Take me home</a>
    </small>

    <br/>

    <div [innerHtml]="content"></div>

  </div>
</div>
