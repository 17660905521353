import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {InputMask} from '../../../models/form-elements.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../services/validation.service';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {UniqueValueService} from '../../../services/unique-value.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-form-control-number',
  templateUrl: './form-control-number.component.html',
  styleUrls: ['./form-control-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlNumberComponent),
      multi: true
    }
  ]
})
export class FormControlNumberComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('ngModel', {static: true}) ngModel: NgModel;

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() stringPrepend?: string;
  @Input() stringAppend?: string;
  @Input() maxLength?: number;
  @Input() minLength = 0;

  // Default
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() theme = 'light';
  @Input() style: 'default' | 'bordered' = 'default';
  @Input() numberMask: InputMask;

  @Input() isUnique = false;
  @Input() uniqueId?: number;
  @Input() urlFragment?: string;
  @Input() uniqueAdditionalParam?: string;
  @Input() uniqueError = 'That value is already in use. Please try another';

  @Output() childValue = new EventEmitter<string>();

  public valueSrc: string;
  mask: any;
  hasError: 'null' | 'length-over' | 'length-under' | '';
  valueSubscription: Subscription;
  hasUniqueError: boolean;
  isLoading: boolean;

  set value(val: any) {
    if (this.valueSrc !== val) {
      this.valueSrc = FormControlNumberComponent.removeThousandsSeparators(val);
      this.childValue.emit(this.valueSrc);
      this.onChange(this.valueSrc);
      this.onTouch();
      this.validate();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  static removeThousandsSeparators(value: any) {
    if (value && typeof value === 'string') {
      return parseInt(value.replace(/,/g, ''), 0);
    }
    return value;
  }

  constructor(private uniqueValueService: UniqueValueService) {
  }

  ngOnInit() {
    this.mask = this.createStandardNumberMask(this.numberMask);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSubmitted) {
      if (changes.formSubmitted.currentValue === true) {
        this.validate();
      }
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
  }

  ngAfterViewInit() {
    if (this.ngModel) {
      this.valueSubscription = this.ngModel.valueChanges
        .pipe(
          debounceTime(1000),
        ).subscribe(value => {
          if (value && this.isUnique) {
            this.isLoading = true;
            this.uniqueValueService.checkIsUnique(value, this.urlFragment, this.uniqueAdditionalParam, this.uniqueId)
              .subscribe(isUnique => {
                this.hasUniqueError = !isUnique;
                this.isLoading = false;
              });
          }
          this.validate();
        });
    }
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

  validate() {
    if (this.isRequired) {
      this.hasError = ValidationService.numberControlHasNullError(this.ngModel.control, this.formSubmitted) ? 'null' : '';
    } else {
      this.hasError = '';
    }

    if (this.maxLength && this.hasError !== 'null') {

      let value: number;

      // TODO: Replace this with the number util when it's available.
      if (this.value && typeof this.value === 'string') {
        value = parseInt(this.valueSrc.replace(/,/g, ''), 0);
      }

      this.hasError = ValidationService.numberControlHasLengthError(value, this.maxLength, 'max') ? 'length-over' : '';
      if (value > this.maxLength) {
        this.ngModel.control.patchValue(this.maxLength.toString());
        if (this.maxLength === this.minLength) {
          this.hasError = '';
        }
      }
    }

    if (this.minLength && (this.hasError !== 'null' && this.hasError !== 'length-over')) {


      let value: number;

      // TODO: Replace this with the number util when it's available.
      if (this.value && typeof this.value === 'string') {
        value = parseInt(this.valueSrc.replace(/,/g, ''), 0);
      }

      this.hasError = ValidationService.numberControlHasLengthError(value, this.minLength, 'min') ? 'length-under' : '';
      if (value < this.minLength) {
        this.ngModel.control.patchValue(this.minLength.toString());
      }
    }
  }

  select(event: any) {
    event.target.select();
  }

  createStandardNumberMask(options?: InputMask) {
    const config = Object.assign({}, {
      prefix: '',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: false,
      decimalSymbol: '.',
      decimalLimit: 2,
      integerLimit: undefined,
      requireDecimal: false,
      allowNegative: false,
      allowLeadingZeroes: false
    }, options);
    return createNumberMask(config);
  }

}
