import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {LoadingService} from '../../loading/loading.service';
import {ToastrService} from 'ngx-toastr';
import {TruncateUtil} from '../../../shared/utils/truncate-util';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private loadingService: LoadingService,
              private router: Router,
              private toastr: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => take(1)),

      catchError((error: HttpErrorResponse) => {
        if (!environment.production) {
          console.log(error);
        }

        if (error?.error?.message) {
          this.toastr.error(TruncateUtil.truncate(error.error.message, 100), 'System Error');
        } else
          // @ts-ignore
        if (error?.error?.statusMessage) {
          // @ts-ignore
          this.toastr.error(TruncateUtil.truncate(error.error?.statusMessage, 100), 'System Error');
        } else if (error?.message) {
          this.toastr.error(TruncateUtil.truncate(error.message, 100), 'System Error');
        }

        // Expired token message === Full authentication is required to access this resource
        // @ts-ignore
        if ((error.status === 401 || error.status === 403) && (
          // @ts-ignore
          error?.error?.statusMessage === 'Session token expired' ||
          // @ts-ignore
          error?.error?.statusMessage === 'Full authentication is required to access this resource' ||
          error?.error?.message === 'Session token expired' ||
          error?.message === 'Session token expired' ||
          error?.error?.message === 'Full authentication is required to access this resource' ||
          error?.message === 'Full authentication is required to access this resource')) {
          this.router.navigateByUrl('/authentication');
        }

        this.loadingService.setIsLoading(false);
        return throwError(error);
      })
    );
  }
}
