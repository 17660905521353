export type AlertType = 'success' | 'error' | 'info' | 'warning';

export class AlertGlobalConfig {
  maxAlertsOpen: number;
  autoDismiss: boolean;
  defaultTimeout: number;

  constructor(params: AlertGlobalConfig) {
    this.maxAlertsOpen = params.maxAlertsOpen;
    this.autoDismiss = params.autoDismiss;
    this.defaultTimeout = params.defaultTimeout;
  }

}

export interface AlertConfig {
  timeOut?: number;
  userDismissible?: boolean;
  iconClass?: string;
}

export interface AlertMessage {
  message: string;
  alertType: AlertType;
  id?: string;
  config?: AlertConfig;
}

export function createAlertConfig(params: Partial<AlertConfig>): AlertConfig {
  return {
    timeOut: params && params.timeOut || 0,
    userDismissible: params && params.userDismissible,
    iconClass: params && params.iconClass
  } as AlertConfig;
}

export function createAlert(params: Partial<AlertMessage>): AlertMessage {
  return {
    message: params && params.message,
    alertType: params && params.alertType,
    id: params && params.id,
    config: createAlertConfig(params && params.config)
  } as AlertMessage;
}
