import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  content: string;

  constructor(public router: Router, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.content = `
      <div>
          <span class="text-muted">404.component.ts:</span><br/>
          <span class="text-muted">404.component.ts: route</span><br/>
          <code>${this.route}</code><br/>
          <span class="text-muted">404.component.ts: router</span><br/>
          <code>${this.router}</code><br/>
          <span class="text-muted">404.component.ts: router url</span><br/>
          <code>${this.router.url}</code><br/>
          <span class="text-muted">404.component.ts: router snapshot url</span><br/>
          <code>${this.router.routerState.snapshot.url}</code><br/>
      </div>
  `;
  }
}
