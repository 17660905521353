<div class="w-100" [class.border]="hasBorder">
  <a
    *ngIf="!isEditable"
    href="javascript:void(0)"
    (click)="isEditable = true"
  >
    {{ value }}
    <i class="text-dark fa fa-1x fa-pencil-alt"></i>
  </a>

  <input
    *ngIf="isEditable"
    [(ngModel)]="value"
    (mouseover)="$event.target.select()"
  >
  <button
    *ngIf="isEditable"
    class="btn btn-sm btn-success"
    (click)="updateValue(value)"
  >Save
  </button>
</div>
