import {Pipe, PipeTransform} from '@angular/core';
import {NgbDateNativeAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  constructor(private dateAdapter: NgbDateNativeAdapter) {

  }

  transform(value: number): NgbDateStruct {
    const tempValue = new Date(value);
    return this.dateAdapter.fromModel(tempValue);
  }

}
