<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-select-single
    [label]="label"
    [id]="id"
    [items]="items"
    [clearValueOnItemsChange]="clearValueOnItemsChange"
    [name]="id"
    [formSubmitted]="formSubmitted"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [allowDelete]="allowDelete"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [placeHolder]="placeHolder"
    [theme]="theme"
    [style]="style"
    (selectedItem)="selectOption($event)"
    [(ngModel)]="value"
  ></app-form-control-select-single>
</ng-container>

<ng-template #disabledInput>
<!--  <app-input-select-disabled-->
<!--    [label]="label"-->
<!--    [items]="items"-->
<!--    [theme]="theme"-->
<!--    [style]="style"-->
<!--    [isGrey]="isGrey"-->
<!--    [hasBorder]="hasBorder"-->
<!--    [useCountryPipe]="useCountryPipe"-->
<!--    [useProvincePipe]="useProvincePipe"-->
<!--    [useDistrictPipe]="useDistrictPipe"-->
<!--    [useSubDistrictPipe]="useSubDistrictPipe"-->
<!--    [value]="value"-->
<!--  ></app-input-select-disabled>-->

  <app-input-text
    [label]="label"
    [id]="id"
    [style]="style"
    [theme]="theme"
    [isReadOnly]="true"
    [showOptional]="false"
    [ngModel]="value | singleChoiceInputType: items"
  ></app-input-text>
</ng-template>
