export class PdfUtil {
  // https://pdfmake.github.io/docs/0.1/
  // http://pdfmake.org/playground.html
  static docDefinition() {
    return {
      info: {},
      pageMargins: [0, 110, 0, 20],
      background: this.pageBorder(),
      header: (currentPage, pageCount, pageSize) => {
      },
      footer: this.footer(),
      // (currentPage, pageCount) => {
      // },
      content: [],
      images: {},
      styles: {
        sectionHeading: {
          fontSize: 21,
          bold: true,
          decoration: 'underline',
          margin: [0, 0, 0, 15],
          alignment: 'center'
        },
        center: {
          alignment: 'center'
        },
        provinceHeading: {
          fontSize: 17,
          bold: true,
          decoration: 'underline',
          margin: [0, 0, 0, 15],
          alignment: 'center'
        },
        sectionSubHeading: {
          fontSize: 17,
          bold: true,
          decoration: 'underline',
          margin: [0, 15, 0, 10]
        },
        sectionSubSubHeading: {
          fontSize: 13,
          bold: true,
          decoration: 'underline',
          margin: [0, 10, 0, 5]
        },
        bold: {
          bold: true,
          decoration: 'underline',
          margin: [7, 5, 0, 5]
        },
        boldUnderline: {
          bold: true,
          decoration: 'underline',
        },
        tableHeader: {
          // bold: true,
          alignment: 'center'
        },
        tableBorderText: {
          margin: [0, 0, 0, 1]

          // Additional table styles
          // ['font',
          // 'fontSize',
          // 'bold',
          // 'italics',
          // 'alignment',
          // 'color',
          // 'columnGap',
          // 'fillColor',
          // 'decoration',
          // 'decorationStyle',
          // 'decorationColor',
          // 'background',
          // 'lineHeight'
          // //'tableCellPadding'
          // // 'cellBorder',
          // // 'headerCellBorder',
          // // 'oddRowCellBorder',
          // // 'evenRowCellBorder',
          // // 'tableBorder'
          // ]
        },
        timelineRows: {
          alignment: 'left',
          fontSize: 6,
          // lineHeight: 1.00
        },
      },
      defaultStyle: {
        // alignment: 'justify',
        // font: 'Arial',
        fontSize: 5,
        lineHeight: 1.15
      }
    };
  }

  static pageBorder() {
    return (currentPage, pageSize) => [
      {
        canvas: [
          {type: 'line', x1: 10, y1: 10, x2: 585, y2: 10, lineWidth: 1}, // Top line
          {type: 'line', x1: 10, y1: 10, x2: 10, y2: 830, lineWidth: 1}, // Left line
          {type: 'line', x1: 10, y1: 830, x2: 585, y2: 830, lineWidth: 1}, // Bottom line
          {type: 'line', x1: 585, y1: 10, x2: 585, y2: 830, lineWidth: 1}, // Right line
        ]
      }
    ];
  }

  static footer() {
    return (currentPage, pageCount) => {
      return [{
        alignment: 'right',
        margin: [0, 0, 15, 20],
        text: 'PAGE ' + currentPage.toString() + ' OF ' + pageCount
      }];
    };
  }

  static getHeaderBorderedText(text: string, width: number) {
    return {
      style: 'tableBorderText',
      color: '#555',
      table: {
        widths: [width],
        body: [[{text: text || ''}]]
      },
      layout: {
        hLineWidth(i, node) {
          return (i === 0 || i === node.table.body.length) ? 0.5 : 1;
        },
        vLineWidth(i, node) {
          return (i === 0 || i === node.table.widths.length) ? 0.5 : 1;
        },

        hLineColor(i, node) {
          return (i === 0 || i === node.table.body.length) ? '#555' : '#555';
        },
        vLineColor(i, node) {
          return (i === 0 || i === node.table.widths.length) ? '#555' : '#555';
        },
        paddingLeft(i, node) {
          return 4;
        },
        paddingRight(i, node) {
          return 4;
        },
        paddingTop(i, node) {
          return 3;
        },
        paddingBottom(i, node) {
          return 3;
        }
      }
    };
  }

  static getContentBorderedText(text: string) {
    return {
      style: 'tableBorderText',
      color: '#555',
      table: {
        widths: [100],
        body: [[{text: text || ''}]]
      },
      layout: {
        hLineWidth(i, node) {
          return (i === 0 || i === node.table.body.length) ? 0.5 : 1;
        },
        vLineWidth(i, node) {
          return (i === 0 || i === node.table.widths.length) ? 0.5 : 1;
        },

        hLineColor(i, node) {
          return (i === 0 || i === node.table.body.length) ? '#555' : '#555';
        },
        vLineColor(i, node) {
          return (i === 0 || i === node.table.widths.length) ? '#555' : '#555';
        },
        paddingLeft(i, node) {
          return 4;
        },
        paddingRight(i, node) {
          return 4;
        },
        paddingTop(i, node) {
          return 3;
        },
        paddingBottom(i, node) {
          return 3;
        }
      }
    };
  }

  static tableLayout() {
    return {
      hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 2 : 1,
      vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length) ? 2 : 1,
      hLineColor: (i, node) => (i === 0 || i === node.table.body.length) ? 'black' : 'gray',
      vLineColor: (i, node) => (i === 0 || i === node.table.widths.length) ? 'black' : 'gray',
    };
  }

  static checkedBox(value: boolean) {
    return {
      table: {
        widths: [1],
        body: [
          [
            {
              margin: [3, 0, 0, 0],
              border: [false, false, false, false],
              image: (value ? 'checked' : 'unchecked'),
              width: 6
            }
          ]
        ]
      }
    };
  }

  static getWhiteCircle() {
    return {
      canvas: [{
        type: 'ellipse',
        x: 11, y: 7,
        r1: 5, r2: 5,
        color: '#EEEEEE',
      }]
    };
  }

  static getRedCircle() {
    return {
      // stack: [
      //   {
      canvas: [{
        type: 'ellipse',
        x: 11, y: 7,
        r1: 5, r2: 5,
        color: '#FF3636',
      }]
      //   },
      //   {
      // text: 'Stuck',
      // alignment: 'center',
      // fontSize: 11,
      // bold: true,
      // decoration: 'underline'
      // },
      // ]
    };
  }

  static getYellowCircle() {
    return {
      canvas: [{
        type: 'ellipse',
        x: 11, y: 7,
        r1: 5, r2: 5,
        color: '#FFB236',
      }]
    };
  }

  static getGreenCircle() {
    return {
      canvas: [{
        type: 'ellipse',
        x: 11, y: 7,
        r1: 5, r2: 5,
        color: '#18ce0f',
      }]
    };
  }

  static getRedCellFillColor() {
    return {
      text: 'Stuck',
      fillColor: '#FF3636',
      style: 'timelineRows',
      alignment: 'center',
      margin: [0, 0, 0, 0],
      // lineHeight: 1.00
    };
  }

  static getYellowCellFillColor() {
    return {
      text: 'Struggling',
      fillColor: '#FFB236',
      style: 'timelineRows',
      alignment: 'center',
      margin: [0, 0, 0, 0],
      // lineHeight: 1.00
    };
  }

  static getGreenCellFillColor() {
    return {
      text: 'Doing well',
      fillColor: '#18ce0f',
      style: 'timelineRows',
      alignment: 'center',
      margin: [0, 0, 0, 0],
      // lineHeight: 1.00
    };
  }
}
