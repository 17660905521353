<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <p class="text-secondary disabled">MENU</p>
  </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/dashboard"
        routerLinkActive="active"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/client-management"
        routerLinkActive="active"
      >Client Management</a>
    </li>

    <li [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/survey-management"
        routerLinkActive="active"
      >Reporting</a>
    </li>

     <li [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/analytics"
        routerLinkActive="active"
      >Analytics</a>
    </li>

    <li [ngbNavItem]="5">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/notification"
        routerLinkActive="active"
      >Notifications</a>
    </li>

    <li [ngbNavItem]="6">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/profile"
        routerLinkActive="active"
      >Facilitator Profile</a>
    </li>

    <li [ngbNavItem]="7">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/facilitator/bi-dashboard-map"
        routerLinkActive="active"
      >Map of Greenlight Movement Members</a>
    </li>

<!--    <li *ngIf="!!user.hasAnyBiReporting" [ngbNavItem]="8">-->
<!--      <a-->
<!--        ngbNavLink-->
<!--        class="sidebar-link"-->
<!--        routerLink="/facilitator/bi-dashboard"-->
<!--        routerLinkActive="active"-->
<!--      >Bi-Dashboard</a>-->
<!--    </li>-->

  </ul>
</div>
