<div [class]="style + '-input-group'">
  <label
    *ngIf="showLabel"
    class="mb-2"
    [class]="style + '-input-label'"
    [class.text-grey]="isReadOnly"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    [for]="id">{{label}}
  </label>
  <input
    #datePicker="ngbDatepicker"
    #model="ngModel"
    [id]="id"
    [name]="id"
    [minDate]="minDate"
    [maxDate]="maxDate"
    placeholder="dd/mm/yyyy"
    [attr.aria-label]="label"
    [class]="style + '-input date-input'"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    [class.border-danger]="hasError === 'null'"
    [class.border-warning]="hasError === 'length'"
    [required]="isRequired"
    [disabled]="isReadOnly"
    [class.disabled]="isReadOnly"
    [(ngModel)]="dateValue"
    (blur)="validate()"
    (click)="!isReadOnly && datePicker.toggle()"
    readonly
    ngbDatepicker
  >
  <i
    [ngClass]="theme === 'light'? ['text-dark'] : ['text-light']"
    [class]="style + '-floating-icon'"
    class="fa fa-2x fa-calendar-alt"
    [class.default-floating-no-label]="!showLabel && style === 'default'"
    (click)="!isReadOnly && datePicker.toggle()"
  ></i>

  <ngb-timepicker
    *ngIf="hasTime"
    id="time"
    [meridian]="true"
    [spinners]="false"
    [required]="isRequired"
    [readonlyInputs]="isReadOnly"
    [(ngModel)]="timeValue"
  ></ngb-timepicker>
  <div class="input-validation-notices mt-1" *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <p class="font-size-sm font-weight-bold">This field is optional</p>
      </div>
    </ng-container>
    <div class="validation-notice null-notice" [class.invisible]="hasError !== 'null'">
      <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
    </div>
  </div>
</div>
