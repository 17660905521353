import {Component, Injectable, TemplateRef} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  // Docs: https://solinta.atlassian.net/wiki/spaces/Sol2020/pages/906002449/Modal+Documentation

  modalRef: NgbModalRef;
  hasActiveModal: boolean;

  defaultModalOptions: NgbModalOptions = {
    ariaLabelledBy: 'Basic Dialogue',
    scrollable: true,
    backdropClass: 'z-index-995',
    windowClass: 'z-index-999',
  };

  constructor(private ngbModalService: NgbModal) {
  }

  openBasicModal(content: (TemplateRef<HTMLElement | any> | Component),
                 options?: Partial<NgbModalOptions>,
                 params?: { key: any, value: any }[]) {
    if (!this.hasActiveModal) {
      const modalOptions = {...this.defaultModalOptions, ...options};
      this.modalRef = this.ngbModalService.open(content, modalOptions);
      if (this.modalRef.componentInstance) {
        params.forEach(param => {
          this.modalRef.componentInstance[param.key] = param.value;
        });
      }
      this.hasActiveModal = true;
      this.modalRef.result
        .then(() => {
          // This runs when the modal is closed with the close() function
          this.hasActiveModal = false;
        })
        .catch(() => {
          // This runs when the modal is dismissed (ie: by clicking the backdrop or esc key)
          this.hasActiveModal = false;
        });
    } else if (!environment.production) {
      throw new Error('Cannot create a new modal instance when a modal instance is already open');
    }
  }

  openStackedModal(content: any,
                   options?: Partial<NgbModalOptions>,
                   params?: { key: any, value: any }[]): NgbModalRef {
    const modalOptions = {...this.defaultModalOptions, ...options};
    this.modalRef = this.ngbModalService.open(content, modalOptions);
    if (this.modalRef.componentInstance) {
      params.forEach(param => {
        this.modalRef.componentInstance[param.key] = param.value;
      });
    }

    return this.modalRef;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
