import {Component, Input, OnInit} from '@angular/core';
import {ChoiceInputItem} from '../../../models/form-elements.model';

@Component({
  selector: 'app-input-checkbox-disabled',
  templateUrl: './input-checkbox-disabled.component.html',
  styleUrls: ['./input-checkbox-disabled.component.scss']
})
export class InputCheckboxDisabledComponent implements OnInit {

  @Input() label: string;
  @Input() values: string[];
  @Input() choices: ChoiceInputItem[];
  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() cols?: number;

  constructor() { }

  ngOnInit() {
  }

}
