import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-widget-check-two',
  templateUrl: './widget-check-two.component.html',
  styleUrls: ['./widget-check-two.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetCheckTwoComponent),
      multi: true
    }
  ]
})
export class WidgetCheckTwoComponent implements OnInit, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;
  @Input() name: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  // Default
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'dark' | 'light' = 'light';

  public valueSrc: boolean;
  hasError: 'null' | 'length' | '';

  set value(val: boolean) {
    if (val !== undefined && val !== null && this.valueSrc !== val) {
      this.valueSrc = val;
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): boolean {
    return this.valueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(val: boolean): void {
    this.value = val;
    this.onChange(val);
  }

  registerOnChange(fn: (value: boolean) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isReadOnly = isDisabled;
  }

  onChange(value?: boolean) {
  }

  onTouch() {
  }

}
