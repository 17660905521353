import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionQuery} from '../../../modules/authentication/session/session.query';

@Injectable({
  providedIn: 'root'
})
export class OrganisationGuard implements CanActivate {

  constructor(private sessionQuery: SessionQuery,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.sessionQuery.getUser();
    const isOrganisation = this.sessionQuery.hasRole(['ROLE_ORGANISATION']);
    if (!user) {
      this.router.navigateByUrl('/authentication/login');
    } else if (isOrganisation) {
      if (!user.organisationId) {
        this.router.navigateByUrl('/organisation-registration/welcome');
      } else if (user.organisationStatus === 'PENDING') {
        this.router.navigateByUrl('/organisation-registration/submitted');
      } else if (user.organisationStatus === 'INACTIVE' || user.organisationStatus === 'DELETED') {
        this.router.navigateByUrl('/authentication/deactivated');
      }
    }
    return isOrganisation;
  }
}
