import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {SessionStore, SessionState} from './session.store';
import {Roles} from '../user/user.model';

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<SessionState> {

  private isLoggedIn$ = this.select(state => state.token !== null);
  private token$ = this.select(state => state.token);
  private user$ = this.select(state => state.user);
  private latitude$ = this.select(state => state.latitude);
  private longitude$ = this.select(state => state.longitude);

  constructor(protected store: SessionStore) {
    super(store);
  }

  hasRole(roles: Roles[]) {
    let matched = false;
    const user = this.getValue().user;

    if (user && user.roles && user.roles.length) {
      user.roles.forEach(role => {
        if (roles.includes(role)) {
          matched = true;
        }
      });
    }

    return matched;
  }

  getRoles() {
    return this.getValue().user.roles;
  }

  isLoggedIn() {
    return this.isLoggedIn$;
  }

  getUserObservable() {
    return this.user$;
  }

  getTokenObservable() {
    return this.token$;
  }

  getToken() {
    return this.getValue().token;
  }

  getUser() {
    return this.getValue().user;
  }

  getLatitude() {
    return this.getValue().latitude;
  }

  getLongitude() {
    return this.getValue().longitude;
  }

}
