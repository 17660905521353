import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {createUser, User} from '../user/user.model';

export interface SessionState {
  name: string;
  user: User;
  token: string;
  latitude: number;
  longitude: number;
}

export function createInitialState(): SessionState {
  return {
    name: null,
    user: null,
    token: null,
    latitude: undefined,
    longitude: undefined
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session'})
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

  login(session: SessionState) {
    this.update(session);
  }

  logout() {
    this.update(createInitialState());
  }

  resetToken(password: string) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    const authToken = btoa(updatedUser.username + ':' + password);

    this.update({
      token: authToken
    });
  }

  setOrgIdOnUser(id: number) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.organisationId = id;
    this.update({
      user: updatedUser
    });
  }

  setOrgStatusOnUser(status: string) {
    const session = this.getValue();
    const updatedUser = createUser(session.user);
    updatedUser.organisationStatus = status;
    this.update({
      user: updatedUser
    });
  }

  updateUser(user: User) {
    this.update({
      user
    });
  }

  updateGps(latitude: number, longitude: number) {
    this.update({
      latitude,
      longitude
    });
  }
}

