import {ChangeDetectorRef, Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() {
  }

  public static controlHasNullError(control: FormControl, submitted: boolean, ref: ChangeDetectorRef): boolean {
    let value: string = control.value;
    if (value && typeof value === 'string') {
      value = value.trim();
      ref.detectChanges();
    }
    return (control.touched || submitted) && (!value || value === '');
  }

  public static dateControlHasError(control: FormControl, submitted: boolean): boolean {
    return (control.touched || submitted) && (!control.value || control.value === {});
  }

  public static multiControlHasError(values: string[], formSubmitted: boolean, touched: boolean) {
    return (formSubmitted || touched) && values.length === 0;
  }

  public static multiSelectControlHasError(isTouched: boolean, values: string[], formSubmitted: boolean) {
    return (isTouched || formSubmitted) && (!values || values.length === 0);
  }

  public static singleChoiceControlHasError(value: any, formSubmitted: boolean) {
    return formSubmitted && ((!value && value !== false) || value === '');
  }

  public static singleSelectControlHasError(isTouched: boolean, value: any, formSubmitted: boolean) {
    return (isTouched || formSubmitted) && ((!value && value !== false) || value === '');
  }

  public static controlHasLengthError(control: FormControl, length: number, type?: 'max' | 'min'): boolean {
    const value: string = control.value;
    return type === 'max' ?
      value && value.length >= length :
      value && value.length < length;
  }

  public static numberControlHasLengthError(value: number, comparison: number, type: 'max' | 'min'): boolean {
    return type === 'max' ?
      value >= comparison :
      value <= comparison;
  }

  public static numberControlHasNullError(control: FormControl, submitted: boolean) {
    return (control.touched || submitted) && (!control.value && control.value !== 0);
  }

  public static controlHasMinLengthError(control: FormControl, minLength: number) {
  }
}
