import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-editable-text-label',
  templateUrl: './editable-text-label.component.html',
  styleUrls: ['./editable-text-label.component.scss']
})
export class EditableTextLabelComponent implements OnInit {

  @Input() value: string;
  @Input() isEditable = false;
  @Input() hasBorder = false;
  @Output() valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  updateValue(value: string) {
    this.valueChange.emit(value);
    this.isEditable = false;
  }
}
