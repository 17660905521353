<div
  class="alert"
  role="alert"
  [ngClass]="alert.alertType === 'error' ? 'alert-danger' : 'alert-' + alert.alertType"
>
  <div class="alert-body">
    <div class="alert-icon">
      <ng-container *ngIf="alert.config.iconClass; else fetchIcon">
        <i [class]="alert.config.iconClass"></i>
      </ng-container>
      <ng-template #fetchIcon>
        <ng-container *ngTemplateOutlet="icon; context: {type: alert.alertType}"></ng-container>
      </ng-template>
    </div>
    <p class="alert-message align-self-center">{{alert.message}}</p>
    <button
      *ngIf="alert.config.userDismissible"
      class="alert-close"
      type="button"
      (click)="dismissAlert()"
    >
      <i class="fa fa-times fa-sm"></i>
    </button>
  </div>
</div>

<ng-template #icon let-type="type">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'success'">
      <i class="fa fa-2x fa-check-circle"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <i class="fa fa-2x fa-times-circle"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'info'">
      <i class="fa-2x fa fa-info-circle"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'warning'">
      <i class="fa fa-2x fa-exclamation-circle"></i>
    </ng-container>
  </ng-container>
</ng-template>
