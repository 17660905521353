import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminGuard} from './common/gaurds/admin-guard/admin.guard';
import {OrganisationGuard} from './common/gaurds/organisation-guard/organisation.guard';
import {FacilitatorGuard} from './common/gaurds/facilitator-guard/facilitator.guard';
import {AuthenticatedContainerComponent} from './common/components/authenticated-container/authenticated-container.component';
import {OrganisationAuthenticationGuard} from './common/gaurds/organisation-authentication-guard/organisation-authentication.guard';
import {RegistrationWelcomeComponent} from './modules/organisation-registration/components/registration-welcome/registration-welcome.component';
import {RegistrationProfileComponent} from './modules/organisation-registration/components/registration-profile/registration-profile.component';
import {RegistrationSubmittedComponent} from './modules/organisation-registration/components/registration-submitted/registration-submitted.component';
import {OrganisationRegistrationGuard} from './common/gaurds/organisation-registration-guard/organisation-registration.guard';
import {ErrorComponent} from './common/components/error/error.component';
import {ScorecardPdfForMobileComponent} from './modules/scorecard-pdf-for-mobile/scorecard-pdf-for-mobile.component';

const routes: Routes = [
  // no landing page atm, do not delete as it might come in later
  // {path: 'landing', component: LandingContainerComponent},
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {path: '', pathMatch: 'full', redirectTo: 'authentication'},

  {
    path: 'scorecard/:conductedSurveyId',
    component: ScorecardPdfForMobileComponent,
  },

  {
    path: 'organisation-registration/welcome',
    component: RegistrationWelcomeComponent,
    canActivate: [OrganisationRegistrationGuard]
  },
  {
    path: 'organisation-registration/form',
    component: RegistrationProfileComponent,
    canActivate: [OrganisationRegistrationGuard]
  },
  {
    path: 'organisation-registration/submitted',
    component: RegistrationSubmittedComponent,
    canActivate: [OrganisationAuthenticationGuard]
  },

  {
    path: '',
    component: AuthenticatedContainerComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'organisation',
        loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule),
        canActivate: [OrganisationGuard]
      },
      {
        path: 'facilitator',
        loadChildren: () => import('./modules/facilitator/facilitator.module').then(m => m.FacilitatorModule),
        canActivate: [FacilitatorGuard]
      },
    ]
  },

  {path: '**', pathMatch: 'full', component: ErrorComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
