import {Component, OnInit} from '@angular/core';
import {SessionQuery} from '../../../../../modules/authentication/session/session.query';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {
  activeId: number;
  roleLegacy: boolean;
  roleSuperHub: boolean;
  roleSuperAdmin: boolean;

  constructor(private sessionQuery: SessionQuery) {
  }

  ngOnInit(): void {
    // ROLE_LEGACY
    this.roleLegacy = this.sessionQuery.hasRole(['ROLE_LEGACY']);
    this.roleSuperHub = this.sessionQuery.hasRole(['ROLE_SUPER_HUB']);
    this.roleSuperAdmin = this.sessionQuery.hasRole(['ROLE_SUPER_ADMIN']);
  }

}
