import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionQuery} from '../../../modules/authentication/session/session.query';

@Injectable({
  providedIn: 'root'
})
export class OrganisationRegistrationGuard implements CanActivate {

  constructor(private sessionQuery: SessionQuery,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.sessionQuery.getUser();
    if (user && !user.organisationId) {
      const isOrganisation = this.sessionQuery.hasRole(['ROLE_ORGANISATION']);
      if (isOrganisation) {
        return this.sessionQuery.hasRole(['ROLE_ORGANISATION']);
      }
    }
    this.router.navigateByUrl('/authentication/login');
  }
}
