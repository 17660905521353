import {Pipe, PipeTransform} from '@angular/core';
import {NameDto} from '../../../../../modules/organisation-registration/store/organisation-profile.model';

@Pipe({
  name: 'nameDto'
})
export class NameDtoPipe implements PipeTransform {

  transform(id: number, nameDtoList: NameDto[]): string {
    return this.getTypeByValue(id, nameDtoList);
  }

  getTypeByValue(id: number, nameDtoList: NameDto[]): string {
    if (nameDtoList) {
      const found = nameDtoList.find(item => item.id === id);
      if (found) {
        return found.name;
      }
    }

    return id.toString();
  }

}
