<div class="position-relative">
  <label
    *ngIf="showLabel"
    class="mb-2 input-label"
    [class]="style + '-input-multi-legend'"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
  >
    {{label}}
    <small *ngIf="labelExtraInfo">{{labelExtraInfo}}</small>
    <ng-container *ngIf="helpLink">
      <p
        class="is-link-small"
        role="button"
        (click)="onHelpClick.emit()"
      >{{helpLink}}</p>
    </ng-container>
  </label>

  <fieldset>
    <div
      appItemGrid
      [cols]="cols"
      [arrayLength]="choices.length"
      [class]="style + '-input-multi-group checkbox-group'"
      [class.border-danger]="hasError === 'null'"
      [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    >


      <ng-container *ngIf="choices">
        <ng-container *ngFor="let choice of choices; let idx = index">
          <div
             class="flex-start custom-control custom-radio pl-2"
          >
<!--              class="custom-control-input"-->
            <input
              type="radio"
              id="{{id + idx}}"
              [name]="name"
              [value]="choice.value"
              [required]="isRequired"
              [disabled]="choice.isDisabled"
              [attr.aria-label]="choice.label"
              (blur)="validate()"
              [(ngModel)]="value"
            />
<!--            class="custom-control-label"-->
            <label class="pl-1 pt-half" for="{{id + idx}}">{{choice.label}}</label>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </fieldset>

  <div class="input-validation-notices mt-1" *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <p class="font-size-sm font-weight-bold">This field is optional</p>
      </div>
    </ng-container>
    <ng-container *ngIf="hasError === 'null'">
      <div class="validation-notice null-notice">
        <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
      </div>
    </ng-container>
  </div>
</div>
