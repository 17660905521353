import {Component, Input, OnInit} from '@angular/core';
import {ChoiceInputItem} from '../../../models/form-elements.model';

@Component({
  selector: 'app-input-select-disabled',
  templateUrl: './input-select-disabled.component.html',
  styleUrls: ['./input-select-disabled.component.scss']
})
export class InputSelectDisabledComponent implements OnInit {

  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() label: string;
  @Input() items: ChoiceInputItem[];
  @Input() value = 'Select an option';
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() useCountryPipe?: boolean;
  @Input() useProvincePipe?: boolean;
  @Input() useDistrictPipe?: boolean;
  @Input() useSubDistrictPipe?: boolean;

  constructor() { }

  ngOnInit() {
  }

}
