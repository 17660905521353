<!--<svg-->
<!--  class="chevron-svg"-->
<!--  [class.svg-active-height]="isActive"-->
<!--  width="159"-->
<!--  height="43"-->
<!--  (click)="onSelect()"-->
<!--&gt;-->
<!--  <g transform="translate(-11.637 -103.31)">-->
<!--    <path-->
<!--      class="chevron-body"-->
<!--      d="m12.271 103.7 159.55 0.3202 15.111 23.461-13.127 22.817-159.28-0.0539 9.3764-22.649z"-->
<!--      [ngClass]="isActive ? ['svg-active', 'fill-primary'] : ['']"-->
<!--      [class.fill-danger]="appValid === false || isValid === false"-->
<!--      [class.fill-success]="appValid === true || isValid === true"-->
<!--    />-->
<!--    <ng-container *ngIf="!isActive">-->
<!--      <ng-container *ngIf="formattedTitle">-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="60%" y="287%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{formattedTitle.titleOne}}-->
<!--        </text>-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="60%" y="320%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{formattedTitle.titleTwo}}-->
<!--        </text>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="!formattedTitle">-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="60%" y="305%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{title.label}}-->
<!--        </text>-->
<!--      </ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="isActive">-->
<!--      <ng-container *ngIf="formattedTitle">-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="55%" y="260%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{formattedTitle.titleOne}}-->
<!--        </text>-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="55%" y="290%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{formattedTitle.titleTwo}}-->
<!--        </text>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="!formattedTitle">-->
<!--        <text-->
<!--          text-anchor="middle"-->
<!--          x="55%" y="275%"-->
<!--          font-size="14"-->
<!--          class="chevron-text"-->
<!--          [class.svg-active]="isActive"-->
<!--          [class.filled-text]="isActive || (appValid === false || isValid === false) || (appValid === true || isValid === true)"-->
<!--        >-->
<!--          {{title.label}}-->
<!--        </text>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </g>-->
<!--</svg>-->

<button
  class="btn btn-outline-secondary chevron-btn btn-sm position-relative text-smaller line-height-0"
  placement="bottom"
  [class.btn-primary]="isActive"
  [class.btn-outline-primary]="isActive"
  [class.disabled]="isDisabled"
  [class.border]="isDisabled"
  [class.border-dark]="isDisabled"
  [class.btn-success]="appValid === true || isValid === true"
  [class.btn-outline-success]="appValid === true || isValid === true"
  [class.btn-danger]="appValid === false || isValid === false"
  [class.btn-outline-danger]="appValid === false || isValid === false"
  [ngbTooltip]="showPopover ? popoverText || title.label : undefined"
  [disabled]="isDisabled"
  (click)="onSelect()"
>
  {{title.label}}
</button>
