<div class="body-shell">

  <div id="authenticated-page-content-wrapper">
    <div class="body-content container-fluid p-0">

      <div class="row mt-3 p-0 mx-5 px-5">
        <div class="col-12 p-0">
          <img
            class="register-profile-logo"
            src="assets/images/poverty-stoplight/psl-logo-white.jpg"
            alt="logo"
          >
            <span class="float-right mt-4">
              <button class="btn btn-link" (click)="logout()">
                <i class="fa fa-sign-out fa-2x text-success pr-2"></i>
                <span class="align-middle">
                  Logout
                </span>
              </button>
            </span>
          <div class="w-100 m-auto">
            <h3 class="mt-3">Complete your profile</h3>
            <p class="mt-2">Please capture the required information in the fields below:</p>
            <app-organisation-profile-form
              [heading]="'Basic information'"
              [hasBottomSubmit]="true"
              (submitSuccess)="submitSuccess($event)"
            ></app-organisation-profile-form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
