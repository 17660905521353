<div class="body-shell">

  <div id="authenticated-page-content-wrapper">
    <div class="body-content container-fluid p-0">

      <div class="row">
        <div class="col-12">
          <img
            class="register-profile-logo ml-5"
            src="assets/images/poverty-stoplight/psl-logo-white.jpg"
            alt="logo"
          >
          <span class="float-right mt-4 mr-4">
      <button class="btn btn-link" (click)="logout()">
        <i class="fa fa-sign-out fa-2x text-success pr-2"></i>
        <span class="align-middle">
          Logout
        </span>
      </button>
    </span>
        </div>
      </div>

      <div class="row mt-3 p-0 mx-5 px-5">
        <div class="col-6 p-0">
          <!--    <img-->
          <!--      class="register-profile-logo"-->
          <!--      src="assets/images/poverty-stoplight/psl-logo-white.jpg"-->
          <!--      alt="logo"-->
          <!--    >-->
          <div class="w-100 m-auto">
            <h3 class="mt-3">Registration Completed <i class="fa fa-check-circle text-success"></i></h3>
            <p class="mt-2">You have completed your registration for access to the Greenlight Platform</p>
          </div>
        </div>
      </div>

      <div class="row mt-3 p-0 mx-5 px-5">
        <div class="col-6 p-0">
          <img
            class="registration-submitted-logo"
            src="assets/images/poverty-stoplight/registration-completed.png"
            alt="logo"
          >
        </div>
        <div class="col-6 p-0">
          <h3 class="mt-5 text-center">Awaiting Verification</h3>
          <p class="mt-2 font-size-lg">
            Your registration has been submitted and is currently under review.
            Once your organisation’s profile is verified we will notify you to get started.
          </p>
        </div>
      </div>

    </div>
  </div>
</div>
