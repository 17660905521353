import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FileUploadSpaces} from '../../../../../../../shared/modules/file-upload/models/file-upload.model';

@Component({
  selector: 'app-client-management-survey-edit-question',
  templateUrl: './client-management-survey-edit-question.component.html',
  styleUrls: ['./client-management-survey-edit-question.component.scss']
})
export class ClientManagementSurveyEditQuestionComponent implements OnInit {

  @Input() questionForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() totalNumberOfPreviousQuesions: number;
  @Input() dimensionIndex: number;
  @Input() questionIndex: number;

  questionNumber: number;

  constructor() { }

  ngOnInit(): void {
    this.questionNumber = this.questionIndex + 1 + this.totalNumberOfPreviousQuesions;
  }

  addPhoto(fileUploadSpaces: FileUploadSpaces) {
    this.questionForm.controls.answerPhoto.patchValue(fileUploadSpaces);
  }
}
