<div class="body-shell">

  <div id="authenticated-page-content-wrapper">
    <div class="body-content container-fluid p-0">

      <div class="row">
        <div class="col-12">
    <span class="float-right mt-4 mr-5">
      <button class="btn btn-link" (click)="logout()">
        <i class="fa fa-sign-out fa-2x text-success pr-2"></i>
        <span class="align-middle">
          Logout
        </span>
      </button>
    </span>
        </div>
      </div>


      <div class="row">
        <!--  <div class="col-6 p-0">-->

        <!--    <img-->
        <!--      class="sign-in-bg"-->
        <!--      src="assets/images/poverty-stoplight/sign-in-bg.png"-->
        <!--      alt="sign-in"-->
        <!--    >-->
        <!--  </div>-->
        <div class="offset-4 col-4 text-center mt-5 p-0">
          <img
            class="sign-in-logo"
            src="assets/images/poverty-stoplight/psl-logo-white.jpg"
            alt="logo"
          >
          <div class="w-80 m-auto">
            <div class="align-middle">

              <h2 class="mt-5 mb-3">Welcome {{user.firstName}}</h2>

              <p class="text-dark font-size-lg text-justify line-height-1">
                Welcome to the Greenlight Platform, a system managed by the Greenlight Office.
                <br><br>
                Before you get started, we will need some information from you describing your organisation.
              </p>

              <button class="btn btn-success w-100 mt-3" routerLink="/organisation-registration/form">Begin Registration
              </button>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
