import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {ChoiceInputItem} from '../../../shared/modules/form-elements/models/form-elements.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LoadingService} from '../../../services/loading/loading.service';
import {OrganisationProfileService} from '../../../modules/organisation-registration/store/organisation-profile.service';
import {
  createOrganisationProfileForm,
  OrganisationProfile,
} from '../../../modules/organisation-registration/store/organisation-profile.model';
import {FileUploadSpaces} from '../../../shared/modules/file-upload/models/file-upload.model';
import {AlertService} from '../../../shared/modules/alert/services/alert.service';
import {SessionQuery} from '../../../modules/authentication/session/session.query';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {tap} from 'rxjs/operators';
import {LocationService} from '../../../modules/admin/components/location-management/store/location.service';
import {Province, sortProvinceComparator} from '../../../modules/admin/components/location-management/store/location.model';
import {compare} from '../../../shared/utils/sort-util';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {GooglePlacesUtil} from '../../../shared/utils/google-places-util';
import {Options} from 'ngx-google-places-autocomplete/objects/options/options';

export const sdgTypes: ChoiceInputItem[] = [
  {
    label: 'GOAL 1: No Poverty',
    value: 'GOAL_1'
  },
  {
    label: 'GOAL 2: Zero Hunger',
    value: 'GOAL_2'
  },
  {
    label: 'GOAL 3: Good Health and Well-being',
    value: 'GOAL_3'
  },
  {
    label: 'GOAL 4: Quality Education',
    value: 'GOAL_4'
  },
  {
    label: 'GOAL 5: Gender Equality',
    value: 'GOAL_5'
  },
  {
    label: 'GOAL 6: Clean Water and Sanitation',
    value: 'GOAL_6'
  },
  {
    label: 'GOAL 7: Affordable and Clean Energy',
    value: 'GOAL_7'
  },
  {
    label: 'GOAL 8: Decent Work and Economic Growth',
    value: 'GOAL_8'
  },
  {
    label: 'GOAL 9: Industry, Innovation and Infrastructure',
    value: 'GOAL_9'
  },
  {
    label: 'GOAL 10: Reduced Inequality',
    value: 'GOAL_10'
  },
  {
    label: 'GOAL 11: Sustainable Cities and Communities',
    value: 'GOAL_11'
  },
  {
    label: 'GOAL 12: Responsible Consumption and Production',
    value: 'GOAL_12'
  },
  {
    label: 'GOAL 13: Climate Action',
    value: 'GOAL_13'
  },
  {
    label: 'GOAL 14: Life Below Water',
    value: 'GOAL_14'
  },
  {
    label: 'GOAL 15: Life on Land',
    value: 'GOAL_15'
  },
  {
    label: 'GOAL 16: Peace and Justice Strong Institutions',
    value: 'GOAL_16'
  },
  {
    label: 'GOAL 17: Partnerships to achieve the Goal',
    value: 'GOAL_17'
  }
];

export const targetedPopulations: ChoiceInputItem[] = [
  {
    label: 'Adolescent boys only 13 - 18',
    value: 'ADOLESCENT_BOYS'
  },
  {
    label: 'Adolescent girls only 13 - 18',
    value: 'ADOLESCENT_GIRLS'
  },
  {
    label: 'Adults 18+',
    value: 'ADULTS'
  },
  {
    label: 'Children under 5',
    value: 'CHILDREN'
  },
  {
    label: 'Children 6 – 12 years',
    value: 'SIX_TO_TWELVE'
  },
  {
    label: 'Differently-abled people',
    value: 'DIFFERENTLY_ABLED'
  },
  {
    label: 'Elderly people/pensioners 60+',
    value: 'ELDERLY'
  },
  {
    label: 'Homeless people',
    value: 'HOMELESS_PEOPLE'
  },
  {
    label: 'LGBTQIA+',
    value: 'LGBTQIA'
  },
  {
    label: 'Men only (any age)',
    value: 'MEN'
  },
  {
    label: 'Migrant workers',
    value: 'MIGRANT_WORKERS'
  },
  {
    label: 'Pregnant women only',
    value: 'PREGNANT_WOMEN'
  },
  {
    label: 'Prisoners',
    value: 'PRISONERS'
  },
  {
    label: 'Refugees / asylum seekers',
    value: 'REFUGEES'
  },
  {
    label: 'Survivors of violence',
    value: 'SURVIVORS'
  },
  {
    label: 'Women only (any age)',
    value: 'WOMEN'
  },
  {
    label: 'Youth and adolescents',
    value: 'YOUTH'
  },
  {
    label: 'Other',
    value: 'OTHER'
  }
];

export const organisationTypes: ChoiceInputItem[] = [
  {
    label: 'Co-Operative',
    value: 'CO_OPERATIVE'
  },
  {
    label: 'For Profit Private Company (PTY Ltd)',
    value: 'FOR_PROFIT_BUSINESS',
  },
  {
    label: 'Government entity',
    value: 'GOVERNMENT_ENTITY',
  },
  {
    label: 'Non-Profit Company (NPC)',
    value: 'NPC'
  },
  {
    label: 'Non-Profit Organisation (NPO)',
    value: 'NGO',
  },
  {
    label: 'Public/Listed Company',
    value: 'PUBLIC_COMPANY'
  },
  {
    label: 'Social Enterprise – Non-Profit',
    value: 'SOCIAL_ENTERPRISE_NON_PROFIT'
  },
  {
    label: 'Social Enterprise Private Company (PTY Ltd)',
    value: 'SOCIAL_ENTERPRISE_PRIVATE',
  },
  {
    label: 'State Owned Enterprise (SOE)',
    value: 'SOE'
  },
  {
    label: 'Trust',
    value: 'TRUST'
  },
  {
    label: 'Voluntary Association',
    value: 'VOLUNTARY_ASSOCIATION'
  },
];

export const organisationServiceTypes: TreeviewItem[] = [
  new TreeviewItem({
    text: 'Health',
    value: 'HEALTH',
    checked: false,
    children: [
      new TreeviewItem({
        text: 'Physical Health',
        value: 'PHYSICAL_HEALTH',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Mental Health',
        value: 'MENTAL_HEALTH',
        checked: false,
      }),
    ]
  }),

  new TreeviewItem({
    text: 'Nutrition and Food Security',
    value: 'NUTRITION',
    checked: false,
    children: [
      new TreeviewItem({
        text: 'Feeding programmes',
        value: 'FEEDING_PROGRAMMES',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Awareness and Education',
        value: 'AWARENESS_EDUCATION',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Food Gardens and small scale agriculture',
        value: 'FOOD_GARDENS',
        checked: false,
      }),
    ]
  }),

  new TreeviewItem({
    text: 'Education',
    value: 'EDUCATION',
    checked: false,
    children: [
      new TreeviewItem({
        text: 'ECD',
        value: 'ECD',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Primary Schooling',
        value: 'PRIMARY_SCHOOLING',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Secondary Schooling',
        value: 'SECONDARY_SCHOOLING',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Post-Secondary Education and Training',
        value: 'PSET',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Training and Capacity building',
        value: 'TRAINING',
        checked: false,
      }),
    ]
  }),

  new TreeviewItem({
    text: 'Youth',
    value: 'YOUTH',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Gender and Inclusion',
    value: 'GENDER_AND_INCLUSION',
    checked: false,
    children: [
      new TreeviewItem({
        text: 'GBV and domestic violence',
        value: 'GBV',
        checked: false,
      }),
      new TreeviewItem({
        text: 'Gender Inclusion',
        value: 'GENDER_INCLUSION',
        checked: false,
      }),
      new TreeviewItem({
        text: 'LGBTQIA+ support',
        value: 'LGBTQIA',
        checked: false,
      }),
    ]
  }),

  new TreeviewItem({
    text: 'People with disabilities',
    value: 'PEOPLE_WITH_DISABILITIES',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Financial inclusion',
    value: 'FINANCIAL_INCLUSION',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Economic Strengthening',
    value: 'ECONOMIC_STRENGTHENING',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Entrepreneur and SME support',
    value: 'ENTREPRENEUR_SME',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Sports Development and Recreation',
    value: 'SPORTS_RECREATION',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Arts and Culture',
    value: 'ARTS_AND_CULTURE',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Environment',
    value: 'ENVIRONMENT',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Animal Welfare',
    value: 'ANIMAL_WELFARE',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Housing',
    value: 'HOUSING',
    checked: false,
  }),
  new TreeviewItem({
    text: 'Religious and Spiritual Support',
    value: 'RELIGIOUS_AND_SPIRITUAL',
    checked: false,
  })
];

// export const provinceTypes: ChoiceInputItem[] = [
//   {
//     label: 'Eastern Cape',
//     value: 'EASTERN_CAPE',
//   },
//   {
//     label: 'Free State',
//     value: 'FREE_STATE',
//   },
//   {
//     label: 'Gauteng',
//     value: 'GAUTENG',
//   },
//   {
//     label: 'KwaZulu-Natal',
//     value: 'KWAZULU_NATAL',
//   },
//   {
//     label: 'Limpopo',
//     value: 'LIMPOPO',
//   },
//   {
//     label: 'Mpumalanga',
//     value: 'MPUMALANGA',
//   },
//   {
//     label: 'North West',
//     value: 'NORTH_WEST',
//   },
//   {
//     label: 'Northern Cape',
//     value: 'NORTHERN_CAPE',
//   },
//   {
//     label: 'Western Cape',
//     value: 'WESTERN_CAPE',
//   },
// ];

@Component({
  selector: 'app-organisation-profile-form',
  templateUrl: './organisation-profile-form.component.html',
  styleUrls: ['./organisation-profile-form.component.scss'],
})
export class OrganisationProfileFormComponent implements OnInit, OnChanges {
  @Input() organisationProfile: OrganisationProfile;
  @Input() heading: string;
  @Input() username: string; // only used for hub user when creating an organisation
  @Input() subHeading: string;
  @Input() hasBottomSubmit = false;

  @Output() submitSuccess: EventEmitter<OrganisationProfile> =
    new EventEmitter<OrganisationProfile>();

  loading: Observable<boolean>;
  profileForm: FormGroup;

  isHub: boolean;
  submitText: string;

  sdgs: ChoiceInputItem[] = sdgTypes;
  targetedPopulations: ChoiceInputItem[] = targetedPopulations;
  organisationTypes: ChoiceInputItem[] = organisationTypes;
  organisationServiceTypes: TreeviewItem[] = [...organisationServiceTypes];
  selectedCountry: string;
  provinceList: Province[];
  provinceTypes: ChoiceInputItem[];
  filteredProvinceTypes: ChoiceInputItem[] = [];
  provinceTreeViewTypes: TreeviewItem[] = [];

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 350
  });

  options: Options = {
    fields: ['address_components', 'geometry'],
    // bounds: undefined,
    //   {
    // // @ts-ignore
    //   north: -26.2626262 + 0.1,
    //   south: -26.2626262 - 0.1,
    //   east: 27.9962420 + 0.1,
    //   west: 27.9962420 - 0.1,
    // },

    componentRestrictions: {
      // @ts-ignore
      country: []
    }
  };

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SouthAfrica];

  unique: boolean;

  constructor(private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private sessionQuery: SessionQuery,
              private organisationProfileService: OrganisationProfileService,
              private locationService: LocationService,
              private googlePlacesUtil: GooglePlacesUtil,
              private alertService: AlertService,
              private ref: ChangeDetectorRef) {
    const lat = sessionQuery.getLatitude();
    const long = sessionQuery.getLongitude();

    if (lat && long) {
      this.options.bounds = {
        // @ts-ignore
        north: long + 0.1,
        south: long - 0.1,
        east: lat + 0.1,
        west: lat - 0.1,
      };
    }
  }

  ngOnInit(): void {
    this.loading = this.loadingService.getIsLoading();
    this.isHub = this.sessionQuery.hasRole(['ROLE_HUB']);

    this.submitText =
      this.organisationProfile && this.organisationProfile.id
        ? 'Update'
        : 'Submit';
    this.profileForm = createOrganisationProfileForm(
      this.formBuilder,
      this.organisationProfile || {}
    );

    this.checkItemsInTreeView(this.organisationServiceTypes);
    this.fetch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.organisationProfile &&
      changes.organisationProfile.currentValue
    ) {
      this.organisationProfile = changes.organisationProfile.currentValue;
      if (this.organisationProfile) {
        this.setProvinceFields();
      }
    }
  }

  fetch() {
    const actions = [this.fetchProvinces()];
    this.loadingService.setIsLoading(true);
    forkJoin(actions).subscribe(() => {
    }, () => {
    }, () => {
      this.loadingService.setIsLoading(false);
    });
  }

  private fetchProvinces() {
    return this.locationService.getAllProvinces().pipe(tap(list => {
      const countryCodes = list.reduce(
        (acc, x) => {
          if (!acc.includes(x.country.code.toLowerCase())) {
            acc.push(x.country.code.toLowerCase());
          }
          return acc;
        }, []);

      this.provinceList = list;
      this.provinceList.sort(sortProvinceComparator);
      this.provinceTypes = this.provinceList.map(x => {
        return {
          label: x.name,
          extraLabel: x.country.name,
          value: x,
        };
      });
      this.filteredProvinceTypes = this.provinceTypes;

      this.setProvinceFields();

      // @ts-ignore
      this.options.componentRestrictions.country = countryCodes;
      this.preferredCountries = countryCodes;

      this.ref.detectChanges();


      const countryList = list.reduce((acc, x) => {
        const found = acc.find(a => a.id === x.country.id);

        if (!found) {
          acc.push(x.country);
        }
        return acc;
      }, []);

      countryList.sort((a, b) => compare(a.name, b.name, true));

      this.provinceTreeViewTypes = countryList.map(x => {
        const found = list.filter(y => y.country.id === x.id);

        const childrenState = found.map(y => {
          let checkedState = false;

          if (this.organisationProfile && this.organisationProfile.operationalProvinceEntities?.length) {
            const selectedProvince = this.organisationProfile.operationalProvinceEntities.find(z => z.id === y.id);
            checkedState = selectedProvince ? true : false;
          } else {
          }

          return new TreeviewItem({
            text: y.name,
            value: y,
            checked: checkedState,
          });
        });

        return new TreeviewItem({
          text: x.name,
          value: x,
          checked: false,
          children: childrenState,
        });
      }, () => {
        this.alertService.setAlert('Unable to fetch province data', 'error', 'registration-profile');
      });
    }));
  }

  validateAndSave(pageTop: HTMLElement) {
    this.checkUniqueName(pageTop, true);
  }

  submit(pageTop: HTMLElement) {
    if (this.profileForm.valid) {
      this.loadingService.setIsLoading(true);

      const primaryContactNo = (this.profileForm.controls.contact as FormGroup).controls.primaryNumber.value;
      if (primaryContactNo) {
        (this.profileForm.controls.contact as FormGroup).controls.primaryNumber.patchValue(primaryContactNo.internationalNumber);
      } else {
        (this.profileForm.controls.contact as FormGroup).controls.primaryNumber.patchValue(undefined);
      }

      const secondaryContactNo = (this.profileForm.controls.contact as FormGroup).controls.secondaryNumber?.value;
      if (secondaryContactNo) {
        (this.profileForm.controls.contact as FormGroup).controls.secondaryNumber.patchValue(secondaryContactNo.internationalNumber);
      } else {
        (this.profileForm.controls.contact as FormGroup).controls.secondaryNumber.patchValue(undefined);
      }

      if (this.profileForm.value.id) {
        this.organisationProfileService.update(this.profileForm.value).subscribe(
          (savedProfile) => {
            this.submitSuccess.emit(savedProfile);
            this.loadingService.setIsLoading(false);
          },
          () => {
            this.loadingService.setIsLoading(false);
            this.alertService.setAlert(
              'Failed to update profile.',
              'error',
              'registration-profile'
            );
            pageTop.scrollIntoView({behavior: 'smooth'});
          }
        );
      } else {
        if (this.isHub) {
          this.organisationProfileService
            .createByHub(this.profileForm.value, encodeURIComponent(this.username))
            .subscribe(
              (savedProfile) => {
                this.submitSuccess.emit(savedProfile);
                this.loadingService.setIsLoading(false);
              },
              () => {
                this.loadingService.setIsLoading(false);
                this.alertService.setAlert(
                  'Failed to create profile.',
                  'error',
                  'registration-profile'
                );
                pageTop.scrollIntoView({behavior: 'smooth'});
              }
            );
        } else {
          this.organisationProfileService
            .createByOrganisation(this.profileForm.value)
            .subscribe(
              (savedProfile) => {
                this.submitSuccess.emit(savedProfile);
                this.loadingService.setIsLoading(false);
              },
              () => {
                this.loadingService.setIsLoading(false);
                this.alertService.setAlert(
                  'Failed to create profile.',
                  'error',
                  'registration-profile'
                );
                pageTop.scrollIntoView({behavior: 'smooth'});
              }
            );
        }
      }
    } else {
      this.alertService.setAlert(
        'Please complete the form before submitting',
        'error',
        'registration-profile'
      );
      pageTop.scrollIntoView({behavior: 'smooth'});
    }
  }

  checkUniqueName(pageTop: HTMLElement, createFn?: boolean) {
    this.organisationProfileService.checkUniqueName(this.profileForm.controls.name.value, this.profileForm.value.id).then(isUnique => {
      // if `result` is false, this will update the UI with a validation error.
      this.unique = isUnique;

      if (isUnique && createFn) {
        this.submit(pageTop);
      }
    });
  }

  changeName(name: string) {
    this.unique = undefined;
    this.profileForm.controls.name.patchValue(name);
  }

  addLogo(fileUploadSpaces: FileUploadSpaces) {
    this.profileForm.controls.photo.patchValue(fileUploadSpaces);
  }

  removePhoto() {
    this.profileForm.controls.photo.reset();
    this.profileForm.controls.photo.patchValue(undefined);
  }

  organisationServiceChange(services) {
    this.profileForm.patchValue({
      services
    });
  }

  operationalProvinceChange(provinces) {
    this.profileForm.controls.operationalProvinceEntities.patchValue(provinces);
  }

  private checkItemsInTreeView(list: TreeviewItem[]) {
    list.forEach(item => {
      item.checked = this.profileForm.controls.services?.value?.includes(item.value);
      if (item.children) {
        this.checkItemsInTreeView(item.children);
      }
      // item.disabled = this.isReadOnly;
    });
  }

  getContact(): FormGroup {
    return this.profileForm.controls.contact as FormGroup;
  }

  handleAddressChange(address: Address) {
    const addressDto = this.googlePlacesUtil.handleAddressChange(address, this.provinceTypes);

    this.setCountry(addressDto.provinceValue);

    if (addressDto.country) {
      this.filteredProvinceTypes = this.provinceTypes.filter(x => x.extraLabel === addressDto.country);
    } else {
      this.filteredProvinceTypes = this.provinceTypes;
    }

    let streetAddress = addressDto.streetAddress;
    if (addressDto.suburb) {
      streetAddress = streetAddress.concat(', ' + addressDto.suburb);
    }

    this.getContact().controls.streetAddress.patchValue(streetAddress);
    this.getContact().controls.city.patchValue(addressDto.city);
    this.getContact().controls.provinceEntity.patchValue(addressDto.provinceValue);
    this.getContact().controls.postalCode.patchValue(addressDto.postalCode);

    this.getContact().controls.latitude.patchValue(addressDto.latitude);
    this.getContact().controls.longitude.patchValue(addressDto.longitude);
  }

  setCountry(province: Province) {
    this.selectedCountry = province?.country?.name || undefined;
  }

  private setProvinceFields() {
    if (this.organisationProfile?.contact?.provinceEntity) {
      if (this.provinceList) {
        const found = this.provinceList.find(x => x.id === this.organisationProfile.contact.provinceEntity.id);
        if (found) {
          this.organisationProfile.contact.provinceEntity = found;
        }
      }

      if (this.profileForm) {
        this.profileForm.patchValue(this.organisationProfile);
        this.setCountry(this.organisationProfile.contact.provinceEntity);
      }
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    this.filteredProvinceTypes = this.provinceTypes;
  }
}
