<div>
  <div class="w-100 p-4">
<!--    <button class="close-modal" type="button" (click)="closeModal()">-->
<!--      <i class="far fa-times-circle"></i>-->
<!--    </button>-->
    <form *ngIf="entityForm" class="form" [formGroup]="entityForm" (ngSubmit)="submit(entityForm)" #form="ngForm">

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h4 class="d-inline-block text-success text-center mt-2">
              {{conductedSurvey.surveyType}}
            </h4>

            <button class="btn btn-light float-right border-2" type="button" (click)="closeModal()">Cancel</button>
            <button class="btn btn-success float-right" type="submit">Save</button>
          </div>
        </div>

        <div class="row d-flex justify-content-between">
          <p>
            {{conductedSurvey.answerTimeline | singleChoiceInputType : timelineTypes}}
          </p>
          <p>
            Submitted:
            <span *ngIf="conductedSurvey.answerSubmittedDate">{{conductedSurvey.answerSubmittedDate | date : 'dd/MM/yyyy'}}</span>
            <span *ngIf="!conductedSurvey.answerSubmittedDate">Not Submitted</span>
          </p>
          <p>
            Facilitator: {{conductedSurvey.answerConductedBy}}
          </p>
        </div>

<!--        <hr class="dropdown-divider border-primary w-100 mx-auto mb-2">-->
        <hr>

        <app-alert
          class="w-100"
          [id]="'conducted-survey-edit'"
        ></app-alert>

        <div
          *ngIf="(entityForm.controls.dimensions | asFormArray).controls && (entityForm.controls.dimensions | asFormArray).controls.length"
          formArrayName="dimensions">
          <div *ngFor="let dimension of (entityForm.controls.dimensions | asFormArray).controls; let dimIdx = index">
            <p class="mb-2">
              <strong>{{(dimIdx + 1) + '. '}} {{dimension.value.name}}</strong>
            </p>

            <form [formGroup]="dimension | asFormGroup">
              <div
                *ngIf="dimension && ((dimension | asFormGroup).controls.questions | asFormArray).controls && ((dimension | asFormGroup).controls.questions | asFormArray).controls.length"
                formArrayName="questions"
                class="bg-light-grey p-2"
              >
                <div *ngFor="let question of ((dimension | asFormGroup).controls.questions | asFormArray).controls; let qIdx = index">

                  <!-- Can only edit INDICATOR type questions -->
                  <ng-container *ngIf="question.value.type === 'INDICATOR'">
                    <app-client-management-survey-edit-question
                      [questionForm]="question | asFormGroup"
                      [formSubmitted]="form.submitted"
                      [totalNumberOfPreviousQuesions]="getNumberOfPreviousQuestions(dimIdx)"
                      [dimensionIndex]="dimIdx"
                      [questionIndex]="qIdx"
                    ></app-client-management-survey-edit-question>
                  </ng-container>

                </div>
              </div>
            </form>
          </div>
        </div>

      </div>

    </form>
  </div>
</div>
