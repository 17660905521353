import {Component, OnInit} from '@angular/core';
import { OrganisationProfile } from 'app/modules/organisation-registration/store/organisation-profile.model';
import { OrganisationProfileService } from 'app/modules/organisation-registration/store/organisation-profile.service';
import { LoadingService } from 'app/services/loading/loading.service';
import { AlertService } from 'app/shared/modules/alert/services/alert.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-organisation-sidebar',
  templateUrl: './organisation-sidebar.component.html',
  styleUrls: ['./organisation-sidebar.component.scss']
})
export class OrganisationSidebarComponent implements OnInit {
  activeId = 1;
  organisationProfile: OrganisationProfile;
  loading: Observable<boolean>;
  constructor(private organisationProfileService: OrganisationProfileService,
              private loadingService: LoadingService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.loading = this.loadingService.getIsLoading();
    this.fetchOrganisation();
  }

  private fetchOrganisation() {
    // const user = this.sessionQuery.getUser();
    this.loadingService.setIsLoading(true);
    this.organisationProfileService
      .getByOrganisationUser()
      .subscribe((organisationProfile) => {
        this.loadingService.setIsLoading(false);
        this.organisationProfile = organisationProfile;
      }, () => {
        this.alertService.setAlert(
          'Failed to fetch organisation details.',
          'error',
          'organisation-profile-details'
        );
      });
  }

}
