import {Component, OnInit} from '@angular/core';
import { User } from 'app/modules/authentication/user/user.model';
import {SessionQuery} from '../../../../../modules/authentication/session/session.query';

@Component({
  selector: 'app-facilitator-sidebar',
  templateUrl: './facilitator-sidebar.component.html',
  styleUrls: ['./facilitator-sidebar.component.scss']
})
export class FacilitatorSidebarComponent implements OnInit {
  activeId = 1;
  user: User;

  constructor(private sessionQuery: SessionQuery) {
  }

  ngOnInit(): void {
    this.user = this.sessionQuery.getUser();
  }

}
