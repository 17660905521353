<div
  [class]="style + '-input-group position-relative'"
  [class.input-is-grey]="isGrey"
>
  <div
    [ngClass]="style === 'default' ? 'input-label-text' : ''"
  >
    <p
      class="mb-2"
      [class]="style + '-input-multi-legend label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <div
    class="w-100"
    [class.wrap-checkboxes-small]="choices?.length > 8 && choices?.length < 16"
    [class.wrap-checkboxes-large]="choices?.length > 16"
  >
    <div
      appItemGrid
      [cols]="cols"
      [arrayLength]="choices?.length"
      [class.disabled-multi]="isGrey"
      [class]="style + '-input-multi-group checkbox-group readonly-input'"
      [class.borderless]="!hasBorder"
      [ngClass]="theme === 'light' ? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
    >
      <ul>
        <ng-container *ngFor="let choice of choices">
          <li
            *ngIf="values?.includes(choice.value)"
            class="font-size-small"
            [ngClass]="theme === 'dark' ? 'text-grey' : 'text-dark'"
            [innerHTML]="choice.label"
          ></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
