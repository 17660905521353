import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-widget-check-two-disabled',
  templateUrl: './widget-check-two-disabled.component.html',
  styleUrls: ['./widget-check-two-disabled.component.scss']
})
export class WidgetCheckTwoDisabledComponent implements OnInit {

  @Input() value: boolean;
  @Input() label: string;
  @Input() theme = 'light';

  constructor() { }

  ngOnInit() {
  }

}
