import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'arrayItemToString'
})
export class ArrayItemToStringPipe implements PipeTransform {

  transform(value: any[]): string {
    if (value) {
      return value.join(', ');
    }
    return '';
  }

}
