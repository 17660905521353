import {Pipe, PipeTransform} from '@angular/core';
import {GeoLocationService} from '../../../../../../services/geo-location/geo-location.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  constructor(private geoLocationService: GeoLocationService) {
  }

  transform(code: string): Observable<string> {
    return this.geoLocationService.getCountryByCode(code)
      .pipe(map(result => {
      return result ? result.name : 'Unknown';
    }));
  }

}
