import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertMessage, AlertType, createAlert} from '../../models/alert.model';
import {AlertService} from '../../services/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() inputMessage?: string;
  @Input() inputAlertType?: AlertType;

  public alertList: AlertMessage[] = [];
  public inputAlert: AlertMessage;
  private alertSubscription: Subscription;

  constructor(public alertService: AlertService) {
  }

  ngOnInit() {
    this.alertSubscription = this.alertService.getAlert().subscribe(alert => {
      if (alert) {
        // Make sure the alert can be dismissed if a timeout is not set
        if (alert.config.userDismissible !== false && (!alert.config.timeOut || alert.config.timeOut === 0)) {
          alert.config.userDismissible = true;
        }
        if (this.canUpdate()) {
          if (alert.id === this.id) {
            this.alertList.push(createAlert(alert));
          }
        }
      } else {
        this.alertList = [];
      }
    });

    if (this.inputMessage) {
      this.inputAlert = {
        alertType: this.inputAlertType,
        config: {
          timeOut: 0,
          userDismissible: false
        },
        message: this.inputMessage,
        id: 'inputMessage'
      };
    }
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
    this.alertService.resetAlert();
  }

  dismissAlert(index: number) {
    this.alertList.splice(index, 1);
  }

  private canUpdate(): boolean {
    // If the max number of alerts is open, check if the new alert should replace the
    // first alert or be blocked from rendering
    if (this.alertService.globalConfig.maxAlertsOpen === this.alertList.length) {
      if (this.alertService.globalConfig.autoDismiss) {
        this.dismissAlert(0);
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
