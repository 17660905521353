import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {
  Country,
  CountryCombined,
  CountryUsersDto,
  Province,
  ProvinceCreateDto,
  ProvinceUpdateDto,
  ProvinceUsersDto
} from './location.model';


@Injectable({providedIn: 'root'})
export class LocationService {
  countryUrl = `${environment.apiUrl}/country`;
  provinceUrl = `${environment.apiUrl}/province`;

  constructor(private http: HttpClient) {
  }

  getAllCombined(): Observable<CountryCombined[]> {
    const url = `${this.countryUrl}/combined`;
    return this.http.get<CountryCombined[]>(url);
  }

  getAll(): Observable<Country[]> {
    const url = `${this.countryUrl}`;
    return this.http.get<Country[]>(url);
  }

  getNumberOfUsersPerCountry(): Observable<CountryUsersDto[]> {
    const url = `${this.countryUrl}/users-per-country`;
    return this.http.get<CountryUsersDto[]>(url);
  }

  getNumberOfUsersPerProvince(countryId: number): Observable<ProvinceUsersDto[]> {
    const url = `${this.provinceUrl}/users-per-province?countryId=${countryId}`;
    return this.http.get<ProvinceUsersDto[]>(url);
  }

  // getList(): Observable<HouseholdListDto[]> {
  //   const url = `${this.countryUrl}/list`;
  //   return this.http.get<HouseholdListDto[]>(url);
  // }

  // getInclusiveById(id: number): Observable<HouseholdInclusiveDto> {
  //   const url = `${this.countryUrl}/${id}/inclusive`;
  //   return this.http.get<HouseholdInclusiveDto>(url);
  // }

  getById(id: number): Observable<Country> {
    const url = `${this.countryUrl}/${id}`;
    return this.http.get<Country>(url);
  }

  // createByHub(value: Household, organisationId: number): Observable<Household> {
  //   const url = `${this.url}?organisationId=${organisationId}`;
  //   return this.http.post<Household>(url, value);
  // }

  addCountry(country: Country) {
    const url = `${this.countryUrl}`;
    return this.http.post<Country>(url, country);
  }

  updateCountry(value: Country): Observable<Country> {
    const url = `${this.countryUrl}/${value.id}`;
    return this.http.put<Country>(url, value);
  }

  deleteCountry(countryId: number) {
    const url = `${this.countryUrl}/${countryId}`;
    return this.http.delete<Country>(url);
  }

  updateCountryStatus(id: number, status: string): Observable<any> {
    const url = `${this.countryUrl}/${id}/status?status=${status}`;
    return this.http.put<any>(url, undefined);
  }

  checkCountryUniqueName(name: string, id?: number): Observable<boolean> {
    let url = `${this.countryUrl}/unique/name?name=${name}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<boolean>(url);
  }

  checkCountryUniqueCode(code: string, id?: number): Observable<boolean> {
    let url = `${this.countryUrl}/unique/code?code=${code}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<boolean>(url);
  }

  // -- Province -------------------------------------------------------
  getAllProvinces(): Observable<Province[]> {
    const url = `${this.provinceUrl}`;
    return this.http.get<Province[]>(url);
  }

  getProvincesByCountryId(countryId: any): Observable<Province[]> {
    const url = `${this.provinceUrl}?countryId=${countryId}`;
    return this.http.get<Province[]>(url);
  }

  getProvinceById(id: any): Observable<Province> {
    const url = `${this.provinceUrl}/${id}`;
    return this.http.get<Province>(url);
  }

  // getByFacilitatorId(facilitatorId): Observable<HouseholdInclusiveDto[]> {
  //   const url = `${environment.apiUrl}/facilitator-household/by-facilitator?facilitatorId=${facilitatorId}`;
  //   return this.http.get<HouseholdInclusiveDto[]>(url);
  // }

  addProvince(value: ProvinceCreateDto, countryId: any) {
    const url = `${this.provinceUrl}?countryId=${countryId}`;
    return this.http.post<Province>(url, value);
  }

  updateProvince(value: ProvinceUpdateDto, countryId: any) {
    const url = `${this.provinceUrl}/${value.id}?countryId=${countryId}`;
    return this.http.put<Province>(url, value);
  }

  deleteProvince(provinceId: number) {
    const url = `${this.provinceUrl}/${provinceId}`;
    return this.http.delete<Province>(url);
  }

  updateProvinceStatus(id: number, status: string): Observable<any> {
    const url = `${this.provinceUrl}/${id}/status?status=${status}`;
    return this.http.put<any>(url, undefined);
  }

  checkProvinceUniqueName(name: string, countryId: number, id?: number): Observable<boolean> {
    let url = `${this.provinceUrl}/unique/name?countryId=${countryId}&name=${name}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<boolean>(url);
  }

  checkProvinceUniqueCode(code: string, countryId: number, id?: number): Observable<boolean> {
    let url = `${this.provinceUrl}/unique/code?countryId=${countryId}&code=${code}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<boolean>(url);
  }
}
