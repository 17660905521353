import {Pipe, PipeTransform} from '@angular/core';
import {GeoLocationService} from '../../../../../../services/geo-location/geo-location.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'subDistrict'
})
export class SubDistrictPipe implements PipeTransform {

  constructor(private geoLocationService: GeoLocationService) {
  }

  transform(code: string): Observable<string> {

    return this.geoLocationService.getGeoLocationBySubDistrictCode(code)
      .pipe(map(result => {
        return result && result.selectedSubDistrict ? result.selectedSubDistrict.name : 'Unknown';
      }));
  }
}
