<div [class]="style + '-input-group position-relative'" [class.input-is-grey]="isGrey">
  <div [ngClass]="style === 'default' ? 'input-label-text' : ''">
    <p
      class="mb-2"
      [class]="style + '-input-label label-readonly'"
      [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
    >{{label}}</p>
  </div>
  <p
    [class]="style + '-input readonly-input-textarea font-weight-normal font-size-small'"
    [class.borderless]="!hasBorder"
    [class.disabled]="isGrey"
    [ngClass]="theme === 'light'? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
  >{{value}}</p>
</div>
