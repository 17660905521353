import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniqueValueService {

  baseUrl = `${environment.apiUrl}`;

  constructor(private httpClient: HttpClient) {
  }

  checkIsUnique(value: any, urlFragment: string, additionalParam?: string, id?: number) {
    let url = `${this.baseUrl}/${urlFragment}/unique?value=${value}`;
    if (additionalParam) {
      url += additionalParam;
    }
    if (id) {
      url += `&id=${id}`;
    }
    return this.httpClient.get<any>(url);
  }
}
