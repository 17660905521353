<button
  type="button"
  (click)="fileInput.click();"
  class="btn btn-light border-2"
  [class.button-center]="center"
  [class.mx-auto]="center"
>
  <span class="fa fa-upload fa-1x text-center mr-1"></span>
  <span>{{label}}</span>
</button>
<span class="uploader">
  <input type="file" #fileInput ng2FileSelect [uploader]="uploader" hidden/>
</span>
<span *ngIf="uploading">
  <i class="mt-2 fa fa-spinner fa-spin text-success"></i>
</span>
