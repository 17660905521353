import {Pipe, PipeTransform} from '@angular/core';
import {roleTypes} from '../../../../modules/organisation/components/organisation-profile/components/org-admin-profile/org-admin-profile.component';
import {ChoiceInputItem} from '../../form-elements/models/form-elements.model';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(values: any[]): string[] {
    return values.map(val => {
      return this.getTypeByValue(val, roleTypes);
    });
  }

  getTypeByValue(role: any, choiceInputItem: ChoiceInputItem[]): string {
    const found = choiceInputItem.find(item => item.value === role.name);
    if (found) {
      return found.label;
    }

    return 'Unknown';
  }

}
