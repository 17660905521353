import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true
    }
  ]
})
export class InputDateComponent implements OnInit, OnDestroy, ControlValueAccessor {

  // Required
  @Input() label: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;

  // Default
  @Input() type = 'text';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() showDisabledValue = false;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() hasTime = false;
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() minDate = {year: 1900, month: 1, day: 1};
  @Input() maxDate = {year: 2100, month: 1, day: 1};
  @Input() minTime;

  public dateValueSrc: any;
  public timeValueSrc: any;
  valueSubscription: Subscription;

  set dateValue(value: any) {
    this.dateValueSrc = value;
    this.onChange(value);
    this.onTouch();
  }

  get dateValue(): any {
    return this.dateValueSrc;
  }

  set timeValue(value: any) {
    this.timeValueSrc = value;
    this.onChange(value);
    this.onTouch();
  }

  get timeValue(): any {
    return this.timeValueSrc;
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: any) {
    this.dateValue = value;
    this.timeValue = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: number) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: number) {
  }

  onTouch() {
  }
}
