import {Pipe, PipeTransform} from '@angular/core';
import {GeoLocationService} from '../../../../../../services/geo-location/geo-location.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'district'
})
export class DistrictPipe implements PipeTransform {

  constructor(private geoLocationService: GeoLocationService) {
  }

  transform(code: string): Observable<string> {
    return this.geoLocationService.getGeoLocationByDistrictCode(code)
      .pipe(map(result => {
        return result && result.selectedDistrict ? result.selectedDistrict.name : 'Unknown';
      }));
  }
}
