import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input-select-multi-disabled',
  templateUrl: './input-select-multi-disabled.component.html',
  styleUrls: ['./input-select-multi-disabled.component.scss']
})
export class InputSelectMultiDisabledComponent implements OnInit {

  @Input() label: string;
  @Input() values: string[] = ['Select an option'];
  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() isGrey = true;
  @Input() hasBorder = true;

  constructor() { }

  ngOnInit() {
  }

}
