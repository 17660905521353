<ng-container *ngIf="alertList.length > 0">
  <ng-container *ngFor="let alert of alertList; let idx = index">
    <app-alert-banner
      [alert]="alert"
      (dismiss)="dismissAlert(idx)"
    ></app-alert-banner>
  </ng-container>
</ng-container>

<ng-container *ngIf="inputMessage">
  <app-alert-banner
    [alert]="inputAlert"
  ></app-alert-banner>
</ng-container>
