<ng-container *ngIf="!isReadOnly; else disabledInput">
  <app-form-control-date
    [label]="label"
    [id]="id"
    [formSubmitted]="formSubmitted"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [type]="type"
    [showLabel]="showLabel"
    [showErrors]="showErrors"
    [showOptional]="showOptional"
    [isRequired]="isRequired"
    [hasTime]="hasTime"
    [theme]="theme"
    [style]="style"
    (childValue)="dateValue"
    [(ngModel)]="dateValue"
  ></app-form-control-date>
</ng-container>

<ng-template #disabledInput>
  <app-input-date-disabled
    [label]="label"
    [theme]="theme"
    [style]="style"
    [isGrey]="isGrey"
    [hasBorder]="hasBorder"
    [hasTime]="hasTime"
    [value]="showDisabledValue ? dateValue : ''"
  ></app-input-date-disabled>
</ng-template>
