import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UniqueValueService} from '../../../services/unique-value.service';
import {debounceTime} from 'rxjs/operators';
import {ValidationService} from '../../../services/validation.service';

const PAT_EMAIL = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:\\[\x00-\x7F]|[^\\"\\\x00-\x1F\x7F])+")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\[(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\]))$/;
const PAT_OPTIONAL_EMAIL = /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$/;
const PAT_CONTACT_NO = /^$|^[+]?[0-9]{10,14}$/;

@Component({
  selector: 'app-form-control-text',
  templateUrl: './form-control-text.component.html',
  styleUrls: ['./form-control-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTextComponent),
      multi: true
    }
  ]
})
export class FormControlTextComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('ngModel', {static: true}) ngModel: NgModel;

  // Required
  @Input() label: string;
  @Input() labelExtraInfo?: string;
  @Input() id: string;

  // Conditional / Optional
  @Input() formSubmitted?: boolean;
  @Input() isEmail?: boolean;
  @Input() isContactNo?: boolean;
  @Input() iconClassAppend?: string;
  @Input() stringPrepend?: string;
  @Input() stringAppend?: string;
  @Input() urlFragment?: string;
  @Input() uniqueAdditionalParam?: string;
  @Input() uniqueRules?: any;
  @Input() uniqueId?: number;
  @Input() uniqueDescription?: string;
  @Input() markUnTouched: boolean;

  // Default
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() showLabel = true;
  @Input() showErrors = true;
  @Input() showOptional = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isUnique = false;
  @Input() maxLength = 45;
  @Input() minLength = 0;
  @Input() theme = 'light';
  @Input() style: 'default' | 'bordered' = 'bordered';
  @Input() class: string;
  @Input() uniqueError = 'That value is already in use. Please try another';
  @Input() clickableIcon: boolean;

  @Output() onIconClick = new EventEmitter<boolean>();
  @Output() childValue = new EventEmitter<string>();

  public valueSrc: string;
  hasNullError: boolean;
  emailInvalid: boolean;
  contactNoInvalid: boolean;
  lengthError: string;
  hasUniqueError: boolean;
  isLoading: boolean;
  valueSubscription: Subscription;

  set value(val: any) {
    if (val !== undefined && this.valueSrc !== val) {
      this.valueSrc = val;
      this.childValue.emit(val);
      this.onChange(val);
      this.onTouch();
    }
  }

  get value(): any {
    return this.valueSrc;
  }

  constructor(private uniqueValueService: UniqueValueService,
              private ref: ChangeDetectorRef) {
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.ngModel) {
      this.valueSubscription = this.ngModel.valueChanges
        .pipe(
          debounceTime(1000),
        ).subscribe(value => {
          // if (value && this.isUnique) {
          //   this.isLoading = true;
          //   this.uniqueValueService.checkIsUnique(value, this.urlFragment, this.uniqueAdditionalParam, this.uniqueId)
          //     .subscribe(isUnique => {
          //       this.hasUniqueError = !isUnique;
          //       this.isLoading = false;
          //     });
          // }
          this.validate();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSubmitted) {
      this.validate();
    }
    if (changes && changes.isRequired) {
      this.validate();
    }
    if (changes && changes.isUnique) {
      this.validate();
    }
    if (changes && changes.markUnTouched && changes.markUnTouched.currentValue === true) {
      this.ngModel.control.markAsUntouched();
      // this.ref.detectChanges();
      this.validate();
    }
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void) {
    // Required by the ControlValueAccessor interface. Informs Angular of value changes
    // Save the function as a property to call later.
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    // Required by the ControlValueAccessor interface. Informs Angular when input is touched.
    // Save the function as a property to call later.
    this.onTouch = fn;
  }

  onChange(value?: string) {
  }

  onTouch() {
  }

  validate() {
    if (this.isRequired) {
      this.hasNullError = ValidationService.controlHasNullError(this.ngModel.control, this.formSubmitted, this.ref);
    } else {
      this.hasNullError = undefined;
    }

    if (this.isUnique) {
      this.hasUniqueError = this.ngModel.control.hasError('checkIsUnique');
    } else {
      this.hasUniqueError = undefined;
    }

    if (this.maxLength) {
      if (this.maxLength !== this.minLength) {
        if (ValidationService.controlHasLengthError(this.ngModel.control, this.maxLength, 'max')) {
          this.lengthError = 'max';
        }
      }
    }

    if (this.minLength > 0) {
      if (ValidationService.controlHasLengthError(this.ngModel.control, this.minLength, 'min')) {
        this.lengthError = 'min';
      }
    }

    if (
      !ValidationService.controlHasLengthError(this.ngModel.control, this.minLength, 'min') &&
      !ValidationService.controlHasLengthError(this.ngModel.control, this.maxLength, 'max')
    ) {
      this.lengthError = undefined;
    } else if (
      this.ngModel.control.value.length === this.maxLength &&
      this.maxLength === this.minLength
    ) {
      // When the max length and min length are the same, check if the value is equal to the max length
      // If it is, hide the length warning to prevent confusion for users when they enter what should be a correct
      // value and receive a warning in response
      this.lengthError = undefined;
    }

    if (this.isEmail === true && (this.ngModel.control.touched || this.formSubmitted)) {
      if (this.isRequired === true) {
        if (!this.hasNullError) {
          // const regex = /^[a-z-]+$/;
          // console.log(regex.test('abc'));
          this.emailInvalid = !PAT_EMAIL.test(this.ngModel.control.value);
        } else {
          this.emailInvalid = false;
        }
      } else {
        this.emailInvalid = !PAT_OPTIONAL_EMAIL.test(this.ngModel.control.value);
      }

    }

    if (this.isContactNo === true && (this.ngModel.control.touched || this.formSubmitted)) {
      if (this.isRequired === true) {
        if (!this.hasNullError) {
          // const regex = /^[a-z-]+$/;
          // console.log(regex.test('abc'));

          this.contactNoInvalid = !PAT_CONTACT_NO.test(this.ngModel.control.value);
        } else {
          this.contactNoInvalid = false;
        }
      } else {
        this.contactNoInvalid = !PAT_CONTACT_NO.test(this.ngModel.control.value);
      }

    }
  }

  onClickCallback() {
    if (this.clickableIcon) {
      this.onIconClick.emit(true);
    }
  }

}
