import {Component, OnInit} from '@angular/core';
import {OrganisationProfile} from '../../store/organisation-profile.model';
import {Router} from '@angular/router';
import {SessionService} from '../../../authentication/session/session.service';

@Component({
  selector: 'app-registration-profile',
  templateUrl: './registration-profile.component.html',
  styleUrls: ['./registration-profile.component.scss']
})
export class RegistrationProfileComponent implements OnInit {

  constructor(private router: Router,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.router.navigateByUrl('/authentication/login').then(() => {
      this.sessionService.logout();
    });
  }

  submitSuccess(savedProfile: OrganisationProfile) {
    this.sessionService.setOrgIdOnUser(savedProfile.id);
    this.sessionService.setOrgStatusOnUser(savedProfile.status);
    this.router.navigateByUrl('/organisation-registration/submitted');
  }
}
