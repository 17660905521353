<div class="position-relative">
  <label
    *ngIf="showLabel"
    class="mb-2"
    [ngClass]="theme === 'light'? ['text-dark', 'bg-white'] : ['text-light', 'bg-dark']"
  >
<!--    [class]="style + '-input-multi-legend'"-->
    {{label}}
  </label>
  <fieldset>
    <div
      appItemGrid
      [cols]="cols"
      [arrayLength]="choices.length"
      [class]="style + '-input-multi-group checkbox-group'"
      [ngClass]="theme === 'light' ? ['text-dark', 'border-dark'] : ['text-light', 'border-light']"
      [class.border-danger]="hasError === 'null'"
      [class.border-none]="!showBorder"
    >

      <ng-container *ngIf="choices && choices.length > 0">
        <ng-container *ngFor="let choice of choices; let idx = index">
          <div
            class="flex-start m-1"
          >
<!--            custom-control custom-checkbox-->
            <input
              type="checkbox"
              id="{{id + idx}}"
              [name]="name + idx"
              [required]="isRequired"
              [attr.aria-label]="choice.label"
              [disabled]="choice.isDisabled"
              [value]="choice.value"
              [ngModel]="checkBoxAnswers.includes(choice.value)"
              (blur)="validate(); isTouched = true"
              (ngModelChange)="checkItem(idx)"
            />
<!--              class="custom-control-input"-->
            <label for="{{id + idx}}" class=" pl-2">{{choice.label}}</label>
<!--            custom-control-label-->
          </div>
        </ng-container>
      </ng-container>
    </div>
  </fieldset>

  <div class="input-validation-notices mt-1" *ngIf="showErrors">
    <ng-container *ngIf="!isRequired && showOptional">
      <div class="validation-notice optional-notice">
        <p class="font-size-sm font-weight-bold">This field is optional</p>
      </div>
    </ng-container>
    <ng-container *ngIf="hasError === 'null'">
      <div class="validation-notice null-notice">
        <p class="font-size-sm font-weight-bold text-danger">This field is required!</p>
      </div>
    </ng-container>
  </div>
</div>
