import {Component, Input, OnInit} from '@angular/core';
import {InputMask} from '../../../models/form-elements.model';
import {createNumberMask} from 'text-mask-addons';

@Component({
  selector: 'app-input-number-disabled',
  templateUrl: './input-number-disabled.component.html',
  styleUrls: ['./input-number-disabled.component.scss']
})
export class InputNumberDisabledComponent implements OnInit {

  @Input() label: string;
  @Input() value: string | number;
  @Input() style: 'bordered' | 'default' = 'default';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() isGrey = true;
  @Input() hasBorder = true;
  @Input() numberMask: InputMask;
  @Input() usePipe?: boolean;
  @Input() showLabel = true;
  @Input() stringPrepend?: string;
  @Input() stringAppend?: string;

  mask: InputMask;

  constructor() {
  }

  ngOnInit() {
    this.mask = this.createStandardNumberMask(this.numberMask);
  }

  createStandardNumberMask(options?: InputMask) {
    if (this.numberMask) {
      const config = {
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: false,
        decimalSymbol: '.',
        decimalLimit: 2,
        integerLimit: undefined,
        requireDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: false,
        ...options
      };
      return createNumberMask(config);
    }

    return {};
  }
}
