import {Injectable} from '@angular/core';
import {UserState, UserStore} from './user.store';
import {NgEntityService} from '@datorama/akita-ng-entity-service';
import {ResetUser, UpdateAdminUser, User, UserDetail, UserManagement, UserManagementDto} from './user.model';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionStore} from '../session/session.store';
import {Observable} from 'rxjs';
import { HubProfile } from 'app/modules/admin/components/admin-user/components/user-management/store/hub-profile.model';

@Injectable({providedIn: 'root'})
export class UserService extends NgEntityService<UserState> {
  constructor(
    protected store: UserStore,
    private sessionStore: SessionStore,
    private httpClient: HttpClient
  ) {
    super(store);
  }

  // -- forgot password ------------
  forgotPassword(user: ResetUser) {
    const url = `${environment.apiUrl}/user/forgot-password`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<any>(url, user, {headers});
  }

  confirmForgotPassword(token: string, user: ResetUser) {
    const url = `${environment.apiUrl}/user/forgot-password/confirmation?token=${token}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<any>(url, user, {headers});
  }

  confirmForgotFacilitatorPassword(token: string, notificationId: number) {
    const url = `${environment.apiUrl}/user/forgot-password/facilitator/confirmation?token=${token}&notificationId=${notificationId}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<any>(url, undefined, {headers});
  }

  // -- registration ------------
  register(user: Partial<User>) {
    const url = `${environment.apiUrl}/user/register`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<any>(url, user, {headers});
  }

  confirmRegistration(token: string) {
    const url = `${environment.apiUrl}/user/register/confirmation?token=${token}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.get<any>(url, {headers});
  }


  // -- reset password ------------
  resetPassword(user: ResetUser) {
    const url = `${environment.apiUrl}/user/reset-password`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<any>(url, user, {headers});
  }

  // -- reset password ------------
  resetFacilitatorPassword(username) {
    const url = `${environment.apiUrl}/user/reset-facilitator-password?username=${username}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.put<any>(url, {}, {headers});
  }


  // -- create users ------------
  createUser(user: Partial<UpdateAdminUser>) {
    const url = `${environment.apiUrl}/user/management/organisation`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<User>(url, user, {headers});
  }

  createLegacyUser(user: Partial<UpdateAdminUser>) {
    const url = `${environment.apiUrl}/user/management/organisation/legacy`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<User>(url, user, {headers});
  }

  createOrganisationUserByProfileId(user: Partial<UserManagement>, profileId: number) {
    const url = `${environment.apiUrl}/user/management/organisation/by-profile-id?profileId=${profileId}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.post<User>(url, user, {headers});
  }


  // -- update users ------------
  updateUser(user: Partial<UpdateAdminUser>) {
    const url = `${environment.apiUrl}/user/management/user`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.put<User>(url, user, {headers});
  }


  // -- get users ------------
  getUser() {
    const url = `${environment.apiUrl}/user/authenticate`;
    return this.httpClient.get<User>(url);
  }

  getHubUserByUsername(username: string) {
    const url = `${environment.apiUrl}/user/management/hub/${username}`;
    return this.httpClient.get<User>(url);
  }

  getAllHubUsers(): Observable<User[]> {
    const url = `${environment.apiUrl}/user/management/hub`;
    return this.httpClient.get<User[]>(url);
  }

  getAllHubProfileUsers(): Observable<HubProfile[]> {
    const url = `${environment.apiUrl}/hub-profile`;
    return this.httpClient.get<HubProfile[]>(url);
  }

  getFacilitatorUsers() {
    const url = `${environment.apiUrl}/user-detail/all-facilitators`;
    return this.httpClient.get<UserDetail[]>(url);
  }


  // -- delete users ------------
  // deleteUserByUsername(username: string) {
  //   const url = `${environment.apiUrl}/user/management/${username}`;
  //   return this.httpClient.delete<void>(url);
  // }

  updateStatus(username: string, status: string): Observable<any> {
    const url = `${environment.apiUrl}/user/management/status?username=${username}&status=${status}`;
    return this.httpClient.put<any>(url, undefined);
  }

  updateHubStatus(id: number, status: string): Observable<any> {
    const url = `${environment.apiUrl}/user/management/hub/${id}/status?status=${status}`;
    return this.httpClient.put<any>(url, undefined);
  }

  getOrganisationUsersByProfileId(profileId: number): Observable<User[]> {
    const url = `${environment.apiUrl}/user/management/organisation/by-profile-id?profileId=${profileId}`;
    return this.httpClient.get<User[]>(url);
  }

  getOrganisationUsersWithNoProfile(): Observable<UserManagementDto[]> {
    const url = `${environment.apiUrl}/user/management/organisation/with-no-profile`;
    return this.httpClient.get<UserManagementDto[]>(url);
  }

  getOrganisationUser(username: string) {
    const url = `${environment.apiUrl}/user/management/organisation/${username}`;
    return this.httpClient.get<User>(url);
  }

  convertFacilitatorToIndependent(facilitatorId: number) {
    const url = `${environment.apiUrl}/user/management/facilitator/convert-to-independent?facilitatorId=${facilitatorId}`;

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    return this.httpClient.put<any>(url, {}, {headers});
  }
}
